import React from "react";
import "./UploadFile.css";
import axios from 'axios';
import { cleanSidebarIcons, fecharSidebar } from "../Sidebar";
import { useState } from "react";
import { Loading } from "../../Loading/LoadingScreen";
import { changeLotes, changeViewState, closeClashAux } from "../../Deck/Deck Components/Organizar Layers/DeckPrepare";
import { baseUrlApi, cleanClashs, SetMultiClash, SetMultiLote } from "../../../DataHandler/DataHandler";
import { inputLotesID } from "../../../Format JSON/formatJson";
import * as turf from '@turf/turf';

export function openCloseUpload() {
    let modal = document.getElementById("tipologiaCheckbox");
    let uploadCenter = document.querySelector(".closeUploadCenter")
    let uploadBody = document.querySelector(".closeUpload")
    modal.classList.remove('mostrar')
    uploadBody.classList.add('active')
    uploadCenter.classList.add('active')
}

export function closeCloseUpload() {
    let uploadCenter = document.querySelector(".closeUploadCenter")
    let uploadBody = document.querySelector(".closeUpload")
    uploadBody.classList.remove('active')
    uploadCenter.classList.remove('active')
}

export function closeUpload() {
    let uploadCenter = document.querySelector(".uploadFormCenter")
    let uploadBody = document.querySelector(".uploadFormBody")
    let uploadForm = document.querySelector(".formupload")
    uploadCenter.classList.remove('opacity')
    uploadBody.classList.remove('active')
    uploadForm.classList.remove('active')
    setTimeout(() => {
        uploadCenter.classList.remove('active')
    }, 800);
    cleanSidebarIcons()
    fecharSidebar()
    Loading("fechar")
}

export function UploadFile() {
    const [file, setFile] = useState()

    const closeUploadMode = () => {
        closeCloseUpload()
        cleanClashs()
        closeClashAux()
        SetMultiClash(false)
        SetMultiLote(undefined)
        changeLotes()
        document.getElementById("showSide").style.display = "none";
        document.getElementById("uploadButtonid").style.display = "none"

    }

    const dontCloseUploadMode = () => {
        closeCloseUpload()
        SetMultiClash(true)
    }

    function closeuploadForm(e) {
        let uploadCenter = document.querySelector(".uploadFormCenter")
        let uploadBody = document.querySelector(".uploadFormBody")
        let uploadForm = document.querySelector(".formupload")
        if (e.target.id == "closeId") {
            uploadCenter.classList.remove('opacity')
            uploadBody.classList.remove('active')
            uploadForm.classList.remove('active')
            setTimeout(() => {
                uploadCenter.classList.remove('active')
            }, 800);
            cleanSidebarIcons()
            fecharSidebar()
        }
    }

    function handleChange(event) {
        setFile(event.target.files[0])
    }

    function handleSubmit(event) {
        if (file !== undefined) {
            Loading("iniciar")
            event.preventDefault()
            const url = `${baseUrlApi()}uploadkml`;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            axios.post(url, formData, config).then((response) => {
                closeClashAux()
                cleanClashs()
                response.data = inputLotesID(response.data)
                const center = turf.center(response.data)
                changeViewState(center.geometry.coordinates[0], center.geometry.coordinates[1], 500, 12)
                SetMultiClash(true)
                SetMultiLote(response.data)
                changeLotes()
                closeUpload()
                document.getElementById("showSide").style.display = "none";
                document.getElementById("uploadButtonid").style.display = "block"
            });
        }
    }

    return (
        <>
            <div id="closeId" onClick={closeuploadForm} className="uploadFormCenter">
                <div className="uploadFormBody">
                    <form id="myForm"
                        onSubmit={handleSubmit} className="formupload" encType="multipart/form-data">
                        <div className="StaticText">Faça Upload de um arquivo KML</div>
                        <input type="file" name="attachment" onChange={handleChange}
                        // accept=".kml"
                        ></input>
                        <button type="submit" className="filterButton btn btn-primary"
                            style={{ bottom: "10%", fontSize: "17px" }}>
                            Enviar
                        </button>
                    </form>
                </div>
            </div>
            <div id="closeUploadId" className="closeUploadCenter">
                <div className="closeUpload">
                    <div style={{ textAlign: "center" }}>Tem certeza que deseja sair do modo Upload?</div>
                    <div style={{
                        cursor: "pointer", position: "absolute",
                        left: "10%", bottom: "10px", fontSize: "14px"
                    }}
                        className="btn btn-primary"
                        onClick={closeUploadMode}>Sim</div>
                    <div style={{
                        cursor: "pointer", position: "absolute",
                        right: "10%", bottom: "10px", fontSize: "14px"
                    }}
                        className="btn btn-default"
                        onClick={dontCloseUploadMode}>Não</div>
                </div>
            </div>
        </>
    )
} 