import React from "react";
import $ from 'jquery'
import { cleanSidebarIcons } from "../Sidebar";
import { baseUrlApi } from "../../../DataHandler/DataHandler";

var fullscreenGif;
var gifRight = true;

export function displayFirstWindow() {
    document.getElementById("fundoTutorial1").style.display = "flex";
}

export function Tutorial() {
    function displayTutorialWindow(img, mensagem, isLast) {
        const peso = "100%"
        const ponta = "10px"
        return new Promise(resolve => {
            document.getElementById("fundoTutorial").style.display = "flex";
            document.getElementById("gifTuto").src = img;
            // document.getElementById("gif").style.right  = imgright;
            // document.getElementById("gif").style.left  = imgleft;
            // document.getElementById("tutorial").style.right = posRight;
            // document.getElementById("tutorial").style.bottom = posBottom;
            document.getElementById("tutorial").style.width = peso;
            // document.getElementById("tutorial").style.left = posLeft;
            // document.getElementById("tutorial").style.top = posTop; 
            document.getElementById("tutorial").style.borderRadius = ponta;
            document.getElementById("texto").innerHTML = mensagem;
            // document.getElementById("titulo").innerHTML = titulo;

            if (img === "") {
                document.getElementById("gif").style.opacity = "0";
            } else {
                document.getElementById("gif").style.opacity = "1";
            }
            if (isLast) {
                document.getElementById("nextTuto").innerHTML = "";
                document.getElementById("closeTuto").innerHTML = "Finalizar";
                document.getElementById("closeTuto").style.paddingRight = "0px";
                //document.getElementsByclassNameName("centro").style.left = "70%";
            } else {
                document.getElementById("nextTuto").innerHTML = "Próximo";
                document.getElementById("closeTuto").innerHTML = "Fechar";
                //document.getElementsByClassName("centro").style.left = "30%";
            }
            const btn = document.getElementById('nextTuto');
            btn.addEventListener('click', () => {
                if (isLast) {
                    //document.getElementById("fundoTutorial").innerHTML = "";
                    document.getElementById("fundoTutorial").style.display = "none";
                }
                document.getElementById("gif").style.opacity = "0";
                resolve()
            });
        });
    }

    async function startTutorial() {
        document.getElementById("changeImgSide").style.display = "none"
        document.getElementById("changeImgSide").innerHTML = "<"
        fullscreenGif = false
        $("[id^='gifTuto']").css("width", "80vw");
        $("[id^='gifTuto']").css("height", "80vh");
        $("[id^='gif']").css("left", "");
        $("[id^='gif']").css("right", "");
        $("[id^='closeTuto']").css("padding-right", "25px");

        document.getElementById("changeImgSize").innerHTML = "Minimizar"
        document.getElementById("fundoTutorial").style.background = "rgba(0, 0, 0, .4)";
        // img, imgright, imgleft, posRight, posBottom, peso, posLeft, posTop, ponta, mensagem, isLast
        // #Clique 1 - Seleção da área          
        await displayTutorialWindow(`${baseUrlApi()}images?name=1_in_out_pan.gif`, "Para navegar no mapa, use o botão de rolagem do mouse para zoom in/out e clique com o botão esquerdo e arraste para controlar o pan.", false)
        await displayTutorialWindow(`${baseUrlApi()}images?name=2_lotes.gif`, "Clique em uma área do mapa para ativar os lotes para seleção.", false)
        // #Clique no lote - Retorno de camadas
        await displayTutorialWindow(`${baseUrlApi()}images?name=3_2D.gif`, "Após identificar o local do projeto no mapa, clique no lote ou área selecionada para visualizar as camadas de interferência existentes na região.", false)
        // #Passar o cursor pelas camadas
        await displayTutorialWindow(`${baseUrlApi()}images?name=4_metadados_buffer.gif`, "Você poderá visualizar os dados das camadas ao clicar sobre elas. Ao passar o cursor sobre as camadas que possuem área envoltória, as áreas de influência serão projetadas no mapa.", false)
        // #Legenda
        await displayTutorialWindow(`${baseUrlApi()}images?name=5_legenda.gif`, "Ao clicar nas camadas da legenda, você pode desabilitar e habilitar sua visualização na tela. Quando camadas ficarem sobrepostas, é possível trazê-las para frente ao passar o cursor na legenda sobre seus respectivos nomes.", false)
        // #Seleção de tipologia
        await displayTutorialWindow(`${baseUrlApi()}images?name=6_tipologias.gif`, "Ao clicar novamente no lote ou área selecionada a janela de Tipologias aparecerá. Selecione a(as) tipologia(as) que definem o projeto consultado e clique em Gerar Relatório.", false)
        // #Gerar relatório de Clash
        await displayTutorialWindow(`${baseUrlApi()}images?name=7_3D.gif`, "Você será levado para o modo de visualização 3D. Poderá orbitar a tela ao clicar e arrastar o botão direito do mouse. No modo 3D, apenas as camadas que intereferem no lote ou área do projeto podem ser visualizadas no mapa e na legenda.", false)
        // #Gerar relatório de Clash
        // ctlSidebar.hide(); 
        gifRight = true
        document.getElementById("changeImgSide").innerHTML = "<"
        changeImgSize()
        changeImgSize()
        await displayTutorialWindow(`${baseUrlApi()}images?name=8_clash.gif`, "O resultado do Clash poderá ser visualizado na barra lateral esquerda. Você poderá consultar o estado de interferência da área de projeto ou lote. É possível baixar os resultados em um arquivo ao clicar no ícone de download.", false)

        // #CEP
        // ctlSidebar.show(); 
        await displayTutorialWindow(`${baseUrlApi()}images?name=14_cep.gif`, "Você pode buscar o local do projeto através do ícone de busca por CEP. A interface centralizará a área do endereço na tela para que selecione o lote.", false)
        // #Função de print da tela
        // ctlSidebar.hide(); 
        await displayTutorialWindow(`${baseUrlApi()}images?name=10_print.gif`, "É possível capturar uma imagem da tela e realizar o download da imagem.", false)
        // Historico
        await displayTutorialWindow(`${baseUrlApi()}images?name=11_historico.gif`, "Você pode acessar a função Histórico e resgatar o resultado de Clash no modo 3D ao clicar na respectiva data e horário da consulta.", false)
        // #Poligono
        await displayTutorialWindow(`${baseUrlApi()}images?name=9_poligono.gif`, "Caso a área do projeto não esteja contida em um lote, você pode usar a ferramenta Polígono. Desenhe a área de projeto através dos vértices de um polígono para delimitar uma região de projeto.", false)
        // #Base de mapa
        await displayTutorialWindow(`${baseUrlApi()}images?name=12_basemapa.gif`, "É possível alternar a base do mapa de visualização 2D ao selecionar as opções disponíveis no ícone superior à esquerda.", false)
        // #Tutorial
        await displayTutorialWindow(`${baseUrlApi()}images?name=13_tutorial.gif`, "A qualquer momento é possível consultar este tutorial novamente ao clicar no ícone interrogação na barra lateral da ferramenta.", true)
    }

    function changeImgSize() {
        if (fullscreenGif === false) {
            document.getElementById("changeImgSize").innerHTML = "+"
            document.getElementById("fundoTutorial").style.background = "rgba(0, 0, 0, .0)";
            $("[id^='gifTuto']").css("width", "40vw");
            $("[id^='gifTuto']").css("height", "40vh");
            document.getElementById("changeImgSide").style.display = "block"
            if (gifRight === true) {
                $("[id^='gif']").css("right", "45px");
                $("[id^='gif']").css("left", "");
                $("[class^='checkTipologia']").css("left", "");
                $("[class^='checkTipologia']").css("right", "22%");
            }
            if (gifRight === false) {
                $("[id^='gif']").css("right", "");
                $("[id^='gif']").css("left", "70px");
                $("[class^='checkTipologia']").css("left", "22%");
                $("[class^='checkTipologia']").css("right", "");
            }
            $("[id^='fundoTutorial']").css("pointer-events", "none");
            fullscreenGif = true
        } else if (fullscreenGif === true) {
            document.getElementById("changeImgSize").innerHTML = "Minimizar"
            document.getElementById("fundoTutorial").style.background = "rgba(0, 0, 0, .4)";
            document.getElementById("changeImgSide").style.display = "none"
            $("[id^='gifTuto']").css("width", "80vw");
            $("[id^='gifTuto']").css("height", "80vh");
            $("[id^='gif']").css("left", "");
            $("[id^='gif']").css("right", "");
            $("[id^='fundoTutorial']").css("pointer-events", "auto");
            fullscreenGif = false
        }
    }
    function changeImgSide() {
        if (fullscreenGif === true) {
            if (gifRight === false) {
                document.getElementById("changeImgSide").innerHTML = "<"
                gifRight = true
            }
            else if (gifRight === true) {
                document.getElementById("changeImgSide").innerHTML = ">"
                gifRight = false
            }
            changeImgSize()
            changeImgSize()
        }
    }

    const closeTuto = () => {
        cleanSidebarIcons()
        document.getElementById("fundoTutorial").style.display = "none";
        document.getElementById("fundoTutorial1").style.display = "none";
        $("[class^='checkTipologia']").css("left", "");
        $("[class^='checkTipologia']").css("right", "");
        gifRight = true
    }

    const closeTuto1 = () => {
        cleanSidebarIcons()
        document.getElementById("fundoTutorial").style.display = "none";
        document.getElementById("fundoTutorial1").style.display = "none";
    }

    const nextTuto1 = () => {
        document.getElementById("fundoTutorial1").style.display = "none";
        startTutorial()
    }

    return (
        <>
            <div id="fundoTutorial">
                <div id="gif">
                    <div id="tutorial">
                        <div className="col-md-11 container" >
                            <label style={{ fontSize: "12px", marginBottom: "15px" }} id="texto"></label>
                        </div>
                    </div>
                    {/* <h1 id="titulo" style="font-size: 27px; text-align: center; color: white;"></h1> */}
                    <img src="" id="gifTuto" className="gifTuto" style={{ border: "1px solid #2f77e4", borderRadius: "10px" }} ></img>
                    <span onClick={() => changeImgSize()} id="changeImgSize" title="Mudar tamanho">-</span>
                    <span onClick={() => changeImgSide()} id="changeImgSide" title="Mudar lado">{'>'}</span>
                    <div className="centro">
                        <span id="closeTuto" onClick={closeTuto} style={{ paddingRight: "25px" }}>Fechar</span>
                        <span id="nextTuto" style={{ paddingRight: "10px" }}>Próximo</span>
                    </div>
                </div>
            </div>
            <div id="fundoTutorial1">
                <div id="tutorial1" >
                    <div className="col-md-11 container" >
                        <label style={{ fontSize: "12px", marginBottom: "15px" }} id="texto1">Bem-vinda(o) à ferramenta de análise automatizada de detecção de Clash para a Secretaria de Infraestrutura Urbana e Obras de São Paulo. Você poderá realizar uma análise automatizada das interferências existentes entre uma área de projeto ou obra e os dados existentes no Mapa Digital da Cidade de São Paulo. A seguir, você será guiado em como utilizar a interface, clicando em próximo para continuar e fechar para sair do Tutorial.</label>
                        <div className="centro1">
                            <span id="closeTuto1" onClick={closeTuto1} style={{ paddingRight: "25px" }}>Fechar</span>
                            <span id="nextTuto1" onClick={nextTuto1} style={{ paddingRight: "10px" }}>Próximo</span>
                        </div>
                    </div>
                </div>
                {/* <h1 id="titulo" style="font-size: 27px; text-align: center; color: white;"></h1> */}
            </div>
        </>
    )
}