var tipologia, lotes, globalRisk, clash, afterClash, clashValidator, ids, multiClash, lat, lng,
    zoomMap, multiLote, validateTipologia, allClash, selectedID, lastSelectedID, logicModeClash,
    bufferLote, confirmedLote, rememberClashAux, screenshot, meters, nebulaGeojsonInfo, lista_lotes,
    orientation, image, camadasDeRisco;
var buildingsView = true
var isNebula = false;
var distritoAberto = false
var clashBruto = []
var clashFilter = []
var clashBuilding = []
var finalClash = []
var downloadFinalClash = []
var tipologyValues = []
var Uploadids = []
var UploadFilterids = []
var UploadBuildingids = []
var UploadFinalClashIds = []
var UploadDownloadFinalClashIds = []
var UploadtipologyValuesIds = []
var clashGeojson = []
var bufferLimiter = false
var fixedPopup = false
var enter = false

export function baseUrlApi() {
    return "https://api-bim.geovista.com.br/bim/"
}

//SETTERS

export function setTipologia(value) {
    tipologia = value
}

export function setLotes(value) {
    lotes = value
}

export function setGlobalRisk(value) {
    globalRisk = value
}

export function setClash(value) {
    clash = value
}

export function setAfterClash(value) {
    afterClash = value
}

export function setClashValidator(value) {
    clashValidator = value
}

export function setIds(value) {
    ids = value
}

export function SetDistritoAberto(value) {
    distritoAberto = value
}

export function SetMultiClash(value) {
    multiClash = value
}

export function SetMultiLote(value) {
    multiLote = value
}

export function setValidateTipologia(value) {
    validateTipologia = value
}

export function setAllClash(value) {
    allClash = value
}

export function setSelectedID(value) {
    selectedID = value
}

export function setLastSelectedID(value) {
    lastSelectedID = value
}

export function cleanClashs() {
    clashBruto = []
    clashFilter = []
    clashBuilding = []
    finalClash = []
    downloadFinalClash = []
    tipologyValues = []
    Uploadids = []
    UploadFilterids = []
    UploadBuildingids = []
    UploadFinalClashIds = []
    UploadDownloadFinalClashIds = []
    UploadtipologyValuesIds = []
}

export function cleanFilterClashs() {
    clashFilter = []
    clashBuilding = []
    finalClash = []
    UploadFilterids = []
    UploadBuildingids = []
    UploadFinalClashIds = []
}

export function setClashBruto(id, json) {
    if (!(Uploadids.includes(id))) {
        Uploadids.push(id)
        let count = Uploadids.length - 1
        clashBruto[count] = [id, json]
    }
}

export function setClashFilter(id, p1, p2, p3, p4, p5, p6) {
    // console.log(id, p1, p2, p3, p4, p5, p6)
    let json = []
    for (let i = 0; i < 6; i++) {
        let layer = [];
        let featureName;
        if (i === 0) { layer = (p1) ? p1 : []; featureName = "interseccao_poligono" }
        if (i === 1) { layer = (p2) ? p2 : []; featureName = "interseccao_polilinha" }
        if (i === 2) { layer = (p3) ? p3 : []; featureName = "interseccao_poligono_offset" }
        if (i === 3) { layer = (p4) ? p4 : []; featureName = "interseccao_circulo" }
        if (i === 4) { layer = (p5) ? p5 : []; featureName = "interseccao_atributo" }
        if (i === 5) { layer = (p6) ? p6 : []; featureName = "lote" }

        let jsonAux = {
            "name": featureName,
            "type": "FeatureCollection",
            "features": []
        }

        console.log('layer', layer)

        for (let j = 0; j < layer.length; j++) {
            if (layer[j].features) {
                for (let i = 0; i < layer[j].features.length; i++) {
                    jsonAux.features.push(layer[j].features[i])
                }
            }
        }
        if (i === 4) {
            if (layer.features) {
                for (let x = 0; x < layer.features.length; x++) {
                    jsonAux.features.push(layer.features[x])
                }
            }
        }
        if (i === 5) {
            jsonAux.features.push(layer)
        }
        json.push(jsonAux)
    }
    for (let i = 0; i < clashFilter.length; i++) {
        if (clashFilter[i][0] === id) {
            clashFilter.splice(i, 1);
        }
    }
    for (let i = 0; i < UploadFilterids.length; i++) {
        if (UploadFilterids[i] === id) {
            UploadFilterids.splice(i, 1);
        }
    }
    if (!(UploadFilterids.includes(id))) {
        UploadFilterids.push(id)
        let count = UploadFilterids.length - 1
        clashFilter[count] = [id, json]
    }
    console.log("clashFilter", clashFilter)
}

export function setClashBuilding(id, json) {
    if (!(UploadBuildingids.includes(id))) {
        UploadBuildingids.push(id)
        let count = UploadBuildingids.length - 1
        clashBuilding[count] = [id, json]
    }
}

export function setFinalClash(id, json) {
    if (!(UploadFinalClashIds.includes(id))) {
        UploadFinalClashIds.push(id)
        let count = UploadFinalClashIds.length - 1
        finalClash[count] = [id, json]
    }
    // ("finalClash", finalClash)
}

export function setDownloadFinalClash(id, json) {
    for (let i = 0; i < downloadFinalClash.length; i++) {
        if (downloadFinalClash[i][0] === id) {
            downloadFinalClash.splice(i, 1);
        }
    }
    for (let i = 0; i < UploadDownloadFinalClashIds.length; i++) {
        if (UploadDownloadFinalClashIds[i] === id) {
            UploadDownloadFinalClashIds.splice(i, 1);
        }
    }
    if (!(UploadDownloadFinalClashIds.includes(id))) {
        UploadDownloadFinalClashIds.push(id)
        let count = UploadDownloadFinalClashIds.length - 1
        downloadFinalClash[count] = [id, json]
    }
}

export function setTipologyValues(id, json) {
    for (let i = 0; i < tipologyValues.length; i++) {
        if (tipologyValues[i][0] === id) {
            tipologyValues.splice(i, 1);
        }
    }
    for (let i = 0; i < UploadtipologyValuesIds.length; i++) {
        if (UploadtipologyValuesIds[i] === id) {
            UploadtipologyValuesIds.splice(i, 1);
        }
    }
    if (!(UploadtipologyValuesIds.includes(id))) {
        UploadtipologyValuesIds.push(id)
        let count = UploadtipologyValuesIds.length - 1
        tipologyValues[count] = [id, json]
    }
}

export function setBuildingsView(value) {
    buildingsView = value
}

export function setLogicModeClash(value) {
    logicModeClash = value
}

export function setBufferLote(value) {
    bufferLote = value
}

export function setConfirmedLote(value) {
    confirmedLote = value
}

export function setRememberClashAux(value) {
    rememberClashAux = value
}

export function setBufferLimiter(value) {
    bufferLimiter = value
}

export function setScreenshot(value) {
    screenshot = value
}

export function setFixedPopup(value) {
    fixedPopup = value
}

export function setEnter(value) {
    enter = value
}

export function setMeters(value) {
    meters = value
}

export function setNebulaGeojsonInfo(value) {
    nebulaGeojsonInfo = value
}

export function setListaLotes(value) {
    lista_lotes = value
}

export function setLat(value) {
    lat = value
}

export function setLng(value) {
    lng = value
}

export function setZoomMap(value) {
    zoomMap = value
}

export function setOrientation(value) {
    orientation = value
}

export function setImage(value) {
    image = value
}

export function setCamadasDeRisco(value) {
    camadasDeRisco = {
        "name": "Features",
        "type": "FeatureCollection",
        "features": []
    }
    for (let i = 0; i < value.length; i++) {
        for (let j = 0; j < value[i].features.length; j++) {
            camadasDeRisco.features.push(value[i].features[j])
        }
    }
}

export function setisNebula(value) {
    isNebula = value
}

export function setClashGeojson(value) {
    clashGeojson = value
}

// GETTERS

export function getTipologia() {
    return tipologia
}

export function getLotes() {
    return lotes
}

export function getGlobalRisk() {
    return globalRisk
}

export function getClash() {
    return clash
}

export function getAfterClash() {
    return afterClash
}

export function getClashValidator() {
    return clashValidator
}

export function getIds() {
    return ids
}

export function getDistritoAberto() {
    return distritoAberto
}

export function getMultiClash() {
    return multiClash
}

export function getMultiLote() {
    return multiLote
}

export function getValidateTipologia() {
    return validateTipologia
}

export function getAllClash() {
    return allClash
}

export function getClashBruto() {
    return clashBruto
}

export function getClashFilter() {
    return clashFilter
}

export function getClashBuilding() {
    return clashBuilding
}

export function getSelectedID() {
    return selectedID
}

export function getLastSelectedID() {
    return lastSelectedID
}

export function getFinalClash() {
    return finalClash
}

export function getDownloadFinalClash() {
    return downloadFinalClash
}

export function getTipologyValues() {
    return tipologyValues
}

export function getBuildingsView() {
    return buildingsView
}

export function getLogicModeClash() {
    return logicModeClash
}

export function getBufferLote() {
    return bufferLote
}

export function getConfirmedLote() {
    return confirmedLote
}

export function getRememberClashAux() {
    return rememberClashAux
}

export function getBufferLimiter() {
    return bufferLimiter
}

export function getScreenshot() {
    return screenshot
}

export function getFixedPopup() {
    return fixedPopup
}

export function getEnter() {
    return enter
}

export function getMeters() {
    return meters
}

export function getNebulaGeojsonInfo() {
    return nebulaGeojsonInfo
}

export function getListaLotes() {
    return lista_lotes
}

export function getLat() {
    return lat
}

export function getLng() {
    return lng
}

export function getZoomMap() {
    return zoomMap
}

export function getOrientation() {
    return orientation
}

export function getImage() {
    return image
}

export function getCamadasDeRisco() {
    return camadasDeRisco
}

export function getisNebula() {
    return isNebula
}

export function getClashGeojson() {
    return clashGeojson
}