import * as turf from '@turf/turf';
import { setIds } from "../DataHandler/DataHandler";

// importScripts('https://cdnjs.cloudflare.com/ajax/libs/Turf.js/6.3.0/turf.min.js');
// importScripts('constants.js');
// importScripts('auxiliarylibrary.js');
let ids = []
const EXCESSAO_TIPOLOGIA = 'recuperação ambiental'
const MASSA_DAGUA = 'massa_dagua'
const AREA_PROTECAO_MANANCIAIS_TITULO = 'Área de Proteção aos Mananciais'

const INTERSECCAO_POLIGONO = "interseccao_poligono"
const INTERSECCAO_CIRCULO = "interseccao_circulo"
const INTERSECCAO_POLILINHA = "interseccao_polilinha"
const INTERSECCAO_POLIGONO_OFFSET = "interseccao_poligono_offset"
const INTERSECCAO_ATRIBUTO = "interseccao_atributo"

function interseccao_poligono(lote, json, tipologia) {
    try {
        tipologia = JSON.stringify(tipologia).toLowerCase()
        tipologia = JSON.parse(tipologia)
        let vetorRetorno = []

        const numberOfPolygons = json.features.length - 1
        let area_protecao_manaciais_flag = false
        let zeis_flag = false
        let pqaVetor = []
        let outputPQA = []
        for (let i = 0; i <= numberOfPolygons; i++) {
            const nome = json.features[i].properties.nome
            var output = ""
            const polygon = json.features[i].geometry.coordinates[0]
            const turfPolygon = turf.polygon(polygon)
            const turfLote = turf.polygon(lote)
            const hasIntersection = turf.booleanIntersects(turfPolygon, turfLote)
            if (hasIntersection) {
                ids.push(json.features[i].properties.id)
                let tipologiaJSON = json.features[i].properties.tipologia

                if (nome === 'area_protecao_mananciais') { area_protecao_manaciais_flag = true }
                if (nome === 'zeis_1' || nome === 'zeis2345') { zeis_flag = true }

                if (nome === 'area_contaminada_reabilitada') {
                    const atributo_valor = json.features[i].properties.atributo_chave
                    const isContaminated = (json.features[i].properties[atributo_valor] === "Contaminada")
                    if (isContaminated) {
                        if (tipologia.includes(EXCESSAO_TIPOLOGIA)) {
                            output = json.features[i].properties.output_hasI_hasT_special
                            //   alert("contaminada excessao")
                        } else {
                            output = json.features[i].properties.output_hasI_isC
                            //   alert("contaminada")
                        }
                    } else {
                        //   alert("nao contaminada")
                        output = json.features[i].properties.output_hasI_isntC
                    }
                } else if (nome === 'qualificacao_ambiental') {
                    const atributo_valor = json.features[i].properties.atributo_chave
                    const pqa = json.features[i].properties[atributo_valor]
                    if (!pqaVetor.includes(pqa)) {
                        pqaVetor.push(pqa)
                    }
                    outputPQA = {
                        "inicio": json.features[i].properties.output_hasI,
                        "fim": json.features[i].properties.output_hasI_final,
                        "titulo": json.features[i].properties.titulo,
                        "algoritmo": json.features[i].properties.algoritmo,
                        "numero": json.features[i].properties.numero,
                        "metadado": json.features[i].properties.metadado
                    }
                } else {
                    if (tipologiaJSON !== undefined) { // quando tem tipologia no txt
                        tipologiaJSON = tipologiaJSON.toLowerCase().replace(/\s/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        const vetorTipologiaJSON = tipologiaJSON.split(',')

                        // captura vetor de tipologias coincidentes
                        let vetorTipologiaOutput = []
                        tipologia.forEach(function (valor) {
                            if (vetorTipologiaJSON.includes(valor)) {
                                vetorTipologiaOutput.push(valor)
                            }
                        });

                        // se vetorTipologiaOutput coincide
                        if (tipologia.includes(EXCESSAO_TIPOLOGIA) && (json.features[i].properties.output_hasI_hasT_special !== undefined)) {
                            output = json.features[i].properties.output_hasI_hasT_special
                        } else if (vetorTipologiaOutput.length > 0) {
                            output = json.features[i].properties.output_hasI_hasT
                        } else {
                            output = json.features[i].properties.output_hasI_hasntT
                        }
                    } else { // quando nao tem tipologia no txt
                        output = json.features[i].properties.output_hasI_hasntT
                    }
                }
            } else {
                // output = json.features[i].properties.output_hasntI
                // concatenar todos os jsons no backend
            }

            // evita duplicidade de valores
            const titulo = json.features[i].properties.titulo
            const containString = checkStringInsideArrayOfObjects(titulo, vetorRetorno)
            if (!containString && output != "") {
                const objeto = {
                    "titulo": json.features[i].properties.titulo,
                    "output": output,
                    "algoritmo": json.features[i].properties.algoritmo,
                    "numero": json.features[i].properties.numero,
                    "metadado": json.features[i].properties.metadado
                }
                vetorRetorno.push(objeto)
            }

        }

        // cria output para output PQA (qualificacao ambiental)
        const hasPQA = (outputPQA !== undefined)
        // const hasPQA =  (outputPQA.length > 0)
        if (hasPQA) {
            // const pqa = JSON.stringify(pqaVetor).split(",")
            const pqa = pqaVetor.join(", ")
            output = outputPQA.inicio + pqa + outputPQA.fim
            const objeto = {
                "titulo": outputPQA.titulo,
                "output": output,
                "algoritmo": outputPQA.algoritmo,
                "numero": outputPQA.numero,
                "metadado": outputPQA.metadado
            }
            if (objeto.titulo !== undefined) {
                vetorRetorno.push(objeto)
            }
        }

        // substitui ou concatena area de protecao mananciais (se pre-analise inclui protecao manancial, substitui, caso contrario, concatena)
        if (area_protecao_manaciais_flag && zeis_flag) {
            for (let i = 0; i < vetorRetorno.length; i++) {
                const titulo = json.features[i].properties.titulo
                if (titulo === AREA_PROTECAO_MANANCIAIS_TITULO) {
                    const objeto = {
                        "titulo": json.features[i].properties.titulo,
                        "output": json.features[i].properties.output_hasI_hasZeis,
                        "algoritmo": json.features[i].properties.algoritmo,
                        "numero": json.features[i].properties.numero,
                        "metadado": json.features[i].properties.metadado
                    }
                    vetorRetorno[i] = objeto
                } else {
                    const output = json.features[i].properties.output_hasI_hasZeis
                    const objeto = {
                        "titulo": json.features[i].properties.titulo,
                        "output": output,
                        "algoritmo": json.features[i].properties.algoritmo,
                        "numero": json.features[i].properties.numero,
                        "metadado": json.features[i].properties.metadado
                    }
                    vetorRetorno.push(objeto)
                }
                // vetorRetorno[i]
            }
        }
        return vetorRetorno
    } catch (error) { console.log(error) }
}

function interseccao_circulo(lote, json, tipologia) {
    const bufferedCirculo = bufferJSON(json, lote)  // funcao auxiliar
    return interseccao_poligono(lote, bufferedCirculo, tipologia)
}

function interseccao_polilinha(lote, json, tipologia) {
    const bufferedPolilinha = bufferJSON(json, lote)  // funcao auxiliar
    return interseccao_poligono(lote, bufferedPolilinha, tipologia)
}

function interseccao_poligono_offset(lote, json, tipologia) {
    const bufferedPoligono = bufferJSON(json, lote)  // funcao auxiliar
    return interseccao_poligono(lote, bufferedPoligono, tipologia)
}

function interseccao_atributo(lote, json, tipologia) {
    return interseccao_poligono(lote, json, tipologia)
}

export function executa_algoritmos(tipologias, lote, riskLayer) {
    // const tipologias = ['edificação de educação', 'edificação de saúde', 'recuperação ambiental']
    let clash = []
    let indice
    indice = getIndex(riskLayer, INTERSECCAO_POLIGONO)
    if (indice !== null) {
        const algorithmResult = interseccao_poligono(lote, riskLayer[indice], tipologias)
        clash = jsonConcat(clash, algorithmResult)  // funcao auxiliar
    }

    indice = getIndex(riskLayer, INTERSECCAO_CIRCULO)
    if (indice !== null) {
        const algorithmResult = interseccao_circulo(lote, riskLayer[indice], tipologias)
        clash = jsonConcat(clash, algorithmResult)  // funcao auxiliar
    }

    indice = getIndex(riskLayer, INTERSECCAO_POLILINHA)
    if (indice !== null) {
        const algorithmResult = interseccao_polilinha(lote, riskLayer[indice], tipologias)
        clash = jsonConcat(clash, algorithmResult)  // funcao auxiliar
    }

    indice = getIndex(riskLayer, INTERSECCAO_POLIGONO_OFFSET)
    if (indice !== null) {
        const algorithmResult = interseccao_poligono_offset(lote, riskLayer[indice], tipologias)
        clash = jsonConcat(clash, algorithmResult)  // funcao auxiliar
    }

    indice = getIndex(riskLayer, INTERSECCAO_ATRIBUTO)
    if (indice !== null) {
        const algorithmResult = interseccao_atributo(lote, riskLayer[indice], tipologias)
        clash = jsonConcat(clash, algorithmResult)  // funcao auxiliar
    }
    setIds(ids)
    ids = []
    return clash;
}

// usada no nodo "get areas" para capturar o indice do algoritmo no vetor de riscos
function getIndex(json, algoritmo) {
    for (let i = 0, len = json.length; i < len; i++) {
        const name = json[i].name
        if (name === algoritmo) {
            return i
        }
    }
    return null
}

// usada no nodo "biblioteca de algoritmos" para criar um buffer de geoJSONs com pontos, poligonos e polilinhas
function bufferJSON(json, lote) {
    let jsonAux = JSON.parse(JSON.stringify(json));
    let loteAux = JSON.parse(JSON.stringify(lote));

    for (let i = 0, len = jsonAux.features.length; i < len; i++) {
        let offset
        const nome = jsonAux.features[i].properties.nome
        if (nome === MASSA_DAGUA) {
            const rio = jsonAux.features[i].geometry.coordinates[0]
            const distance = getRiverDistance(loteAux, rio)
            if (distance <= 10) {
                offset = 30
            } else if (distance > 10 && distance <= 50) {
                offset = 50
            } else if (distance > 50 && distance <= 200) {
                offset = 100
            } else if (distance > 200 && distance <= 600) {
                offset = 200
            } else if (distance > 600) {
                offset = 500
            }
        } else {
            offset = jsonAux.features[i].properties.offset
        }
        let bufferedPolygon
        const sizePolygon = jsonAux.features[i].geometry.coordinates[0][0].length // verifica tamanho de pontos no poligono
        const polygon = jsonAux.features[i].geometry.coordinates[0][0]
        const turfPolygon = turf.multiPolygon([[polygon]])
        if (sizePolygon > 10000) { // caso poligono seja muito grande, simplifica ele
            const options = { tolerance: 0.00001, highQuality: false };
            const simplified = turf.simplify(turfPolygon, options);
            bufferedPolygon = turf.buffer(simplified, offset, { units: 'meters' })
            jsonAux.features[i].geometry = bufferedPolygon.geometry
        } else {
            bufferedPolygon = turf.buffer(jsonAux.features[i], offset, { units: 'meters' })
            jsonAux.features[i] = bufferedPolygon
        }
        jsonAux.features[i].geometry.type = "MultiPolygon"
        jsonAux.features[i].geometry.coordinates = [jsonAux.features[i].geometry.coordinates]
    }
    return jsonAux
}

function getRiverDistance(lote, rio) {
    const turfPolygonLote = turf.polygon(lote, rio)
    const turfPolygonRiver = turf.polygon(rio)

    const centerLote = turf.centerOfMass(turfPolygonLote);
    const lineRiver = turf.polygonToLine(turfPolygonRiver);

    const pointRiver = turf.nearestPointOnLine(lineRiver, centerLote, { units: 'meters' });
    const bearing = turf.bearing(centerLote, pointRiver);

    const destinationPoint = turf.destination(pointRiver, 1000, bearing, { units: 'meters' });
    const destinationLine = turf.lineString([pointRiver.geometry.coordinates, destinationPoint.geometry.coordinates]);

    const pointCrossedRiver = turf.lineIntersect(lineRiver, destinationLine);
    //	const lineCrossingRiver = turf.lineString([pointRiver.geometry.coordinates, pointCrossedRiver.features[0].geometry.coordinates]);

    const distance = turf.distance(pointRiver.geometry.coordinates, pointCrossedRiver.features[0].geometry.coordinates, { units: 'meters' });
    return distance
}


// usada no nodo "get areas" para concatenar outputs de algoritmos
function checkStringInsideArrayOfObjects(string, array) {
    for (let i = 0, len = array.length; i < len; i++) {
        if (array[i].titulo === string) {
            return true
        }
    }
    return false
}

// usada no node "get areas" para concatenar o resultado de cada algoritmo em um unico Array
function jsonConcat(json1, json2) {
    for (let i = 0, len = json2.length; i < len; i++) {
        json1.push(json2[i])
    }
    return json1
}
