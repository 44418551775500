import $ from "jquery";
import { baseUrlApi, setGlobalRisk, SetMultiLote } from "../../../DataHandler/DataHandler";
import { Loading } from "../../Loading/LoadingScreen";
import { cleanSidebarIcons } from "../../Sidebar/Sidebar";
import { changeLotes, setDistritos } from "../Deck Components/Organizar Layers/DeckPrepare";
import OrganizaLayers, { OrganizaLayerClashMode } from "../Deck Components/Organizar Layers/OrganizaLayers";
import { changeSelectOrRotate } from "../Deck Components/Visual Settings/DeckComponent";

export function prepareLotes(lng, lat, info) {
    $.ajax({
        type: "POST",
        // url: `${baseUrlApi()}getlotes`,
        url: `https://api-bim.geovista.com.br/bim/getlotes`,
        // url: `https://api-bim.geovista.com.br/bim/getlotes`,
        data: { "lat": lat, "lng": lng },
        dataType: 'JSON',
        success: function (lotes) {
            if (lotes.features.length === 0) {
                info.foraDeArea = true
                changeSelectOrRotate(info)
            }
            for (let i = 0; i < lotes.features.length; i++) {
                lotes.features[i].properties.isClicked = false
            }
            SetMultiLote(lotes)
            changeLotes()
        }
    })
}

export function getDistritos() {
    $.ajax({
        type: "GET",
        url: "https://api.geovista.com.br/rugosidade/distritos",
        dataType: 'JSON',
        success: function (layers) {
            for (let i = 0; i < layers.features.length; i++) {
                layers.features[i].properties.layerActivated = false
                layers.features[i].properties.isDistrito = true
            }
            setDistritos(layers)
        }
    });
}

export function prepareRisks(lote, loteUnico, id) {
    Loading("iniciar")
    $.ajax({
        type: "POST",
        url: `${baseUrlApi()}getriscos`,
        data: {
            "lote": JSON.stringify(lote),
            "layer": "visivel"
        },
        dataType: 'JSON',
        success: function (response) {
            $.ajax({
                type: "POST",
                url: `${baseUrlApi()}getriscos`,
                data: {
                    "lote": JSON.stringify(lote),
                    "layer": "invisivel"
                },
                dataType: 'JSON',
                success: function (layers) {
                    for (let i = 0; i < response.length; i++) {
                        for (let j = 0; j < layers.length; j++) {
                            if (response[i].name === layers[j].name) {
                                layers[j].complete = true
                                for (let y = 0; y < layers[j].features.length; y++) {
                                    response[i].features.push(layers[j].features[y])
                                }
                            }
                        }
                    }
                    for (let i = 0; i < layers.length; i++) {
                        if (layers[i].complete !== true) {
                            response.push(layers[i])
                        }
                    }
                    setGlobalRisk(response)
                }
            });
            OrganizaLayers(response, loteUnico, undefined, id)
        }
    });
}

export function prepareRisksClashMode(lote, loteUnico, id) {
    Loading("iniciar")
    $.ajax({
        type: "POST",
        url: `${baseUrlApi()}getriscos`,
        data: {
            "lote": JSON.stringify(lote),
            "layer": "visivel"
        },
        dataType: 'JSON',
        success: function (response) {
            $.ajax({
                type: "POST",
                url: `${baseUrlApi()}getriscos`,
                data: {
                    "lote": JSON.stringify(lote),
                    "layer": "invisivel"
                }, dataType: 'JSON',
                success: function (layers) {
                    for (let i = 0; i < response.length; i++) {
                        for (let j = 0; j < layers.length; j++) {
                            if (response[i].name === layers[j].name) {
                                layers[j].complete = true
                                for (let y = 0; y < layers[j].features.length; y++) {
                                    response[i].features.push(layers[j].features[y])
                                }
                            }
                        }
                    }
                    for (let i = 0; i < layers.length; i++) {
                        if (layers[i].complete !== true) {
                            response.push(layers[i])
                        }
                    }
                    response.slice()
                    setGlobalRisk(response)
                }
            });
            OrganizaLayerClashMode(response, loteUnico, undefined, id)
        }
    });
}

export async function linkCamadaDeRisco(lote, limiter) {
    async function main() {
        Loading("iniciar")
        const requestRiscos = await postRequest(`${baseUrlApi()}getriscos`, { "lote": JSON.stringify(lote) })
        let requestRiscosInsiviseis;
        await $.ajax({
            type: "POST",
            url: `${baseUrlApi()}getriscos`,
            data: {
                "lote": JSON.stringify(lote),
                "layer": "invisivel"
            }, dataType: 'JSON',
            success: function (layers) {
                requestRiscosInsiviseis = layers
            }
        });
        requestRiscos[requestRiscos.length] = {
            "name": "interseccao_atributo",
            "type": "FeatureCollection",
            "features": []
        }
        for (let i = 0; i < requestRiscos.length; i++) {
            for (let j = 0; j < requestRiscosInsiviseis.length; j++) {
                if (requestRiscos[i].name === requestRiscosInsiviseis[j].name) {
                    requestRiscosInsiviseis[j].complete = true
                    for (let y = 0; y < requestRiscosInsiviseis[j].features.length; y++) {
                        requestRiscos[i].features.push(requestRiscosInsiviseis[j].features[y])
                    }
                }
            }
        }
        for (let i = 0; i < requestRiscosInsiviseis.length; i++) {
            if (requestRiscosInsiviseis[i].complete !== true) {
                requestRiscos.push(requestRiscosInsiviseis[i])
            }
        }
        requestRiscos.slice()
        console.log("requestRiscos", requestRiscos)
        setGlobalRisk(requestRiscos)

        const loteFormatado = {
            "type": "Feature",
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": []
            },
            "properties": {}
        }
        const aux = []; aux.push(lote)
        loteFormatado.geometry.coordinates.push(aux);
        OrganizaLayers(requestRiscos, loteFormatado, limiter)
        Loading("fechar")
        cleanSidebarIcons()
    }
    main()

    function postRequest(url, data) {
        return new Promise(resolve => {
            $.ajax({
                type: "POST",
                url: url,
                data: data,
                dataType: 'JSON',
                success: function (layers) {
                    resolve(layers)
                }
            })
        });
    }
}