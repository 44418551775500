import React from "react";
import { TipologiaOptions } from "./TipologiaOptions";
import $ from "jquery";
import "./Tipologia.css"
import { changeViewState, createBuildings, executaAlgoritmo } from "../Deck/Deck Components/Organizar Layers/DeckPrepare";
import { cleanModals, searchSide } from "../Sidebar/Sidebar";
import { baseUrlApi, getClashFilter, getFixedPopup, getGlobalRisk, getLogicModeClash, getLotes, getMultiClash, getNebulaGeojsonInfo, getRememberClashAux, getSelectedID, setAfterClash, setBuildingsView, setClash, setClashBuilding, setClashGeojson, setDownloadFinalClash, setFinalClash, setGlobalRisk, setIds, setListaLotes, setLogicModeClash, setNebulaGeojsonInfo, setRememberClashAux, setTipologyValues } from "../../DataHandler/DataHandler";
import { auxCallRemember, getFalseOnUploadRisk, getUploadRisk, prepareCamadaClash } from "../Deck/Deck Functions/LayersControl";
import { addCheckboxOption } from "../Sidebar/DownloadClash/DownloadClash";
import { Loading } from "../Loading/LoadingScreen";
import * as turf from '@turf/turf';
import Worker from "web-worker"
import { changeSelectOrRotate } from "../Deck/Deck Components/Visual Settings/DeckComponent";
import { inputIDClash } from "../../Format JSON/formatJson";
const worker = new Worker(
    // new URL('${baseUrlApi()}worker', import.meta.url),
    new URL('../../Worker/WorkerLogic.js', import.meta.url),
    { type: 'module' }
);

var tipologias = []

export function iniciarModal(modalID) {
    const modal = document.getElementById(modalID);
    modal.classList.add('mostrar')
    modal.addEventListener('click', (e) => {
        if (e.target.id === 'botaoConfirm') {
            modal.classList.remove('mostrar')
            document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
        }
        if (e.target.id === modalID || e.target.id === 'botao') {
            getFalseOnUploadRisk()
            // setConfirmedLote(undefined)
            // createBuildings(undefined)
            setLogicModeClash(false)
            setRememberClashAux(false)
            modal.classList.remove('mostrar')
            document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
        }
    })
}

export function resetTipologys() {
    tipologias = []
}

export function getNames(name) {
    tipologias.push(name)
    tipologias = removeDuplicates(tipologias)
    function removeDuplicates(arr) {
        var counts = arr.reduce(function (counts, item) {
            counts[item] = (counts[item] || 0) + 1;
            return counts;
        }, {});
        return Object.keys(counts).reduce(function (arr, item) {
            if (counts[item] === 1) {
                arr.push(item);
            }
            return arr;
        }, []);
    }
}

export function Tipologia() {
    const tipologyLogic = () => {
        document.getElementById("NorthCompass").style.opacity = 0
        document.getElementById("orientation").style.pointerEvents = "all"
        document.getElementById("orientation").style.opacity = 1
        if (getRememberClashAux() === true) {
            auxCallRemember()
        } else if (getLogicModeClash() === true) {
            prepareCamadaClash()
            setLogicModeClash(false)
        } else {
            getTypology()
        }
    }

    return (
        <div className="container-fluid">
            <div id="tipologiaCheckbox" className="mod-tipologia">
                <div className="checkTipologia">
                    <div className="check">
                        <h1 className="titlefont">Tipologias</h1>
                        <p className="container" style={{ fontSize: "12px", textAlign: "justify", marginTop: "-10px" }}>As tipologias de projeto podem alterar o resultado de conformidade ou não conformidade entre o local de projeto e as camadas existentes na região.
                            Marque a(as) tipologia(as) correpondentes ou deixe em branco caso nenhuma das tipologias correpondam ao projeto.</p>
                        <TipologiaOptions nome={nome1} desc={desc1} id="tipo1" />
                        <TipologiaOptions nome={nome2} desc={desc2} id="tipo2" />
                        <TipologiaOptions nome={nome3} desc={desc3} id="tipo3" />
                        <TipologiaOptions nome={nome4} desc={desc4} id="tipo4" />
                        <TipologiaOptions nome={nome5} desc={desc5} id="tipo5" />
                        <TipologiaOptions nome={nome6} desc={desc6} id="tipo6" />
                        <TipologiaOptions nome={nome7} desc={desc7} id="tipo7" />
                        <TipologiaOptions nome={nome8} desc={desc8} id="tipo8" />
                        <TipologiaOptions nome={nome9} desc={desc9} id="tipo9" />
                        <TipologiaOptions nome={nome10} desc={desc10} id="tipo10" />
                        <TipologiaOptions nome={nome11} desc={desc11} id="tipo11" />
                        <br />
                        <button className="btn btn-primary btn-lg direita"
                            onClick={tipologyLogic}
                            id="botaoConfirm" >OK</button>
                        <div>
                            <span className="botfechar" id="botao" >
                                <i id="botao" className="far fa-times imgBot"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const nome1 = "Edificação de educação"
const nome2 = "Edificação de saúde"
const nome3 = "Edificação de Habitação de Interesse Popular"
const nome4 = "Edificação de Habitação de Mercado Popular"
const nome5 = "Edificação de outro fim"
const nome6 = "Obra de arte"
const nome7 = "Sistema viário"
const nome8 = "Drenagem"
const nome9 = "Regularização fundiária"
const nome10 = "Recuperação ambiental"
const nome11 = "Obras de contenção ou redução de risco"

const desc1 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: Bem de Interesse Arqueológico, Lugar de Interesse Paisagistico, Ocorrência Arqueológica, Sítio Arqueológico, Perímetros de Incentivos, Eixos Ativados por Decreto, ZEIS 2-3-4-5, Cadastro de Áreas Públicas, Área Cedida, ZEPEC Indicações"
const desc2 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: Bem de Interesse Arqueológico, Lugar de Interesse Paisagistico, Ocorrência Arqueológica, Sítio Arqueológico, Perímetros de Incentivos, Eixos Ativados por Decreto, ZEIS 2-3-4-5, Cadastro de Áreas Públicas, Área Cedida, ZEPEC Indicações"
const desc3 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: Bem de Interesse Arqueológico, Lugar de Interesse Paisagistico, Ocorrência Arqueológica, Sítio Arqueológico, ZEIS 1, ZEIS 2-3-4-5, ZEPEC Indicações"
const desc4 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: Loteamento Irregular, Bairro Ambiental, Bem de Interesse Arqueológico, Lugar de Interesse Paisagistico, Ocorrência Arqueológica, Sítio Arqueológico, ZEIS 1, ZEIS 2-3-4-5, ZEPEC Indicações"
const desc5 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: Loteamento Irregular, Bairro Ambiental, Bem de Interesse Arqueológico, Lugar de Interesse Paisagistico, Ocorrência Arqueológica, Sítio Arqueológico"
const desc6 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: Bairro Ambiental, Bem de Interesse Arqueológico, Lugar de Interesse Paisagistico, Ocorrência Arqueológica, Sítio Arqueológico, Utilidade Pública e Interesse Social, ZEPEC Indicações"
const desc7 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: Bairro Ambiental, Bem de Interesse Arqueológico, Lugar de Interesse Paisagistico, Ocorrência Arqueológica, Sítio Arqueológico"
const desc8 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: Bairro Ambiental, Bem de Interesse Arqueológico, Lugar de Interesse Paisagistico, Ocorrência Arqueológica, Sítio Arqueológico"
const desc9 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: ZEIS 1, ZEIS 2-3-4-5, ZEPEC Indicações"
const desc10 = "Esta tipologia possui inteferência nos resultados de conformidade das camadas: ZEIS 1, ZEIS 2-3-4-5, ZEPEC Indicações, Área Contaminada e Reabilitada"
const desc11 = "Essa tipologia possui interferência nos resultados de conformidade das camadas: Alagamento, Inundação, Risco Geológico e Risco Hidrológico"

export function getTypology() {
    getFixedPopup()
    changeSelectOrRotate(null)
    getUploadRisk()
    setBuildingsView(true)
    Loading("iniciar")
    const globalLote = getLotes()
    cleanModals()
    setTipologyValues(getSelectedID(), tipologias)
    tipologias = tipologias.join(',')
    tipologias = tipologias.toLowerCase().replace(/\s/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(',')
    if (getMultiClash() === true) {
        $.ajax({
            type: "POST",
            url: `${baseUrlApi()}getedificacao`,
            data: { "lote": JSON.stringify(globalLote.geometry.coordinates[0]) },
            dataType: 'JSON',
            success: function (layers) {
                createBuildings(layers)
                setClashBuilding(getSelectedID(), layers)
                Loading("fechar")
            }
        });
    } else {
        $.ajax({
            type: "POST",
            url: `${baseUrlApi()}getedificacao`,
            data: { "lote": JSON.stringify(globalLote.geometry.coordinates[0]) },
            dataType: 'JSON',
            success: function (layers) {
                createBuildings(layers)
                Loading("fechar")
            }
        });
    }
    let workerOrganize = []
    workerOrganize.push(tipologias);
    workerOrganize.push(globalLote);
    const layersClash = inputIDClash(getGlobalRisk())
    setGlobalRisk(layersClash)
    workerOrganize.push(layersClash);
    workerOrganize.push(getNebulaGeojsonInfo());
    worker.postMessage(workerOrganize);
    worker.addEventListener('message', message => {
        setClash(message.data[0])
        setIds(message.data[1])
        setListaLotes(message.data[2])
        setClashGeojson(message.data[4])
        console.log('filtered geojson',message.data[4])
        if (getMultiClash() === true) {
            setDownloadFinalClash(getSelectedID(), message.data[0])
            addCheckboxOption()
        }
        $.ajax({
            type: "POST",
            url: `${baseUrlApi()}getclash`,
            data: { "clash": message.data[0] },
            dataType: 'JSON',
            success: function (finalClash) {
                executaAlgoritmo(finalClash)
                if (getMultiClash() === true) {
                    setFinalClash(getSelectedID(), finalClash)
                }
                tipologias = []
            }
        })
        if (getNebulaGeojsonInfo() !== undefined) {
            globalLote.properties = getNebulaGeojsonInfo()
        }
        salvaHistorico(tipologias, globalLote)
        setNebulaGeojsonInfo()
    });
    // const polygon = turf.polygon(globalLote);
    // const polygon = turf.polygon(globalLote);
    const area = turf.area(globalLote);
    const centroid = turf.centroid(globalLote);
    const lng = centroid.geometry.coordinates[0]
    const lat = centroid.geometry.coordinates[1]

    if (getMultiClash() === true) {
        if (getClashFilter().length < 1) {
            if (area >= 1 && area < 7872.420654785371) {
                changeViewState(lng, lat, 500, 17.5, 50)
            } else if (area >= 7872.420654785371 && area < 144320.60350029863) {
                changeViewState(lng, lat, 500, 16.5, 50)
            } else {
                changeViewState(lng, lat, 500, 15, 50)
            }
        }
    } else {
        if (area >= 1 && area < 7872.420654785371) {
            changeViewState(lng, lat, 500, 17.5, 50)
        } else if (area >= 7872.420654785371 && area < 144320.60350029863) {
            changeViewState(lng, lat, 500, 16.5, 50)
        } else {
            changeViewState(lng, lat, 500, 15, 50)
        }
    }
    if (document.getElementById('drawPolygonButton').classList[1] === "ativo") {
        $("#drawPolygonButton").click();
    }
}

let historicoAntigo = localStorage.getItem("historico")
if (historicoAntigo !== null) {
    let vetorEpochs = JSON.parse(historicoAntigo).sort().reverse()
    for (let i = 0; i < vetorEpochs.length; i++) {
        if (vetorEpochs[i] !== null) {
            const getDatahora = vetorEpochs[i].datahora
            const datahora = new Date(getDatahora * 1000)
            const hoje = new Date();
            const timeExceeded = new Date(hoje.getTime() - 1592800000)
            const mydate = new Date(datahora);

            if (timeExceeded * 1000 > mydate * 1000) {
                delete vetorEpochs[i]
            }
        }
    }
    vetorEpochs = vetorEpochs.filter(function (x) { return x !== null });
    localStorage.setItem("historico", JSON.stringify(vetorEpochs))
}

function salvaHistorico(tipologia, lote) {
    try {
        let historicoAntigo = localStorage.getItem("historico")
        let historico = []
        if (historicoAntigo !== null) {
            historico = JSON.parse(historicoAntigo)
        }
        setAfterClash(true)
        const timestamp = Math.round(new Date().getTime() / 1000)
        const obj = {
            "datahora": timestamp,
            "lote": lote,
            "tipologia": tipologia
        }
        historico.push(obj)
        localStorage.setItem("historico", JSON.stringify(historico))

        setTimeout(() => {
            searchSide.$children[0].setHistorico(historico)
            searchSide.$children[0].filter()
            setTimeout(() => {
                searchSide.$children[0].epochtoday()
                searchSide.$children[0].epochyesterday()
                searchSide.$children[0].epochlast()
            }, 10);
        }, 1500);

    } catch (error) {
        console.error("Erro na funcao salvar Historico")
        console.error(error)
    }
}