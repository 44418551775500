import "./Loading.css"

import React from "react";

export function Loading(x) {
    const load = document.getElementById("screenLoading");
    if (x === "iniciar") {
        load.classList.add('show')
    } else { load.classList.remove('show') }
}

export function LoadingScreen() {
    return (
        <>
            <div id="screenLoading" className="background">
                <div className="loading">
                    <div className="obj"></div>
                    <div className="obj"></div>
                    <div className="obj"></div>
                    <div className="obj"></div>
                    <div className="obj"></div>
                    <div className="obj"></div>
                    <div className="obj"></div>
                    <div className="obj"></div>
                </div>
            </div>
            <div id="MouseBlock"></div>
        </>
    )
}