import React from "react";
import "./Report.css";
import axios from 'axios';
import { cleanSidebarIcons, fecharSidebar } from "../Sidebar";
import { useState } from "react";
import { baseUrlApi } from "../../../DataHandler/DataHandler";

export function Report() {
    const [file, setFile] = useState()
    const [user, setUser] = useState()
    const [content, setContent] = useState()
    const [emailSended, setEmailSended] = useState("aguardando resposta...")

    function closeEmailForm(e) {
        let emailCenter = document.querySelector(".emailFormCenter")
        let emailBody = document.querySelector(".emailFormBody")
        let emailForm = document.querySelector(".formEmail")
        if (e.target.id == "closeId") {
            emailCenter.classList.remove('opacity')
            emailBody.classList.remove('active')
            emailForm.classList.remove('active')
            setTimeout(() => {
                emailCenter.classList.remove('active')
            }, 800);
            cleanSidebarIcons()
            fecharSidebar()
        }
    }

    function userChange(event) {
        setUser(event.target.value)
    }

    function contentChange(event) {
        setContent(event.target.value)
    }

    function fileChange(event) {
        setFile(event.target.files[0])
    }

    function handleSubmit(event) {
        let emailSended = document.querySelector(".emailSended")
        let Sended = document.querySelector(".Sended")
        function ProblemsDetails(frase) {
            emailSended.classList.add('active')
            Sended.classList.add('active')

            setEmailSended(frase)

            setTimeout(() => {
                emailSended.classList.add('opacity')
                setTimeout(() => {
                    emailSended.classList.remove('active')
                    Sended.classList.remove('active')
                    emailSended.classList.remove('opacity')
                }, 800);
            }, 2500);
        }
        event.preventDefault()
        const url = `${baseUrlApi()}sendEmail`;
        const formData = new FormData();
        let auxUser = false
        let auxContent = false

        if (user !== undefined && user !== "") {
            formData.append('user', user);
        } else if (user === undefined || user === "") {
            auxUser = true
        }

        if (content !== undefined && content !== "") {
            formData.append('content', content);
        } else if (content === undefined || content === "") {
            auxContent = true
        }

        if (file !== undefined) {
            formData.append('file', file);
            formData.append('fileName', file.name);
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        if (auxUser === true && auxContent === true) {
            ProblemsDetails("Insira seu E-mail e os Detalhes do feedback")
        } else if (auxUser === true) {
            ProblemsDetails("Insira seu E-mail")
        } else if (auxContent === true) {
            ProblemsDetails("Insira os Detalhes do feedback")
        } else if (user !== undefined && user !== "" && content !== undefined && content !== "") {
            axios.post(url, formData, config).then((response) => {
                let emailCenter = document.querySelector(".emailFormCenter")
                let emailBody = document.querySelector(".emailFormBody")
                let emailForm = document.querySelector(".formEmail")
                emailCenter.classList.remove('opacity')
                emailBody.classList.remove('active')
                emailForm.classList.remove('active')
                setTimeout(() => {
                    emailCenter.classList.remove('active')
                }, 800);
                cleanSidebarIcons()
                fecharSidebar()

                let mensagem
                if (response.data === true) {
                    mensagem = "E-mail enviado"
                } else if (response.data === false) {
                    mensagem = "Insira uma imagem válida ou retire o arquivo selecionado"
                }
                ProblemsDetails(mensagem)
            });
        }
        // let emailSended = document.querySelector(".emailSended")
        // let Sended = document.querySelector(".Sended")
        // emailSended.classList.add('active')
        // Sended.classList.add('active')

        // setEmailSended("E-mail enviado")

        // setTimeout(() => {
        //     emailSended.classList.remove('active')
        //     Sended.classList.remove('active')
        // }, 5000);
    }

    return (
        <>
            <div id="closeId" onClick={closeEmailForm} className="emailFormCenter">
                <div className="emailFormBody">
                    <form id="myForm" onSubmit={handleSubmit} className="formEmail" method="POST" encType="multipart/form-data">
                        <label className="formLabelEmail">Quem está efetuando o Reporte:</label>
                        <input className="formInputEmail" type="email" name="email" onChange={userChange}></input>
                        <label className="formLabelEmail topmargin">Detalhes do Problema:</label>
                        <textarea className="formInputEmail" onChange={contentChange}
                            style={{ maxHeight: "100px", minHeight: "100px" }} name="message"></textarea>
                        <label className="formLabelEmail topmargin">Anexo:</label>
                        <input type="file" name="attachment" onChange={fileChange}></input>
                        <button type="submit" className="filterButton btn btn-primary"
                            style={{ bottom: "5%", fontSize: "15px" }}>
                            Enviar Reporte
                        </button>
                    </form>
                </div>
            </div>
            <div id="" className="emailSended">
                <div className="Sended" style={{ textAlign: "center" }}>
                    {emailSended}
                </div>
            </div>
        </>
    )
} 