import "./AlertsIframe.css"
import Vue from "vue/dist/vue";
import $ from "jquery"
import { cleanSidebarIcons, fecharSidebar } from "../Sidebar/Sidebar";

export var alertascustom = new Vue({
    el: "#alertascustom_id",
    components: {
        'alertascustom': {
            template: `  
            <div id="alertascustom_id">
                <div id="closeDeckalertsFundo">
                    <div id="closeDeckAlertsModals" >
                        <div class="col-md-11 container" >
                            <label style="color: rgb(0, 0, 0);">É necessário sair do Clash caso deseje realizar esta função.</label>
                        </div>  
                        <div class="closeDeckAlertsCentralizar">
                            <span id="continuarDeckAlert" style="padding-right: 25px;">Continuar</span>
                            <span class="buttonDeck" style="padding-right: 0px;">Sair</span>
                        </div>
                    </div>
                </div>
                
                <div id="alertasFundo">
                    <div id="alertasModals" >
                        <div class="col-md-11 container" >
                            <label id="alertasTexto" style="color: rgb(0, 0, 0);"></label>
                        </div>  
                        <div class="alertasCentralizar">
                            <span id="alertasFechar" style="padding-right: 25px;">OK</span>
                        </div>
                    </div>
                </div>
            </div>        
            `,
        }
    },
    methods: {
        alerta1: function () {
            alertascustom.alertasDisplays("", "", "36%", "", "", "35px 35px 35px", "Aqui você coloca o texto que desejar para cada situação")
        },
        alerta2: function () {
            alertascustom.alertasDisplays("", "", "36%", "", "", "35px 35px 35px", "Modifique como quiser")
        },
        alerta3: function () {
            alertascustom.alertasDisplays("", "", "36%", "90px", "", "35px 35px 35px", "Modal totalmente customizável")
        },
        alerta4: function () {
            alertascustom.alertasDisplays("20px", "", "36%", "", "", "35px 35px 35px", "Escreva o que precisar")
        },
        alertasDisplays: function (posRight, posBottom, peso, posLeft, posTop, ponta, mensagem) {
            document.getElementById("alertasModals").style.right = posRight;
            document.getElementById("alertasModals").style.bottom = posBottom;
            document.getElementById("alertasModals").style.width = peso;
            document.getElementById("alertasModals").style.left = posLeft;
            document.getElementById("alertasModals").style.top = posTop;
            document.getElementById("alertasModals").style.borderRadius = ponta;
            document.getElementById("alertasTexto").innerHTML = mensagem;
            document.getElementById("alertasFundo").style.display = "flex";
            // document.getElementById("alertasTitulo").innerHTML = titulo;
        },
        displayMessage: function (largura, mensagem) {
            document.getElementById("alertasModals").style.width = largura;
            // document.getElementById("alertasModals").style.left = "30%";
            // document.getElementById("alertasModals").style.top = "45%"; 
            document.getElementById("alertasModals").style.borderRadius = "10px";
            document.getElementById("alertasTexto").innerHTML = mensagem;
            document.getElementById("alertasFundo").style.display = "flex";
            // document.getElementById("alertasTitulo").innerHTML = titulo;
        }
    }
})
$("#alertasFechar").click(function () {
    document.getElementById("stopDrawButton").style.display = "none"
    document.getElementById("alertasFundo").style.display = "none";
    cleanSidebarIcons()
});

$("#continuarDeckAlert").click(function () {
    document.getElementById("closeDeckalertsFundo").style.display = "none";
    cleanSidebarIcons()
});

$("#fundoIframeBim").click(function () {
    document.getElementById("fundoIframeBim").style.display = "none";
});

export function openIframeBim() {
    document.getElementById("fundoIframeBim").style.display = "flex";
    fecharSidebar()
}
