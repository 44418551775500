/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { getFixedPopup, setFixedPopup } from '../../../../DataHandler/DataHandler';
import './CSS/Deck.css';
import { changeSelectOrRotate } from './DeckComponent';

export function ChangePopup(props) {
    // const params = (new URL(document.location)).searchParams;
    // const session = params.get('session');
    var html = [];
    if (getFixedPopup() === true) {
        html.push(
            <div key={"closePopup"} className={props.validate ? "closeButton active closePopup" : "closeButton closePopup"}>x</div>
        )
    }
    if (props.info.foraDeArea) {
        html.push(
            <a className="closePopup" key={1} style={{ fontWeight: "300", marginLeft: "5px" }}>
                Área selecionada não possui lotes
            </a>
        )
    } else if (props.info.object.properties.link_sdig !== undefined) {
        for (let key in props.info.object.properties) {
            if (key === "link_sdig") {
                // html.push(<h6 key={key} style={{ fontSize: "13px", margin: "0", padding: "5", paddingTop: "10px" }}>DADOS COMPLEMENTARES: <div style={{ fontWeight: "300", marginLeft: "15px", wordBreak: "break-all" }}> <a target="_blank" href={props.info.object.properties[key] + session} style={{ textDecoration: "none" }}>Clique Aqui!</a></div></h6>)
            } else if (!(key === "visibilityTipo" || key === "visibilityStatus" || key === "layerActivated"
                || key === "latitude" || key === "longitude" || key === "visibility"
                || key === "id" || key === "trecho_emp_id")) {
                if (props.info.object.properties[key] === null) {
                    html.push(<h6 key={key} style={{ fontSize: "13px", margin: "0", padding: "5", paddingTop: "10px" }}>{key.toUpperCase()}: <div style={{ fontWeight: "300", marginLeft: "15px" }}> Não consta dado cadastrado</div></h6>)
                } else {
                    html.push(<h6 key={key} style={{ fontSize: "13px", margin: "0", padding: "5", paddingTop: "10px" }}>{key.toUpperCase()}: <div style={{ fontWeight: "300", marginLeft: "15px" }}> {props.info.object.properties[key]}</div></h6>)
                }
            }
        }
    } else if (props.info.object.properties.setor) {
        html.push(<h6 key={"setor"}
            className="closePopup"
            style={{
                fontSize: "13px", margin: "0",
                padding: "5", paddingTop: "10px",
            }}>Setor:
            <a className="closePopup" style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}>
                {props.info.object.properties.setor}</a>
        </h6>)
        html.push(<h6 key={"quadra"}
            className="closePopup"
            style={{
                fontSize: "13px", margin: "0",
                padding: "5", paddingTop: "10px",
            }}>Quadra:
            <a className="closePopup" style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}>
                {props.info.object.properties.quadra}</a>
        </h6>)
        html.push(<h6 key={"lote"}
            className="closePopup"
            style={{
                fontSize: "13px", margin: "0",
                padding: "5", paddingTop: "10px",
            }}>Lote:
            <a className="closePopup" style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}>
                {props.info.object.properties.lote}</a>
        </h6>)
        // html.push(<h6 key={"porcentagem"}
        //     className="closePopup"
        //     style={{
        //         fontSize: "13px", margin: "0",
        //         padding: "5", paddingTop: "10px",
        //     }}>Porcentagem:
        //     <a className="closePopup" style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}>
        //         {props.info.object.properties.porcentagem}</a>
        // </h6>)
        // html.push(<h6 key={"area_interseccao"}
        //     className="closePopup"
        //     style={{
        //         fontSize: "13px", margin: "0",
        //         padding: "5", paddingTop: "10px",
        //     }}>Área Intersecção:
        //     <a className="closePopup" style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}>
        //         {props.info.object.properties.area_interseccao}</a>
        // </h6>)
        // html.push(<h6 key={"area_total_lote"}
        //     className="closePopup"
        //     style={{
        //         fontSize: "13px", margin: "0",
        //         padding: "5", paddingTop: "10px",
        //     }}>Área total lote:
        //     <a className="closePopup" style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}>
        //         {props.info.object.properties.area_total_lote}</a>
        // </h6>)
        // html.push(<h6 key={"area_total_poligono"}
        //     className="closePopup"
        //     style={{
        //         fontSize: "13px", margin: "0",
        //         padding: "5", paddingTop: "10px",
        //     }}>Área total polígono:
        //     <a className="closePopup" style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}>
        //         {props.info.object.properties.area_total_poligono}</a>
        // </h6>)
    } else {
        if (props.validate) {
            html.push(
                <a className="closePopup" key={1} style={{ fontWeight: "300", marginLeft: "5px" }}>
                    {props.info.object.properties.nome}</a>
            )
        } else {
            html.push(<h6 key={"a"}
                className="closePopup"
                style={{
                    fontSize: "13px", margin: "0",
                    padding: "5", paddingTop: "10px",
                }}>Titulo:
                <a className="closePopup" style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}>
                    {props.info.object.properties.titulo}</a>
            </h6>)
            if (props.info.object.properties.atributo !== undefined) {
                const vetor = props.info.object.properties.atributo.split(',')
                const vetor_formatado = props.info.object.properties.atributo_formatado.split(',')

                for (var i = 0; i < vetor.length; i++) {
                    if (props.info.object.properties[vetor[i]] === undefined) {
                        props.info.object.properties[vetor[i]] = "Não consta"
                    }
                    if (vetor_formatado[i] === "Link") {
                        html.push(
                            <h6 key={i}
                                className="closePopup"
                                style={{
                                    fontSize: "13px", margin: "0",
                                    padding: "5", paddingTop: "10px",
                                }}>{vetor_formatado[i]}:
                                <a href={props.info.object.properties[vetor[i]]}
                                    className="closePopup"
                                    target="_blank" rel="noopener noreferrer"
                                    style={{ fontWeight: "300", marginLeft: "5px", color: "blue", userSelect: "all" }}>
                                    Clique para acessar
                                    {/* {props.info.object.properties[vetor[i]]} */}
                                </a>
                            </h6>
                        )
                    } else {
                        html.push(
                            <h6 key={i}
                                className="closePopup"
                                style={{
                                    fontSize: "13px", margin: "0",
                                    padding: "5", paddingTop: "10px",
                                }}>{vetor_formatado[i]}:
                                <a className="closePopup" style={{ fontWeight: "300", marginLeft: "5px", userSelect: "all" }}>
                                    {props.info.object.properties[vetor[i]]}</a>
                            </h6>
                        )
                    }
                }
            }
        }
    }

    document.addEventListener('click', function (e) {
        if (e.target.className.includes("closePopup")) {
            changeSelectOrRotate(null)
            setFixedPopup(false)
        }
    })

    return (
        <>
            <div className={props.validate ? "popupContent active closePopup" : "popupContent closePopup"}>{html}</div>
        </>
    )
}