import React from "react";
import axios from 'axios';
import { useState } from "react";
import * as turf from "@turf/turf"
import { baseUrlApi, getCamadasDeRisco, getClash, getClashFilter, getClashGeojson, getTipologyValues, getDownloadFinalClash, getImage, getisNebula, getListaLotes, getLotes, getMultiClash, setImage } from "../../../DataHandler/DataHandler";
import { Loading } from "../../Loading/LoadingScreen";

var Selectedcheckboxs = []
var checkboxs = []

function guardarIndice(indice) {
    let adicionarIndice = true
    for (let i = 0; i < Selectedcheckboxs.length; i++) {
        if (Selectedcheckboxs[i] === indice) {
            Selectedcheckboxs.splice(i, 1);
            adicionarIndice = false
        }
    }
    if (adicionarIndice) {
        Selectedcheckboxs.push(indice)
    }
}

export function LegendScreenshot() {
    // eslint-disable-next-line no-undef
    html2canvas(document.querySelector('#root'), {
        useCORS: true,
        allowTaint: true,
    }).then(canvas => {
        document.getElementById('zoomPanel').style.opacity = 1
        document.getElementById('setTilesBody').style.opacity = 1
        const img = canvas.toDataURL("image/png");
        setImage(img)
        // downloadURI(img, "screenshot.png")
    });
}

// function downloadURI(uri, name) {
//     let link = document.createElement("a");
//     link.download = name;
//     link.href = uri;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// }
// $ Screenshot Legenda 

export function addCheckboxOption() {
    checkboxs = []
    for (let i = 0; i < getDownloadFinalClash().length; i++) {
        checkboxs.push(
            <div key={i} className="labelFormat">
                <input onClick={() => guardarIndice(i)} className="checkboxxFormat" type="checkbox" />
                <a>Clash {i + 1}</a>
            </div>
        )
        Selectedcheckboxs = []
    }
}
export function DownloadClash() {
    const [DownloadActivated, setDownloadActivated] = useState(true)

    async function DownloadTXT() {
        if (DownloadActivated === true) {
            if (getMultiClash() === true) {
                document.getElementById("DownloadCheckbox").classList.add("active")
            } else {
                Loading("iniciar")
                const center = turf.center(getLotes())
                const rua = await axios.get(encodeURI(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.geometry.coordinates[1]},${center.geometry.coordinates[0]}&sensor=true&key=AIzaSyBJfQDkEJxuF9Up4tITcEtPhPd4Ma6cRtE`))
                let conforme = [];
                let naoConforme = [];
                let conformeObservacao = [];
                let lista_lotes = [];
                lista_lotes.push(getListaLotes())
                for (let i = 0; i < getClash().length; i++) {
                    if (getClash()[i].output.includes("Não conforme")) {
                        naoConforme.push(getClash()[i])
                    } else if (getClash()[i].output.includes("Conforme -")) {
                        conforme.push(getClash()[i])
                    } else {
                        conformeObservacao.push(getClash()[i])
                    }
                }
                if (lista_lotes[0][0] !== undefined) {
                    lista_lotes = lista_lotes[0]
                }

                const pdfContent = {
                    "json": {
                        "conforme": conforme,
                        "nao_conforme": naoConforme,
                        "conforme_observacao": conformeObservacao
                    },
                    "metadados": {
                        "desenhado": getisNebula(),
                        "lote": getisNebula() ? getLotes().geometry.coordinates[0] : null,
                        "lista_lotes": lista_lotes,
                        "base64": getImage()
                    },
                    "endereco": {
                        "rua": rua.data.results[0].address_components[1].long_name,
                        "numero": rua.data.results[0].address_components[0].long_name,
                        "bairro": rua.data.results[0].address_components[2].long_name,
                        "cep": rua.data.results[0].address_components[6].long_name,
                        "cidade": rua.data.results[0].address_components[3].long_name
                    },
                    "geojson": getCamadasDeRisco(),
                    "clash": getClashGeojson(),
                    "tipologias": getTipologyValues()[0][1]
                }
                console.warn("tipologias",getTipologyValues()[0][1])
                console.log('pdfContent', pdfContent)
                const filename = await axios.post("https://api-bim.geovista.com.br/criarRelatorio", pdfContent)
                window.open("https://api-bim.geovista.com.br/clash.pdf?file=" + filename.data)

                // await axios.post("https://api.bim.geovista.com.br/getConformes", pdfContent).then((response) => {
                //     pdfContent.json.conforme = response.data
                // });
                Loading("fechar")
            }
        }
        setDownloadActivated(true)
    }

    const closeDownloadCheckbox = () => {
        document.getElementById("DownloadCheckbox").classList.remove("active")
    }

    async function confirmDownloadCheckbox() {
        document.getElementById("DownloadCheckbox").classList.remove("active")
        // let sendParameter = "";
        for (let i = 0; i < Selectedcheckboxs.length; i++) {
            const center = turf.center(getClashFilter()[Selectedcheckboxs[i]][1][5].features[0])
            // const rua = await axios.get(encodeURI(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.geometry.coordinates[1]},${center.geometry.coordinates[0]}&sensor=true&key=AIzaSyBJfQDkEJxuF9Up4tITcEtPhPd4Ma6cRtE`))
            // console.log("rua", rua.data.results[0].formatted_address)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            const formData = new FormData();
            formData.append('data', JSON.stringify(getDownloadFinalClash()[Selectedcheckboxs[i]][1]));
            formData.append('center', JSON.stringify(center.geometry.coordinates));
            const url = `${baseUrlApi()}clashs.txt`
            axios.post(url, formData, config).then((response) => {
                const counter = i + 1
                const fileContent = "Clash nº" + counter + "\n \n" + response.data
                const fileName = "Clash_" + counter + ".txt"
                download(fileName, fileContent)
            });
        }
    }

    function download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        // fetch(text).then(function (t) {
        //     return t.blob().then((b) => {
        //         var a = document.createElement("a");
        //         a.href = URL.createObjectURL(b);
        //         a.setAttribute("download", filename);
        //         a.click();
        //     }
        //     );
        // });
    }

    return (
        <>
            <label id="positionDownload" className="positionDownload" htmlFor="ms-download">
                <div className="ms-content">
                    <div className="ms-content-inside">
                        <input type="checkbox" id="ms-download" onClick={() => DownloadTXT()} />
                        <div className="ms-line-down-container">
                            <div className="ms-line-down"></div>
                        </div>
                        <div className="ms-line-point"></div>
                    </div>
                </div>
                <div onClick={() => setDownloadActivated(false)} id="DownloadCheckbox" className="DownloadCheckbox">
                    <div onClick={closeDownloadCheckbox} className="closeCheckboxes">
                        <i className="far fa-times"></i>
                    </div>
                    {checkboxs}
                    <div onClick={() => confirmDownloadCheckbox()} className="confirmCheckboxes">
                        Confirmar
                    </div>
                </div>
            </label>
        </>
    )
}
