import React from "react";
import { baseUrlApi } from "../../../DataHandler/DataHandler";
import { cleanSidebarIcons } from "../Sidebar";
import "./Planilha.css"

export function Planilha() {
    function closePlanilha(e) {
        let fundoPlanilha = document.querySelector(".fundoPlanilha")
        let planilhaBody = document.querySelector(".planilhaBody")
        if (e.target.id == "closePlanilha") {
            cleanSidebarIcons()
            fundoPlanilha.classList.remove('opacity')
            planilhaBody.classList.remove('active')
            setTimeout(() => {
                fundoPlanilha.classList.remove('active')
            }, 800);
        }
    }

    return (
        <div id="closePlanilha" onClick={closePlanilha} className="fundoPlanilha">
            <div className="planilhaBody">
                <iframe src={`${baseUrlApi()}Metadados.html`} frameBorder="0" style={{height: "100%", width: "100%"}}></iframe>
            </div>
        </div>
    )
} 