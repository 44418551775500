import React from 'react'
// import {
//     AmbientLight,
//     AxesHelper,
//     DirectionalLight,
//     GridHelper,
//     PerspectiveCamera,
//     Scene,
//     WebGLRenderer,
// } from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import { IFCLoader } from "web-ifc-three/IFCLoader";
import "./IFC.css"

export default function IFC() {
    // //Sets up the renderer, fetching the canvas of the HTML
    // const threeCanvas = document.getElementById("three-canvas");
    // if (threeCanvas !== null) {
    //     //Creates the Three.js scene
    //     const scene = new Scene();

    //     //Object to store the size of the viewport
    //     const size = {
    //         width: window.innerWidth,
    //         height: window.innerHeight,
    //     };

    //     //Creates the camera (point of view of the user)
    //     const camera = new PerspectiveCamera(75, size.width / size.height);
    //     camera.position.z = 15;
    //     camera.position.y = 13;
    //     camera.position.x = 8;

    //     //Creates the lights of the scene
    //     const lightColor = 0xffffff;

    //     const ambientLight = new AmbientLight(lightColor, 0.5);
    //     scene.add(ambientLight);

    //     const directionalLight = new DirectionalLight(lightColor, 1);
    //     directionalLight.position.set(0, 10, 0);
    //     directionalLight.target.position.set(-5, 0, 0);
    //     scene.add(directionalLight);
    //     scene.add(directionalLight.target);

    //     //Sets up the renderer, fetching the canvas of the HTML
    //     const renderer = new WebGLRenderer({ canvas: threeCanvas, alpha: true });
    //     renderer.setSize(size.width, size.height);
    //     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    //     //Creates grids and axes in the scene
    //     const grid = new GridHelper(50, 30);
    //     scene.add(grid);

    //     const axes = new AxesHelper();
    //     axes.material.depthTest = false;
    //     axes.renderOrder = 1;
    //     scene.add(axes);

    //     //Creates the orbit controls (to navigate the scene)
    //     const controls = new OrbitControls(camera, threeCanvas);
    //     controls.enableDamping = true;
    //     controls.target.set(-2, 0, 0);

    //     //Animation loop
    //     const animate = () => {
    //         controls.update();
    //         renderer.render(scene, camera);
    //         requestAnimationFrame(animate);
    //     };

    //     animate();

    //     //Adjust the viewport to the size of the browser
    //     window.addEventListener("resize", () => {
    //         (size.width = window.innerWidth), (size.height = window.innerHeight);
    //         camera.aspect = size.width / size.height;
    //         camera.updateProjectionMatrix();
    //         renderer.setSize(size.width, size.height);
    //     });

    //     //Sets up the IFC loading
    //     // const ifcLoader = new IFCLoader();
    //     // ifcLoader.ifcManager.setWasmPath("../../../");

    //     // const input = document.getElementById("file-input");
    //     // input.addEventListener(
    //     //     "change",
    //     //     (changed) => {
    //     //         const ifcURL = URL.createObjectURL(changed.target.files[0]);
    //     //         ifcLoader.load(ifcURL, (ifcModel) => scene.add(ifcModel));
    //     //     },
    //     //     false
    //     // );
    // }

    const fecharIFC = (e) => {
        if (e.target.id === "fundoIframeBim") {
            document.getElementById("fundoIframeBim").style.display = "none"
        }
    }

    return (
        <div id="fundoIframeBim" onClick={(e) => fecharIFC(e)}>
            <div id="modalIframeBim" className="iframeBim">
                {/* <iframe id='modalIframeBim' src="https://ifcjs.github.io/hello-world/examples/web-ifc-three/multithreading/index.html" frameBorder="0"></iframe> */}
                <iframe id='modalIframeBim' src="https://ifc.bim.geovista.com.br/" frameBorder="0"></iframe>
                {/* <input type="file" id="file-input" accept=".ifc, .ifcXML, .ifcZIP"></input>
                <canvas id="three-canvas"></canvas> */}
            </div>
        </div>
    )
}

