import Vue from "vue/dist/vue";
import $ from "jquery"
import "./Sidebar.css"
import { Loading } from "../Loading/LoadingScreen";
import { changeLayers, changeLotes, changeViewState, cleanAllLayers, closeClashAux, createBuildings, distritoActivated } from "../Deck/Deck Components/Organizar Layers/DeckPrepare";
import { linkCamadaDeRisco, prepareLotes } from "../Deck/Deck Functions/Requests";
import * as bootstrap from 'bootstrap'
import { closeClash } from "../../Clash/ButtonCloseDeck";
import { baseUrlApi, cleanClashs, getDistritoAberto, getMultiClash, setAfterClash, setBufferLote, setClashValidator, SetDistritoAberto, setLotes, SetMultiClash, SetMultiLote, setTipologia } from "../../DataHandler/DataHandler";
import { closeCloseUpload, closeUpload } from "./UploadFile/UploadFile";
import { changeSelectOrRotate } from "../Deck/Deck Components/Visual Settings/DeckComponent";
import { displayFirstWindow } from "./Tutorial/Tutorial";

var firstClash = true;
let toggleDrawPolygon = false

// Terceiro

export var searchSide = new Vue({
    el: "#searchside_id",
    components: {
        'searchside': {
            template: `  
            <div id="searchSide">
            <div class="sidebarGeovista">
            <div className="popupInfo" id="popupInfo"
                style="
                    position: absolute;
                    opacity: 0;
                    max-width: 800px;
                    max-height: 200px;
                    background: #fff;
                    white-space: nowrap;
                    border: solid 1px lightgrey;
                    border-radius: 2px;
                    padding: 3px;
                    font-size: 14px;
                    z-index: 20000;
                    pointer-events: none;
                    transition: .3s;
                "
            >
                {{title}} 
            </div>
                    <div class="sidebarConteudo">
                    <br>
                    <div id="showSide">
                        <nav class="navbar navbar-dark bg-default">
                            <h5 style="margin-left: -15px;">Não Conforme</h5>
                            <div>
                                <h6 v-if="contar1 === 0" style="margin-top: -12px;" class="contador">{{contar1}}</h6>
                                <h6 v-else class="contador">{{contar1}}</h6>
                                <span v-if="contar1 !== 0" style="border: none;" class="navbar-toggler" type="button" data-toggle="collapse" v-bind:data-target="'#navinfo'+datas[0].indexOf()" 
                                    aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span><img src="https://cdn.iconscout.com/icon/free/png-256/chevron-down-1767562-1502466.png"  class="iconeClash"  /></span>
                                </span>
                            </div>
                        </nav>
                        <div class="pos-f-t">
                            <div class="collapse" v-bind:id="'navinfo'+datas.indexOf()"  >
                                <div class="bg-default p-4">
                                    <div v-for="data in datas[0]">
                                        <nav class="navbar navbar-dark bg-default">
                                            <p style="font-size: 14px; margin-left: -25px; margin-right: -25px;">{{data.titulo}}</p>
                                            <div>
                                                <span style="margin-right: -25px; border: none;" class="navbar-toggler" type="button" data-toggle="collapse" v-bind:data-target="'#navinfo-1'+datas[0].indexOf(data)" 
                                                    aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span><img src="https://cdn.iconscout.com/icon/free/png-256/chevron-down-1767562-1502466.png" class="iconeClash" /></span>
                                                </span>
                                            </div>
                                        </nav>
                                        <div class="pos-f-t">
                                            <div class="collapse" v-bind:id="'navinfo-1'+datas[0].indexOf(data)"  >
                                                <div class="bg-default p-4" >
                                                    <h6 style="font-size: 12px;">{{data.output}}</h6>
                                                    <h6 style="font-size: 12px;">Descrição: </h6>
                                                    <p class="changeTextUrl" style="font-size: 12px;">{{data.metadado}}</p>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <nav class="navbar navbar-dark bg-default">
                            <h5 style="margin-left: -15px;">Conforme</h5>
                            <div>
                                <h6 v-if="contar2 === 0" class="contador">{{contar2}}</h6>
                                <h6 v-else class="contador">{{contar2}}</h6>
                                <span v-if="contar2 !== 0" style="border: none;" class="navbar-toggler" type="button" data-toggle="collapse" v-bind:data-target="'#navinfo1'+datas[1].indexOf()" 
                                    aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span><img src="https://cdn.iconscout.com/icon/free/png-256/chevron-down-1767562-1502466.png"  class="iconeClash"  /></span>
                                </span>
                            </div>
                        </nav>
                        <div class="pos-f-t">
                            <div class="collapse" v-bind:id="'navinfo1'+datas[1].indexOf()"  >
                                <div class="bg-default p-4">
                                    <div v-for="data in datas[1]">
                                        <nav class="navbar navbar-dark bg-default">
                                            <p style="font-size: 14px; margin-left: -25px; margin-right: -25px;">{{data.titulo}}</p>
                                            <div>
                                                <span style="margin-right: -25px; border: none;" class="navbar-toggler" type="button" data-toggle="collapse" v-bind:data-target="'#navinfo1-1'+datas[1].indexOf(data)" 
                                                                                            aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span><img src="https://cdn.iconscout.com/icon/free/png-256/chevron-down-1767562-1502466.png" class="iconeClash" /></span>
                                                </span>
                                            </div>
                                        </nav>
                                        <div class="pos-f-t">
                                            <div class="collapse" v-bind:id="'navinfo1-1'+datas[1].indexOf(data)"  >
                                                <div class="bg-default p-4">
                                                    <h6 style="font-size: 12px;">{{data.output}}</h6>
                                                    <h6 style="font-size: 12px;">Descrição: </h6>
                                                    <p class="changeTextUrl" style="font-size: 12px;">{{data.metadado}}</p>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <nav class="navbar navbar-dark bg-default">
                            <h5 style="margin-left: -15px;">Conforme com Observação</h5>
                            <div>
                                <h6 v-if="contar3 === 0" class="contador">{{contar3}}</h6>
                                <h6 v-else class="contador">{{contar3}}</h6>
                                <span v-if="contar3 !== 0" style="border: none;" class="navbar-toggler" type="button" data-toggle="collapse" v-bind:data-target="'#navinfo2'+datas[2].indexOf()" 
                                    aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span><img src="https://cdn.iconscout.com/icon/free/png-256/chevron-down-1767562-1502466.png"  class="iconeClash"  /></span>
                                </span>
                            </div>
                        </nav>
                        <div class="pos-f-t">
                            <div class="collapse" v-bind:id="'navinfo2'+datas[2].indexOf()"  >
                                <div class="bg-default p-4">
                                    <div v-for="data in datas[2]">
                                        <nav class="navbar navbar-dark bg-default">
                                            <p style="font-size: 14px; margin-left: -25px; margin-right: -25px;">{{data.titulo}}</p>
                                            <div >
                                                <span style="margin-right: -25px; border: none;" class="navbar-toggler" type="button" data-toggle="collapse" v-bind:data-target="'#navinfo2-1'+datas[2].indexOf(data)" 
                                                                                            aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span><img src="https://cdn.iconscout.com/icon/free/png-256/chevron-down-1767562-1502466.png" class="iconeClash" /></span>
                                                </span>
                                            </div>
                                        </nav>
                                        <div class="pos-f-t">
                                            <div class="collapse" v-bind:id="'navinfo2-1'+datas[2].indexOf(data)"  >
                                                <div class="bg-default p-4">
                                                    <h6 style="font-size: 12px;">{{data.output}}</h6>
                                                    <h6 style="font-size: 12px;">Descrição: </h6>
                                                    <p class="changeTextUrl" style="font-size: 12px;">{{data.metadado}}</p>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                
                            </div>
                        </div>
                        <hr>
                        </div>
                        <div id="displayControl">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="list-group" id="list-tab" role="tablist">
                                            <a class="list-group-item list-group-item-action active text-center" id="list-home-list" data-toggle="list" href="#list-home" role="tab" aria-controls="home">Hoje</a>
                                            <a class="list-group-item list-group-item-action text-center" id="list-profile-list" data-toggle="list" href="#list-profile" role="tab" aria-controls="profile">Ontem</a>
                                            <a class="list-group-item list-group-item-action text-center" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages">Últimos 7 dias</a>
                                        </div>
                                    </div>
                                    <div class="col-12"  style="margin-top: 10px;">
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                                                <div v-for="data in datesToday" class="moldeHistorico">
                                                    <ul class="list-group links">
                                                        <a id="getDate1" v-on:click="historyClash(data.lote, data.tipologia)" class="dataLinks">{{data.datahora}}</a>
                                                        <p id="copyLink" style="display: none;"> ${baseUrlApi()}?lote={{JSON.stringify(data.lote)}}&tipologia={{data.tipologia.join(',')}}</p>
                                                        <span onclick="copyToClipboard('#copyLink')" class="clipboardClick">
                                                            <p class="clipboardHistory">
                                                                <i class="fa fa-clipboard"></i>
                                                                <span class="modalCLipboard">
                                                                    Clique para <br>copiar o link     								            
                                                                </span>
                                                            </p> 
                                                        </span>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
                                                <div v-for="data in datesYesterday" class="moldeHistorico">
                                                    <ul class="list-group links" >
                                                        <a id="getDate2" v-on:click="historyClash(data.lote, data.tipologia)" class="dataLinks">{{data.datahora}}</a>
                                                        <p id="copyLink" style="display: none;"> ${baseUrlApi()}?lote={{JSON.stringify(data.lote)}}&tipologia={{data.tipologia.join(',')}}</p>
                                                        <span onclick="copyToClipboard('#copyLink')" class="clipboardClick">
                                                            <p class="clipboardHistory">
                                                                <i class="fa fa-clipboard"></i>
                                                                <span class="modalCLipboard">
                                                                    Clique para <br>copiar o link     								            
                                                                </span>
                                                            </p> 
                                                        </span>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
                                                <div v-for="data in datesLast" class="moldeHistorico">
                                                    <ul class="list-group links" >
                                                        <a id="getDate3" v-on:click="historyClash(data.lote, data.tipologia)" class="dataLinks" style="font-size: 15px; margin-top: -15px; cursor: pointer;">{{data.datahora}}</a>
                                                        <p id="copyLink" style="display: none;"> ${baseUrlApi()}?lote={{JSON.stringify(data.lote)}}&tipologia={{data.tipologia.join(',')}}</p>
                                                        <span onclick="copyToClipboard('#copyLink')" class="clipboardClick">
                                                            <p class="clipboardHistory">
                                                                <i class="fa fa-clipboard"></i>
                                                                <span class="modalCLipboard">
                                                                    Clique para <br>copiar o link     								            
                                                                </span>
                                                            </p> 
                                                        </span>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="search">
                            <div id="fundoBusca">
                                <div class="container">
                                    <div class="">
                                        <p style="font-size: 13px; font-weight: bold;">Preencha todos os campos obrigatórios (Rua e Número) antes de realizar a busca!</p>
                                        <div class="row" style="margin-left: 5px;">
                                            <label class="col-sm-4 tight-gutter">Cep:<br />
                                                <input name="cep" type="text" id="cep" value=""  size="7" maxlength="9"
                                                placeholder="Insira o CEP" onblur="pesquisacep(this.value);" /></label>
                                            <label class="col-sm-4 tight-gutter"><a style="color: red; font-size: 15px;">*</a>Número:<br />
                                                <input name="numero" placeholder="Obrigatório" type="text" id="numero" size="7" value=""/>
                                            </label>
                                            <label class="col-sm-4 tight-gutter">Estado:<br />
                                                <input disabled placeholder="SP" name="uf" type="text" id="uf" size="7" value="SP"/>
                                            </label>
                                        </div>
                
                                        <p id="aviso"></p>
                
                                        <label class="links"><a style="color: red; font-size: 15px;">*</a>Rua:<br />
                                            <input autocomplete="off" placeholder="Campo obrigatório" class="autocomplete" style="color: black;" name="myCountry" type="text" id="rua" size="40" onblur="trocouRua();"/>
                                        </label>
                
                
                                        <label class="links">Bairro:<br />
                                            <input name="bairro" type="text" id="bairro" size="40"/>
                                        </label>
                                            
                                        <label class="links">Cidade:<br />
                                            <input disabled value="São Paulo" placeholder="São Paulo" name="cidade" type="text" id="cidade" size="40" />
                                        </label><br />
                                    </div>
                                    <span id="limpar" class="btn btn-default">Limpar</span>      
                                    <button disabled class="btn btn-primary btn-sm fechar" id="fadeRev"
                                    onclick = "fecharSearch('fundoBusca')">Ok</button>
                                    <br>
                                    <br>
                                </div>
                            </div>
                        </div>
                        <p id="textSide"></p>
                    </div>
                    <ul>
                        <li v-on:mousemove="openTitle('Aba Lateral')" v-on:mouseleave="closeTitle()" class="listaSidebar ativoSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="abreFechaSidebar" onclick="toggleMenu()" class="iconeSidebar toggleSidebar">
                                    <i class="fa fa-exchange" aria-hidden="true"></i>
                                </span>
                            </a>
                        </li>

                        <li v-on:mousemove="openTitle('Upload de arquivo')" v-on:mouseleave="closeTitle()" class="listaSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="uploadButton" v-on:click="uploadFile()" class="iconeSidebar">
                                    <i class="far fa-file-upload"></i>
                                </span>
                            </a>
                        </li>
                        <li v-on:mousemove="openTitle('Planilha de Metadados')" v-on:mouseleave="closeTitle()" class="listaSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="planilhaExcel" v-on:click="planilha()" class="iconeSidebar">
                                    <i class="fas fa-table"></i>
                                </span>
                            </a>
                        </li>
                        <li v-on:mousemove="openTitle('Desenhar Polígonos')" v-on:mouseleave="closeTitle()" class="listaSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="drawPolygonButton" v-on:click="drawPolygon()" class="iconeSidebar">
                                    <i class="fas fa-draw-polygon"></i>
                                </span>
                            </a>
                        </li>

                        
                        <li v-on:mousemove="openTitle('Busca Rua')" v-on:mouseleave="closeTitle()" class="listaSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="buscaCepButton" v-on:click="buscaCepButton()" class="iconeSidebar">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                </span>
                            </a>
                        </li>
                        
                        <li v-on:mousemove="openTitle('Histórico')" v-on:mouseleave="closeTitle()" class="listaSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="historicoButton" v-on:click="abrirHistorico()" class="iconeSidebar">
                                    <i class="fa fa-history" aria-hidden="true"></i>
                                </span>
                            </a>
                        </li>
                        <li v-on:mousemove="openTitle('Mostrar Distritos')" v-on:mouseleave="closeTitle()" class="listaSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="mostrarDistritos" v-on:click="mostrarDistrito()" class="iconeSidebar">
                                    <i class="far fa-globe-americas"></i>
                                </span>
                            </a>
                        </li>
                        <li v-on:mousemove="openTitle('Captura de Tela')" v-on:mouseleave="closeTitle()" class="listaSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="printButton" v:on-click="printScreen()" class="iconeSidebar">
                                    <i class="fa fa-print" aria-hidden="true"></i>
                                </span>
                            </a>
                        </li>
                        
                        <li v-on:mousemove="openTitle('Feedback')" v-on:mouseleave="closeTitle()" id="reportButton" v-on:click="reportButton()" class="listaSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="feedbackButton" class="report hoverside">
                                    <i class="far fa-flag"></i>
                                </span>
                            </a>
                        </li> 
                        <li v-on:mousemove="openTitle('Tutorial')" v-on:mouseleave="closeTitle()" id="tutorialButton" v-on:click="tutorialScreen()" class="listaSidebar">
                            <b></b>
                            <b></b>
                            <a href="#">
                                <span id="tutorialButton" class="bottom hoverside">
                                    <i class="fa fa-question-circle " aria-hidden="true"></i>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div onclick="stopDrawFunc()" id="stopDrawButton"><a>Cancelar Desenho</a></div>
                <div id="stopCaptureButton"><a>Cancelar Captura</a></div>
                <div id="sidebar"></div>
            </div>
            `,
            data: function () {
                return {
                    datas:
                        [
                            [],
                            [],
                            []
                        ],
                    contar1: "",
                    contar2: "",
                    contar3: "",
                    title: "fsiavfsafasu",
                    historico: [],
                    datesToday: [],
                    datesYesterday: [],
                    datesLast: [],
                }
            },
            methods: {
                filter: function () {
                    this.datesToday = []
                    this.datesYesterday = []
                    this.datesLast = []

                    if (historico !== null) {
                        const vetorEpochs = this.historico.sort().reverse()
                        for (let i = 0; i < vetorEpochs.length; i++) {
                            if (vetorEpochs[i] !== null) {
                                const passJson = vetorEpochs[i]
                                const getDatahora = vetorEpochs[i].datahora
                                const datahora = new Date(getDatahora * 1000)
                                const hoje = new Date();
                                const ontem = new Date(hoje.getTime() - 86400000)
                                const seteDias = new Date(hoje.getTime() - 604800000)
                                const mydate = new Date(datahora);

                                if (hoje.getDate() === mydate.getDate() &&
                                    hoje.getMonth() === mydate.getMonth() &&
                                    hoje.getFullYear() === mydate.getFullYear()) {

                                    this.datesToday.push(passJson)

                                }
                                if (ontem.getDate() === mydate.getDate() &&
                                    ontem.getMonth() === mydate.getMonth() &&
                                    ontem.getFullYear() === mydate.getFullYear()) {

                                    this.datesYesterday.push(passJson)

                                }
                                if (ontem > mydate && seteDias <= mydate &&
                                    mydate.getDate() !== ontem.getDate()) {

                                    this.datesLast.push(passJson)

                                }
                            }
                        }
                    }
                },
                epochtoday: function () {
                    $(document).ready(function () {
                        const inner1 = document.querySelectorAll("[id = getDate1]");
                        const vetorEpochs1 = searchSide.$children[0].datesToday
                        for (let i = 0; i < vetorEpochs1.length; i++) {
                            const getDatahora1 = vetorEpochs1[i].datahora
                            const datahora1 = new Date(getDatahora1 * 1000).toLocaleString('pt-BR');
                            try {
                                inner1[i].innerHTML = datahora1
                            } catch (e) { console.log("epochtoday", e) }
                        }
                    })
                },
                epochyesterday: function () {
                    $(document).ready(function () {
                        const inner2 = document.querySelectorAll("[id = getDate2]");
                        const vetorEpochs2 = searchSide.$children[0].datesYesterday
                        for (let i = 0; i < vetorEpochs2.length; i++) {
                            const getDatahora2 = vetorEpochs2[i].datahora
                            const datahora2 = new Date(getDatahora2 * 1000).toLocaleString('pt-BR');
                            try {
                                inner2[i].innerHTML = datahora2
                            } catch (e) { console.log(e) }
                        }
                    })
                },
                epochlast: function () {
                    $(document).ready(function () {
                        const inner3 = document.querySelectorAll("[id = getDate3]");
                        const vetorEpochs3 = searchSide.$children[0].datesLast
                        for (let i = 0; i < vetorEpochs3.length; i++) {
                            const getDatahora3 = vetorEpochs3[i].datahora
                            const datahora3 = new Date(getDatahora3 * 1000).toLocaleString('pt-BR');
                            try {
                                inner3[i].innerHTML = datahora3
                            } catch (e) { console.log(e) }
                        }
                    })
                },
                displayClash: function (clash) {
                    if (firstClash === true) {
                        separateUrl(clash)
                        firstClash = false
                    }
                    this.datas = clash
                    this.contar1 = this.datas[0].length;
                    this.contar2 = this.datas[1].length;
                    this.contar3 = this.datas[2].length;
                },
                setHistorico: function (historico) {
                    this.historico = historico
                },
                historyClash: function (lote, tipologia) {
                    if (getMultiClash() === true) {
                        closeCloseUpload()
                        cleanClashs()
                        closeClashAux()
                        SetMultiClash(false)
                        SetMultiLote(undefined)
                        changeLotes()
                        document.getElementById("showSide").style.display = "none";
                        document.getElementById("uploadButtonid").style.display = "none"
                    }
                    cleanModals()
                    distritoActivated(false)
                    fecharSidebar()
                    const collapseElementList = [].slice.call(document.querySelectorAll('.collapse.show'))
                    collapseElementList.map(function (collapseEl) {
                        return new bootstrap.Collapse(collapseEl)
                    })
                    setTipologia(tipologia)
                    setLotes(lote)
                    cleanAllLayers()
                    // const polygon = turf.polygon(lote);
                    setBufferLote(lote)
                    setAfterClash(true); setClashValidator(true)
                    if (document.getElementById('drawPolygonButton').classList[1] === "ativo") {
                        $("#drawPolygonButton").click();
                    }
                    if (lote.geometry.coordinates[0][0][0][0] === undefined) {
                        changeViewState(lote.geometry.coordinates[0][0][0], lote.geometry.coordinates[0][0][1], 500, 15, 50)
                        linkCamadaDeRisco(lote.geometry.coordinates[0], true)
                    } else {
                        changeViewState(lote.geometry.coordinates[0][0][0][0], lote.geometry.coordinates[0][0][0][1], 500, 15, 50)
                        linkCamadaDeRisco(lote.geometry.coordinates[0][0], true)
                    }
                    $.ajax({
                        type: "POST",
                        url: `${baseUrlApi()}getedificacao`,
                        data: { "lote": JSON.stringify(lote.geometry.coordinates[0][0]) },
                        dataType: 'JSON',
                        success: function (layers) {
                            if (layers[0].features.length === 0) {
                                $.ajax({
                                    type: "POST",
                                    url: `${baseUrlApi()}getedificacao`,
                                    data: { "lote": JSON.stringify(lote.geometry.coordinates[0]) },
                                    dataType: 'JSON',
                                    success: function (layers) {
                                        createBuildings(layers[0])
                                    }
                                });
                            } else {
                                createBuildings(layers[0])
                            }
                        }
                    });
                },
                drawPolygon: function () {
                    if (!toggleDrawPolygon) {
                        fecharSidebar()
                        document.getElementById("NebulaCentral").style.right = "15px"
                        document.getElementById("drawPolygonButton").classList.add("ativo")
                        toggleDrawPolygon = true
                    } else {
                        cleanSidebarIcons()
                        setTimeout(() => {
                            document.getElementById("NebulaCentral").style.right = "-200px"
                            document.getElementById("drawPolygonButton").classList.remove("ativo")
                            toggleDrawPolygon = false
                        }, 10);
                    }
                },
                uploadFile: function () {
                    fecharSidebar()
                    let emailCenter = document.querySelector(".emailFormCenter")
                    let emailBody = document.querySelector(".emailFormBody")
                    let emailForm = document.querySelector(".formEmail")
                    emailCenter.classList.remove('opacity')
                    emailBody.classList.remove('active')
                    emailForm.classList.remove('active')
                    setTimeout(() => {
                        emailCenter.classList.remove('active')
                    }, 800);

                    let fundoPlanilha = document.querySelector(".fundoPlanilha")
                    let planilhaBody = document.querySelector(".planilhaBody")
                    fundoPlanilha.classList.remove('opacity')
                    planilhaBody.classList.remove('active')
                    setTimeout(() => {
                        fundoPlanilha.classList.remove('active')
                    }, 800);
                    let uploadCenter = document.querySelector(".uploadFormCenter")
                    let uploadBody = document.querySelector(".uploadFormBody")
                    let uploadForm = document.querySelector(".formupload")

                    uploadCenter.classList.add("active")
                    uploadBody.classList.add("active")
                    uploadForm.classList.add("active")
                    setTimeout(() => {
                        uploadCenter.classList.add("opacity")
                    }, 200);
                },
                abrirHistorico: function () {
                    abrirSidebar()
                    document.getElementById("displayControl").style.display = "flex"
                    document.getElementById("fundoBusca").style.display = "none";
                    document.getElementById("textSide").style.display = "none";
                },
                reportButton: function () {
                    fecharSidebar()
                    let uploadCenter = document.querySelector(".uploadFormCenter")
                    let uploadBody = document.querySelector(".uploadFormBody")
                    let uploadForm = document.querySelector(".formupload")
                    uploadCenter.classList.remove('opacity')
                    uploadBody.classList.remove('active')
                    uploadForm.classList.remove('active')
                    setTimeout(() => {
                        uploadCenter.classList.remove('active')
                    }, 800);

                    let fundoPlanilha = document.querySelector(".fundoPlanilha")
                    let planilhaBody = document.querySelector(".planilhaBody")
                    fundoPlanilha.classList.remove('opacity')
                    planilhaBody.classList.remove('active')
                    setTimeout(() => {
                        fundoPlanilha.classList.remove('active')
                    }, 800);
                    let emailCenter = document.querySelector(".emailFormCenter")
                    let emailBody = document.querySelector(".emailFormBody")
                    let emailForm = document.querySelector(".formEmail")

                    emailCenter.classList.add("active")
                    emailBody.classList.add("active")
                    emailForm.classList.add("active")
                    setTimeout(() => {
                        emailCenter.classList.add("opacity")
                    }, 200);
                },
                planilha: function () {
                    fecharSidebar()
                    // document.getElementById("fundoIframeBim").style.display = "flex";
                    let emailCenter = document.querySelector(".emailFormCenter")
                    let emailBody = document.querySelector(".emailFormBody")
                    let emailForm = document.querySelector(".formEmail")
                    emailCenter.classList.remove('opacity')
                    emailBody.classList.remove('active')
                    emailForm.classList.remove('active')
                    setTimeout(() => {
                        emailCenter.classList.remove('active')
                    }, 800);
                    let fundoPlanilha = document.querySelector(".fundoPlanilha")
                    let planilhaBody = document.querySelector(".planilhaBody")

                    fundoPlanilha.classList.add("active")
                    planilhaBody.classList.add("active")
                    setTimeout(() => {
                        fundoPlanilha.classList.add("opacity")
                    }, 200);
                },
                mostrarDistrito: function () {
                    if (getDistritoAberto()) {
                        distritoActivated(false)
                        changeSelectOrRotate(null); cleanSidebarIcons(); SetDistritoAberto(false)
                    } else {
                        distritoActivated(true); closeClashAux()
                        changeSelectOrRotate(null); changeViewState(-46.6333, -23.6900, 500, 9); SetDistritoAberto(true)
                    }
                    changeLayers()
                },
                buscaCepButton: function () {
                    abrirSidebar()
                    document.getElementById("displayControl").style.display = "none"
                    iniciarSearch()
                },
                printScreen: function () {

                },
                tutorialScreen: function () {
                    let fundoPlanilha = document.querySelector(".fundoPlanilha")
                    let planilhaBody = document.querySelector(".planilhaBody")
                    fundoPlanilha.classList.remove('opacity')
                    planilhaBody.classList.remove('active')
                    setTimeout(() => {
                        fundoPlanilha.classList.remove('active')
                    }, 800);
                    let emailCenter = document.querySelector(".emailFormCenter")
                    let emailBody = document.querySelector(".emailFormBody")
                    let emailForm = document.querySelector(".formEmail")
                    emailCenter.classList.remove('opacity')
                    emailBody.classList.remove('active')
                    emailForm.classList.remove('active')
                    setTimeout(() => {
                        emailCenter.classList.remove('active')
                    }, 800);
                    closeUpload()
                    fecharSidebar()
                    displayFirstWindow()
                },
                openTitle: function (title) {
                    this.title = title
                },
                closeTitle: function () {
                    document.getElementById("popupInfo").style.opacity = 0
                },
            }
        }
    }
})
document.addEventListener("mousemove", function (event) {
    const id = event.target.id
    if (id === "abreFechaSidebar" || id === "uploadButton" || id === "drawPolygonButton" ||
        id === "buscaCepButton" || id === "historicoButton" || id === "mostrarDistritos" ||
        id === "printButton" || id === "feedbackButton" || id === "tutorialButton" ||
        id === "planilhaExcel") {
        document.getElementById("popupInfo").style.left = event.clientX + "px"
        document.getElementById("popupInfo").style.top = event.clientY + "px"
        document.getElementById("popupInfo").style.opacity = 1
    }
})

function removerAspas() {
    const changeText = document.querySelectorAll('.changeTextUrl')
    for (let i = 0; i < changeText.length; i++) {
        const t = $(changeText[i]).text()
        $(changeText[i]).html(t)
    }
}

function separateUrl(text) {
    for (let i = 0; i < text.length; i++) {
        for (let j = 0; j < text[i].length; j++) {
            if (text[i][j]) {
                text[i][j].metadado = urlify(text[i][j].metadado)
            }
        }
    }
    setTimeout(() => {
        removerAspas()
    }, 1000);
}

function urlify(text) {
    if (text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return "<a style='color: blue; max-width: 200px;' href='" + url + "'>" + url + "</a>";
        })
    }
}

window.copyToClipboard = function (element) {
    $('.modalCLipboard').text(function () {
        return 'Copiado!'
    });
    $(".clipboardHistory").mouseleave(function () {
        $('.modalCLipboard').text(function () {
            return 'Clique para copiar o link'
        });
    })
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
}

let historicoAntigo = localStorage.getItem("historico")
let historico = null
if (historicoAntigo !== null) {
    historico = JSON.parse(historicoAntigo)
}
searchSide.$children[0].setHistorico(historico)
searchSide.$children[0].filter()
setTimeout(() => {
    searchSide.$children[0].epochtoday()
    searchSide.$children[0].epochyesterday()
    searchSide.$children[0].epochlast()
}, 10);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Inicio Busca por CEP

var sidebarGeovista = document.querySelector('.sidebarGeovista')
let listaSidebar = document.querySelectorAll('.listaSidebar');

export function fecharSidebar() {
    document.getElementById("textSide").style.display = "none";
    sidebarGeovista.classList.remove('active')
}

export function abrirSidebar() {
    sidebarGeovista.classList.add('active')
}

export function cleanSidebarIcons() {
    setTimeout(() => {
        for (let i = 0; i < listaSidebar.length; i++) {
            let j = 0
            while (j < listaSidebar.length) {
                listaSidebar[j++].className = 'listaSidebar'
            }
            listaSidebar[0].className = 'listaSidebar ativoSidebar'
        }
    }, 10);
}

function iniciarSearch() {
    document.getElementById("fundoBusca").style.display = "flex";
    document.getElementById("textSide").style.display = "none";
    document.getElementById("displayControl").style.display = "none"
}

window.fecharSearch = function () {
    document.getElementById("fundoBusca").style.display = "none";
}

function validateCEP(inp) {
    inp.addEventListener("input", function () {
        let expression = /^[0-9][0-9][0-9][0-9][0-9][-][0-9][0-9][0-9]$/;
        if (expression.test(this.value)) {
            document.getElementById("cep").style.border = "2px solid grey"
            document.getElementById("cep").style.borderRadius = "3px"
        } else {
            document.getElementById("cep").style.border = "2px solid red"
            document.getElementById("cep").style.borderRadius = "3px"
        }
    })
}

var antes;

function limpa_formulario_cep() {
    document.getElementById('numero').value = ("");
    document.getElementById('rua').value = ("");
    document.getElementById('bairro').value = ("");
    document.getElementById('uf').value = ("");
    document.getElementById("aviso").innerHTML = ("")
}

function limpa_formulario() {
    document.getElementById('numero').value = ("");
    document.getElementById('cep').value = ("");
    document.getElementById('rua').value = ("");
    document.getElementById('bairro').value = ("");
    document.getElementById('uf').value = ("");
    document.getElementById("aviso").innerHTML = ("")
}

window.meu_callback = function (conteudo) {
    var cidade = document.getElementById("cidade")
    antes = document.getElementById("rua").value

    if (!("erro" in conteudo)) {
        document.getElementById('rua').value = (conteudo.logradouro).toUpperCase();
        document.getElementById('bairro').value = (conteudo.bairro).toUpperCase();
        document.getElementById('cidade').value = (conteudo.localidade)
        document.getElementById('uf').value = (conteudo.uf);
        document.getElementById('cep').style.border = "1px solid grey";
        cidade.style.border = "1px solid grey";
        document.getElementById("aviso").innerHTML = ("")

        if (cidade.value !== "São Paulo") {
            limpa_formulario_cep();
            document.getElementById("cep").style.border = "2px solid red"
            document.getElementById("cep").style.borderRadius = "3px"
            document.getElementById("cep").value = ("");
            cidade.style.border = "2px solid red"
            cidade.style.borderRadius = "3px"
            document.getElementById("cep").value = ("");
            document.getElementById('cidade').value = "São Paulo";
            document.getElementById("aviso").innerHTML = ("Informe um CEP da cidade de São Paulo")
            setTimeout(() => {
                document.getElementById("cep").style.border = "2px solid grey"
                cidade.style.border = "2px solid grey"
            }, 300)
            setTimeout(() => {
                document.getElementById("cep").style.border = "2px solid red"
                cidade.style.border = "2px solid red"
            }, 600)
            setTimeout(() => {
                document.getElementById("cep").style.border = "2px solid grey"
                cidade.style.border = "2px solid grey"
            }, 900)
            setTimeout(() => {
                document.getElementById("cep").style.border = "2px solid red"
                cidade.style.border = "2px solid red"
            }, 1200)
        }
    }
    else {
        //CEP não Encontrado.
        limpa_formulario_cep();
        document.getElementById("cep").style.border = "2px solid red"
        document.getElementById("cep").value = ("");
        cidade.style.border = "1px solid grey"
        document.getElementById("cep").style.borderRadius = "3px"
        document.getElementById("aviso").innerHTML = ("CEP não encontrado.")
        setTimeout(() => {
            document.getElementById("cep").style.border = "2px solid grey"
        }, 300)
        setTimeout(() => {
            document.getElementById("cep").style.border = "2px solid red"
        }, 600)
        setTimeout(() => {
            document.getElementById("cep").style.border = "2px solid grey"
        }, 900)
        setTimeout(() => {
            document.getElementById("cep").style.border = "2px solid red"
        }, 1200)
    }
}

window.trocouRua = function () {
    var depois = document.getElementById("rua").value
    if (antes !== depois) {
        document.getElementById('cep').value = ("");
        document.getElementById('bairro').value = ("");
        document.getElementById('uf').value = ("");
        document.getElementById("aviso").innerHTML = ("")
    }
}

window.pesquisacep = function (valor) {
    var cep = valor.replace(/\D/g, '');

    if (cep !== "") {
        var validacep = /^[0-9]{8}$/;

        if (validacep.test(cep)) {
            document.getElementById('rua').value = "Aguarde";
            document.getElementById('bairro').value = "Aguarde";
            document.getElementById('cidade').value = "São Paulo";
            document.getElementById('uf').value = "SP";
            document.getElementById("aviso").innerHTML = ("")

            var script = document.createElement('script');
            script.src = 'https://viacep.com.br/ws/' + cep + '/json/?callback=meu_callback';
            document.body.appendChild(script);
        }
        else {
            limpa_formulario_cep();
            document.getElementById("cep").style.border = "2px solid red"
            document.getElementById("cep").value = ("");
            document.getElementById("cidade").style.border = "1px solid grey"
            document.getElementById("cep").style.borderRadius = "3px"
            document.getElementById("aviso").innerHTML = ("Insira um formato de CEP válido.")
            setTimeout(() => {
                document.getElementById("cep").style.border = "2px solid grey"
            }, 300)
            setTimeout(() => {
                document.getElementById("cep").style.border = "2px solid red"
            }, 600)
            setTimeout(() => {
                document.getElementById("cep").style.border = "2px solid grey"
            }, 900)
            setTimeout(() => {
                document.getElementById("cep").style.border = "2px solid red"
            }, 1200)
        }
    }
};

$(document).keyup(function () {
    const rua = document.getElementById('rua')
    const numero = document.getElementById('numero')
    const cidade = document.getElementById("cidade")
    if ((rua.value !== undefined && numero.value !== undefined) && (rua.value !== "" && numero.value !== "")) {
        document.getElementById("fadeRev").disabled = false
    } else {
        document.getElementById("fadeRev").disabled = true
        cidade.style.border = "1px solid grey"
        cidade.style.borderRadius = "3px"
        document.getElementById("cep").style.border = "1px solid grey"
        document.getElementById("cep").style.borderRadius = "3px"
    }
});

$(document).ready(function () {
    $("#limpar").click(function () {
        limpa_formulario()
    });
})

export function cleanModals() {
    let fundoPlanilha = document.querySelector(".fundoPlanilha")
    let planilhaBody = document.querySelector(".planilhaBody")
    fundoPlanilha.classList.remove('opacity')
    planilhaBody.classList.remove('active')
    setTimeout(() => {
        fundoPlanilha.classList.remove('active')
    }, 800);
    let emailCenter = document.querySelector(".emailFormCenter")
    let emailBody = document.querySelector(".emailFormBody")
    let emailForm = document.querySelector(".formEmail")
    emailCenter.classList.remove('opacity')
    emailBody.classList.remove('active')
    emailForm.classList.remove('active')
    setTimeout(() => {
        emailCenter.classList.remove('active')
    }, 800);
}

$(document).ready(function () {
    $("#fadeRev").click(function () {
        distritoActivated(false)
        changeLayers()
        closeClash()
        fecharSidebar()
        Loading("iniciar")
        changeViewState(-46.6333, -23.5505, 500, 9)

        const rua = document.getElementById("rua").value
        const bairro = document.getElementById("bairro").value
        const cidade = document.getElementById("cidade").value
        const numero = document.getElementById("numero").value
        const uf = document.getElementById("uf").value

        const collapseElementList = [].slice.call(document.querySelectorAll('.collapse.show'))
        collapseElementList.map(function (collapseEl) {
            return new bootstrap.Collapse(collapseEl)
        })

        document.getElementById("showSide").style.display = "none";
        cleanSidebarIcons()

        $(document).ready(function () {
            $.ajax({
                type: "POST",
                url: `${baseUrlApi()}gelatlng`,
                data: {
                    "rua": rua,
                    "bairro": bairro,
                    "numero": numero,
                    "cidade": cidade,
                    "uf": uf
                },
                dataType: 'JSON',
                success: function (localBusca) {
                    Loading("fechar")
                    prepareLotes(localBusca[1], localBusca[0])
                    changeViewState(localBusca[1], localBusca[0], 500, 15)
                }
            })
        })
    });
})

//Final Busca por CEP
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Inicio Autocomplete

function autocomplete(inp, arr) {
    var currentFocus;
    String.prototype.contains = function contains(typedValue) {
        typedValue = typedValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
        const roadName = this.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
        const vetor = roadName.split(typedValue)
        return (vetor.length > 1) ? true : false
    }

    inp.addEventListener("input", function () {
        var a, b, i, val = this.value;
        closeAllLists();
        if (!val) { return false; }
        currentFocus = -1;
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        this.parentNode.appendChild(a);
        let counter = 0
        for (i = 0; i < arr.length; i++) {
            if (arr[i].contains(val)) {
                b = document.createElement("DIV");

                const ruaVetor = arr[i].toUpperCase().split(val.toUpperCase())
                for (let i = 0; i < ruaVetor.length; i++) {
                    b.innerHTML += ruaVetor[i]
                    if (i < (ruaVetor.length - 1)) {
                        b.innerHTML += "<strong>" + val.toUpperCase() + "</strong>"
                    }
                }

                b.innerHTML += "<input type='hidden' value='" + arr[i].toUpperCase() + "'>";
                b.addEventListener("click", function () {
                    inp.value = this.getElementsByTagName("input")[0].value;
                    closeAllLists();
                });
                a.appendChild(b);
                counter++
                if (counter === 15) {
                    break
                }
            }
        }
    });
    inp.addEventListener("keydown", function (e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode === 40) {
            currentFocus++;
            addActive(x);
        } else if (e.keyCode === 38) {
            currentFocus--;
            addActive(x);
        } else if (e.keyCode === 13) {
            e.preventDefault();
            if (currentFocus > -1) {
                if (x) x[currentFocus].click();
            }
        }
    });
    function addActive(x) {
        if (!x) return false;
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }
    function closeAllLists(elmnt) {
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt !== x[i] && elmnt !== inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
}
$(document).ready(function () {
    let ruasCache = localStorage.getItem("vetorRuas")
    if (ruasCache === null) {
        $.ajax({
            type: "POST",
            url: `${baseUrlApi()}listaderuas`,
            success: function (msg) {
                localStorage.setItem("vetorRuas", msg)
            }
        }).done(function () {
            ruasCache = JSON.parse(localStorage.getItem("vetorRuas"))
            autocomplete(document.getElementById("rua"), ruasCache);

        });
    } else {
        ruasCache = JSON.parse(ruasCache)
        autocomplete(document.getElementById("rua"), ruasCache);

    }
    validateCEP(document.getElementById("cep"));
})

//Final Autocomplete
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$("#printButton").click(function () {
    cleanModals()
})

$("#fadeRev").click(function () {
    SetDistritoAberto(false)
})

$("#reportButton").click(function () {
    SetDistritoAberto(false)
})

$("#planilhaExcel").click(function () {
    SetDistritoAberto(false)
})
