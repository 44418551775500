import { changeSelectOrRotate } from '../Deck Components/Visual Settings/DeckComponent';
import { BufferFunction, changeClashDisplay, changeLayers, changeLegend, changeViewState, closeClashAux, createBuildings } from '../Deck Components/Organizar Layers/DeckPrepare';
import { prepareLotes, prepareRisks, prepareRisksClashMode } from './Requests';
import { getTypology, iniciarModal, resetTipologys } from '../../Tipologia/Tipologia';
import { fecharSidebar } from '../../Sidebar/Sidebar';
import { bufferJSONDeck } from '../../../Clash/auxiliarylibrary';
import $ from "jquery"
import { getAfterClash, getBufferLimiter, getClashBruto, getClashBuilding, getClashFilter, getClashValidator, getConfirmedLote, getFinalClash, getLastSelectedID, getLotes, getMultiClash, getMultiLote, getRememberClashAux, getTipologyValues, getValidateTipologia, setAfterClash, setBufferLimiter, setBufferLote, setClashValidator, setConfirmedLote, setGlobalRisk, setisNebula, setLastSelectedID, setLogicModeClash, setLotes, SetMultiLote, setNebulaGeojsonInfo, setRememberClashAux, setSelectedID, setValidateTipologia } from '../../../DataHandler/DataHandler';
import { openCloseUpload } from '../../Sidebar/UploadFile/UploadFile';
import { getColor, getIndex, redistribuirIcones } from '../../../Format JSON/formatJson';
import { distributeAlgorithms, distributeAlgorithmsFeatures } from '../../../Format JSON/DistributeAlgorithms';
import Worker from "web-worker"
import { rightButton } from '../../RightButton/RightButton';
const worker = new Worker(
    new URL('../../../Worker/WorkerBuffer.js', import.meta.url),
    { type: 'module' }
);

var clashValidator, afterClash, infoaux;

export function getLote(info, editMode) {
    if (getMultiClash() !== true) {
        afterClash = getAfterClash()
        if (editMode && !afterClash) {
            setAfterClash(false); setClashValidator(false)
            fecharSidebar()
            changeSelectOrRotate(null)
            if (info.viewport.zoom <= 12) { changeViewState(info.coordinate[0], info.coordinate[1], 500, 15) }
            else { changeViewState(info.coordinate[0], info.coordinate[1], 500, info.viewport.zoom) }
            prepareLotes(info.coordinate[0], info.coordinate[1], info)
        }
    } else {
        openCloseUpload()
    }
}

export function getUploadRisk() {
    setConfirmedLote(infoaux)
    // setLotes(infoaux.geometry.coordinates[0])
    if (infoaux !== undefined && getValidateTipologia() === true) {
        for (let i = 0; i < getMultiLote().features.length; i++) {
            if (getMultiLote().features[i] === infoaux) {
                getMultiLote().features[i].properties.isSelected = true
                getMultiLote().features[i].properties.isClicked = true
            }
        }
        SetMultiLote(getMultiLote())
    }
}

export function auxCallRemember() {
    callRemember(infoaux)
    setConfirmedLote(infoaux)
}

export function getFalseOnUploadRisk() {
    // setLotes(infoaux.geometry.coordinates[0])
    if (infoaux !== undefined && getValidateTipologia() === true) {
        // for (let i = 0; i < getClashFilter().length; i++) {
        //     if (getClashFilter()[i][0] === infoaux.properties.id) {
        //         reload = false
        //     }
        // }
        for (let i = 0; i < getMultiLote().features.length; i++) {
            if (getMultiLote().features[i] === infoaux) {
                getMultiLote().features[i].properties.dontOpen = true
            }
        }
        SetMultiLote(getMultiLote())
    }
}

function callRemember(info) {
    let dontFilter = true
    let infoParameter
    if (info.object === undefined) {
        infoParameter = info
    } else {
        infoParameter = info.object
    }
    setBufferLote(infoParameter)

    for (let i = 0; i < getClashFilter().length; i++) {
        if (getClashFilter()[i][0] === infoParameter.properties.id) {
            changeLegend(getClashFilter()[i][1])
            try { changeClashDisplay(getFinalClash()[i][1]) } catch (error) { console.log(error) }
            rememberClash(getClashFilter()[i][1],
                getClashBuilding()[i][1])
            dontFilter = false
        }
    }
    if (dontFilter) {
        for (let i = 0; i < getClashBruto().length; i++) {
            if (getClashBruto()[i][0] === infoParameter.properties.id) {
                changeLegend(getClashBruto()[i][1])
                try { changeClashDisplay(getFinalClash()[i][1]) } catch (error) { console.log(error) }
                rememberClash(getClashBruto()[i][1])
            }
        }
    }
    if (getRememberClashAux() === true) {
        getTypology()
        setLogicModeClash(false)
        setRememberClashAux(false)
    }
}

function rememberClash(layers, edificacao) {
    setBufferLote(infoaux)
    for (let i = 0; i < layers.length; i++) {
        for (let j = 0; j < layers[i].features.length; j++) {
            layers[i].features[j].properties.layerActivated = true
        }
    }

    const globalRisk = layers.slice()
    setGlobalRisk(globalRisk)
    const indexInterseccaoAtributo = getIndex(layers, "interseccao_atributo")
    let INTERSECCAOATRIBUTO = layers[indexInterseccaoAtributo]
    // if (indexInterseccaoAtributo > -1) {
    //     layers.splice(indexInterseccaoAtributo, 1);
    // }
    const indexMARKER = getIndex(layers, "interseccao_circulo")
    const indexPOLYGON = getIndex(layers, "interseccao_poligono")
    const indexPOLYLINE = getIndex(layers, "interseccao_polilinha")
    const indexPOLYGONOFFSET = getIndex(layers, "interseccao_poligono_offset")

    let POLYLINE = layers[indexPOLYLINE]
    let MARKER = layers[indexMARKER]
    let POLYGON = layers[indexPOLYGON]
    let POLYGONOFFSET = layers[indexPOLYGONOFFSET]

    POLYGON = getColor(POLYGON)
    MARKER = getColor(MARKER)
    POLYGONOFFSET = getColor(POLYGONOFFSET)
    POLYLINE = getColor(POLYLINE)
    MARKER = redistribuirIcones(MARKER)

    if (POLYGON !== undefined) { POLYGON = distributeAlgorithms(POLYGON, "titulo") }
    if (POLYGONOFFSET !== undefined) { POLYGONOFFSET = distributeAlgorithms(POLYGONOFFSET, "titulo") }
    if (POLYLINE !== undefined) { POLYLINE = distributeAlgorithms(POLYLINE, "titulo") }
    if (MARKER !== undefined) { MARKER = distributeAlgorithmsFeatures(MARKER, "titulo") }

    if (edificacao !== undefined && getClashValidator() === true && getClashFilter().length > 0) {
        createBuildings(edificacao)
    }

    changeLayers(layers, getMultiLote(), POLYGON, POLYGONOFFSET, MARKER, POLYLINE, INTERSECCAOATRIBUTO)
}
export function rebemberTipologys(selectedTipologys) {
    setLogicModeClash(true)
    resetTipologys()
    iniciarModal('tipologiaCheckbox')
    for (let i = 0; i < selectedTipologys.length; i++) {
        if (selectedTipologys[i] === "Edificação de educação") {
            document.getElementById("tipo1").click()
        }
        if (selectedTipologys[i] === "Edificação de saúde") {
            document.getElementById("tipo2").click()
        }
        if (selectedTipologys[i] === "Edificação de Habitação de Interesse Popular") {
            document.getElementById("tipo3").click()
        }
        if (selectedTipologys[i] === "Edificação de Habitação de Mercado Popular") {
            document.getElementById("tipo4").click()
        }
        if (selectedTipologys[i] === "Edificação de outro fim") {
            document.getElementById("tipo5").click()
        }
        if (selectedTipologys[i] === "Obra de arte") {
            document.getElementById("tipo6").click()
        }
        if (selectedTipologys[i] === "Sistema viário") {
            document.getElementById("tipo7").click()
        }
        if (selectedTipologys[i] === "Drenagem") {
            document.getElementById("tipo8").click()
        }
        if (selectedTipologys[i] === "Regularização fundiária") {
            document.getElementById("tipo9").click()
        }
        if (selectedTipologys[i] === "Recuperação ambiental") {
            document.getElementById("tipo10").click()
        }
    }
}

export function prepareCamadaClash() {
    setBufferLote(infoaux)
    setConfirmedLote(infoaux)
    prepareRisksClashMode(infoaux.geometry.coordinates[0], getMultiLote(), infoaux.properties.id)
}

var limiterCheckbox = false
export async function getRisks(info, nebula) {
    afterClash = getAfterClash()
    clashValidator = getClashValidator()
    infoaux = info.object
    document.getElementById("bordaCirculo").style.left = "-7000px";
    if (getMultiClash() === true) {
        setNebulaGeojsonInfo()
        setSelectedID(info.object.properties.id)
        if (clashValidator && info.object.properties.isSelected === true) {
            setValidateTipologia(true)
            if (getLastSelectedID() === info.object.properties.id && info.object.properties.isClicked === true) {
                if (getConfirmedLote() === info.object) {
                    setBufferLote(info.object)
                    changeSelectOrRotate(null)
                    rightButton(info.x, info.y);
                    // openIframeBim()
                    // callRemember(info)
                    // console.log("TESTE 1 IF")
                } else {
                    for (let i = 0; i < getClashFilter().length; i++) {
                        if (getTipologyValues()[i][0] === info.object.properties.id) {
                            setLotes(info.object)
                            rebemberTipologys(getTipologyValues()[i][1])
                            // console.log("TESTE 1 ELSE")
                        }
                    }
                }
            } else if (getLastSelectedID() !== info.object.properties.id && info.object.properties.isClicked === true) {
                // callRemember(info)
                for (let i = 0; i < getClashFilter().length; i++) {
                    if (getTipologyValues()[i][0] === info.object.properties.id) {
                        setLotes(info.object)
                        rebemberTipologys(getTipologyValues()[i][1])
                        setRememberClashAux(true)
                        // console.log("TESTE 2")
                    }
                }
            } else {
                if (getLotes() !== info.object) {
                    setLotes(info.object)
                    let prepare = true
                    for (let i = 0; i < getClashBruto().length; i++) {
                        if (getClashBruto()[i][0] === info.object.properties.id) {
                            changeLegend(getClashBruto()[i][1])
                            try { changeClashDisplay(getFinalClash()[i][1]) } catch (error) { console.log(error) }
                            rememberClash(getClashBruto()[i][1])
                            prepare = false
                        }
                    }
                    if (getClashBruto().length === 1 && limiterCheckbox === false) {
                        limiterCheckbox = true
                        iniciarModal('tipologiaCheckbox')
                    }
                    if (prepare) {
                        setBufferLote(info.object)
                        if (info.object.geometry.coordinates[0][0][0].length === undefined) {
                            prepareRisks(info.object.geometry.coordinates[0], getMultiLote(), info.object.properties.id)
                        } else {
                            prepareRisks(info.object.geometry.coordinates[0][0], getMultiLote(), info.object.properties.id)
                        }
                        changeViewState(info.coordinate[0], info.coordinate[1], 500, info.viewport.zoom)
                    }
                    for (let i = 0; i < getClashBruto().length; i++) {
                        if (getClashBruto()[i][0] === info.object.properties.id) {
                            changeLegend(getClashBruto()[i][1])
                        }
                    }
                    // console.log("passei no primeiro")
                } else {
                    // console.log("passei no primeiro else")
                    iniciarModal('tipologiaCheckbox')
                }
            }
        } else if (clashValidator && info.object.properties.isSelected === false) {
            // setBufferLote(info.object)
            setNebulaGeojsonInfo()
            setValidateTipologia(true)
            if (getLotes() !== info.object) {
                let prepare = true
                for (let i = 0; i < getClashBruto().length; i++) {
                    if (getClashBruto()[i][0] === info.object.properties.id) {
                        if (getMultiClash() === true && getClashFilter().length > 0) {
                            // console.log("passei no if")
                            setLogicModeClash(true)
                            resetTipologys()
                            iniciarModal('tipologiaCheckbox')
                        } else {
                            console.log("passei no else")
                            changeLegend(getClashBruto()[i][1])
                            try { changeClashDisplay(getFinalClash()[i][1]) } catch (error) { console.log(error) }
                            rememberClash(getClashBruto()[i][1])
                        }
                        prepare = false
                    }
                }
                if (prepare) {
                    setBufferLote(info.object)
                    if (getClashFilter().length < 1) {
                        changeViewState(info.coordinate[0], info.coordinate[1], 500, info.viewport.zoom)
                    }
                    if (getClashFilter().length > 0) {
                        setLogicModeClash(true)
                        iniciarModal('tipologiaCheckbox')
                    } else {
                        if (info.object.geometry.coordinates[0][0][0].length === undefined) {
                            prepareRisks(info.object.geometry.coordinates[0], getMultiLote(), info.object.properties.id)
                        } else {
                            prepareRisks(info.object.geometry.coordinates[0][0], getMultiLote(), info.object.properties.id)
                        }
                    }
                }
                for (let i = 0; i < getClashBruto().length; i++) {
                    if (getClashBruto()[i][0] === info.object.properties.id) {
                        changeLegend(getClashBruto()[i][1])
                    }
                }
                if (getClashFilter().length > 0) {
                    // createBuildings(undefined)
                }
                // console.log("passei no segundo")
            } else {
                // console.log("passei no segundo else")
                setLogicModeClash(true)
                resetTipologys()
                iniciarModal('tipologiaCheckbox')
            }
            setLotes(info.object)
        } else {
            setLotes(info.object)
            setNebulaGeojsonInfo()
            setBufferLote(info.object)
            setValidateTipologia(false)
            if (info.object.properties.isSelected === false) {
                closeClashAux()
            }
            if (info.object.geometry.coordinates[0][0][0].length === undefined) {
                prepareRisks(info.object.geometry.coordinates[0], getMultiLote(), info.object.properties.id)
            } else {
                prepareRisks(info.object.geometry.coordinates[0][0], getMultiLote(), info.object.properties.id)
            }
            changeViewState(info.coordinate[0], info.coordinate[1], 500, info.viewport.zoom)
            setClashValidator(true)
            for (let i = 0; i < getMultiLote().features.length; i++) {
                if (getMultiLote().features[i] === info.object) {
                    getMultiLote().features[i].properties.isSelected = true
                } else {
                    getMultiLote().features[i].properties.isSelected = false
                }
            }
            SetMultiLote(getMultiLote())
        }
        if (document.getElementById('drawPolygonButton').classList[1] === "ativo") {
            $("#drawPolygonButton").click();
        }
    } else {
        // document.getElementById("uploadButtonid").style.display = "block"
        setBufferLote(info.object)
        setLotes(info.object)
        if (clashValidator) {
            if (afterClash) {
                changeSelectOrRotate(null)
                rightButton(info.x, info.y);
                // openIframeBim()
            } else {
                iniciarModal('tipologiaCheckbox')
                setLotes(info.object)
            }
        } else if (nebula) {
            setisNebula(true)
            document.getElementById("MouseBlock").style.display = "flex"
            document.getElementById('zoomPanel').style.opacity = 0
            document.getElementById('setTilesBody').style.opacity = 0
            changeSelectOrRotate(null)
            if (info.object.geometry.coordinates[0][0][0].length === undefined) {
                prepareRisks(info.object.geometry.coordinates[0], info.object, info.object.properties.id)
            } else {
                prepareRisks(info.object.geometry.coordinates[0][0], info.object, info.object.properties.id)
            }
            changeViewState(info.object.geometry.coordinates[0][0][0][0], info.object.geometry.coordinates[0][0][0][1], 500, 15.2)
            setClashValidator(true)
        } else {
            document.getElementById("MouseBlock").style.display = "flex"
            document.getElementById('zoomPanel').style.opacity = 0
            document.getElementById('setTilesBody').style.opacity = 0
            changeSelectOrRotate(null)
            setNebulaGeojsonInfo()
            if (info.object.geometry.coordinates[0][0][0].length === undefined) {
                prepareRisks(info.object.geometry.coordinates[0], info.object, info.object.properties.id)
            } else {
                prepareRisks(info.object.geometry.coordinates[0][0], info.object, info.object.properties.id)
            }
            changeViewState(info.coordinate[0], info.coordinate[1], 500, 15.2)
            setClashValidator(true)
        }
        if (document.getElementById('drawPolygonButton').classList[1] === "ativo") {
            $("#drawPolygonButton").click();
        }
    }
    setLastSelectedID(info.object.properties.id)
}

export function createBuffer(lote) {
    if (lote !== undefined) {
        const loteaux = JSON.parse(JSON.stringify(lote));
        if (lote.properties.nome === 'massa_dagua') {
            let workerOrganize = []
            workerOrganize.push(loteaux)
            workerOrganize.push(getBufferLimiter())
            workerOrganize.push(getLotes())
            worker.postMessage(workerOrganize);
            worker.addEventListener('message', message => {
                BufferFunction(message.data)
                setBufferLimiter(true)
            })
        } else {
            setBufferLimiter(false)
            bufferJSONDeck(loteaux)
        }
    }
}

export function openPopup(info) {
    changeSelectOrRotate(info)
}

export function closeClashFunctions() {
    setClashValidator(false); setAfterClash(false)
    changeSelectOrRotate(false)
    if (getMultiLote() !== undefined) {
        for (let i = 0; i < getMultiLote().features.length; i++) {
            getMultiLote().features[i].properties.isClicked = false
            getMultiLote().features[i].properties.isSelected = false
        }
        SetMultiLote(getMultiLote())
    }
}

