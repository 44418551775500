import React from "react"
import { baseUrlApi } from "../../DataHandler/DataHandler"
import { getNames } from "./Tipologia"

export function TipologiaOptions(props) {

    const getName = () => {
        getNames(props.nome)
    }

    return (
        <div className="container-fluid checkfont">
            <input onChange={getName} id={props.id} className="col-md-1 " type="checkbox"></input>
            <label style={{ display: "inline-block" }} >
                {props.nome}
            </label>
            <p className="info">
                <img src={`${baseUrlApi()}images?name=question.png`} style={{ width: "20px" }}></img>
                <span className="span">
                    {props.desc}
                </span>
            </p>
        </div>
    )
}