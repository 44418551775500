import React from "react";
import DeckGL from "deck.gl";
import { _MapContext as MapContext, StaticMap, Popup } from "react-map-gl";
import { ChangePopup } from './ChangePopup';
import { useState } from "react";
import { useEffect } from "react";
import ReactDOM from 'react-dom';
import DeckHUB from "../Organizar Layers/DeckPrepare";
import "../../../../App.css"
import { AmbientLight, DirectionalLight, LightingEffect } from '@deck.gl/core';
import { setLat, setLng, setZoomMap } from "../../../../DataHandler/DataHandler";

const MAPBOX_ACCESS_TOKEN =
  "Não definido"
// "pk.eyJ1IjoiZ2Vvcmdpb3MtdWJlciIsImEiOiJjanZidTZzczAwajMxNGVwOGZrd2E5NG90In0.gdsRu_UeU_uPi9IulBruXA"

var changeSelected;
var changeRotate = false;
var popupDistrito = false

const lightingEffect = new LightingEffect({
  light1: new AmbientLight({
    color: [255, 255, 255],
    intensity: 2.0
  }),
  light2: new DirectionalLight({
    color: [255, 255, 255],
    intensity: 0.5,
    direction: [-1, -2, -3],
    _shadow: true
  })
});

lightingEffect.shadowColor = [0, 0, 0, 0.3];

export function changeSelectOrRotate(value) {
  if (value === true || value === false) { changeRotate = value }
  else { changeSelected = value }
  ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

function DeckMap(props) {
  const [selected, setSelected] = useState(null);
  const [rotateMap, setRotateMap] = useState(false)

  useEffect(() => {
    setSelected(changeSelected)
    setRotateMap(changeRotate)
  })

  return (
    <DeckGL
      glOptions={{ preserveDrawingBuffer: true }}
      initialViewState={props.initialViewState}
      layers={props.mapLayers}
      id="DeckMap"
      getCursor={() => "pointer"}
      // effects={[lightingEffect]}
      controller={{ dragRotate: rotateMap, doubleClickZoom: false }}
      ContextProvider={MapContext.Provider}
      onHover={(info) => {
        try {
          if (info.viewport !== undefined) {
            setLat(info.viewport.latitude)
            setLng(info.viewport.longitude)
            setZoomMap(info.viewport.zoom)
          }
          if (info.object !== undefined) {
            if (info.object.properties.isDistrito) {
              popupDistrito = true
              changeSelected = info
              setSelected(changeSelected)
            } else {
              popupDistrito = false
            }
          }
        } catch (error) { console.log(error) }
      }}
    ><>
        <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
        {selected && (
          <Popup
            longitude={selected.coordinate[0]}
            latitude={selected.coordinate[1]}
            closeButton={false}
            anchor="bottom"
          >
            <ChangePopup validate={popupDistrito} info={selected} />
          </Popup>
        )}
      </>
    </DeckGL>
  )
}

document.addEventListener('contextmenu', e => e.preventDefault());

export default DeckMap;
