import { BufferFunction } from "../Components/Deck/Deck Components/Organizar Layers/DeckPrepare";
import * as turf from '@turf/turf';
import { getBufferLimiter, getLotes, setBufferLimiter } from "../DataHandler/DataHandler";

const MASSA_DAGUA = 'massa_dagua'

// usada no nodo "biblioteca de algoritmos" para criar um buffer de geoJSONs com pontos, poligonos e polilinhas
export function bufferJSON(json) {
    let jsonAux = JSON.parse(JSON.stringify(json));
    for (let i = 0, len = jsonAux.features.length; i < len; i++) {
        let offset
        const nome = jsonAux.features[i].properties.nome
        if (nome === MASSA_DAGUA) {
            const rio = jsonAux.features[i].geometry.coordinates[0];
            const distance = getRiverDistance(getLotes(), rio)
            if (distance <= 10) {
                offset = 30
            } else if (distance > 10 && distance <= 50) {
                offset = 50
            } else if (distance > 50 && distance <= 200) {
                offset = 100
            } else if (distance > 200 && distance <= 600) {
                offset = 200
            } else if (distance > 600) {
                offset = 500
            }
        } else {
            offset = jsonAux.features[i].properties.offset
        }
        let bufferedPolygon
        const sizePolygon = jsonAux.features[i].geometry.coordinates[0][0].length // verifica tamanho de pontos no poligono                                                                                                                     
        const polygon = jsonAux.features[i].geometry.coordinates[0][0]
        const turfPolygon = turf.multiPolygon([[polygon]])
        if (sizePolygon > 10000) { // caso poligono seja muito grande, simplifica ele                                                                                                                                                       
            const options = { tolerance: 0.00001, highQuality: false };
            const simplified = turf.simplify(turfPolygon, options);
            bufferedPolygon = turf.buffer(simplified, offset, { units: 'meters' })
            jsonAux.features[i].geometry = bufferedPolygon.geometry
        } else {
            bufferedPolygon = turf.buffer(jsonAux.features[i], offset, { units: 'meters' })
            jsonAux.features[i] = bufferedPolygon
        }
        jsonAux.features[i].geometry.type = "MultiPolygon"
        jsonAux.features[i].geometry.coordinates = [jsonAux.features[i].geometry.coordinates]
    }
    BufferFunction(jsonAux)
    return jsonAux
}

export function bufferJSONDeckNebula(json, lote) {
    try {
        let jsonAux = JSON.parse(JSON.stringify(json));
        let loteAux = JSON.parse(JSON.stringify(lote));
        if (getBufferLimiter() === false) {
            setBufferLimiter(true)
            let offset
            const nome = jsonAux.properties.nome
            if (nome === MASSA_DAGUA) {
                const rio = jsonAux.geometry.coordinates[0];
                const distance = getRiverDistance(loteAux, rio)
                if (distance <= 10) {
                    offset = 30
                } else if (distance > 10 && distance <= 50) {
                    offset = 50
                } else if (distance > 50 && distance <= 200) {
                    offset = 100
                } else if (distance > 200 && distance <= 600) {
                    offset = 200
                } else if (distance > 600) {
                    offset = 500
                }
            } else {
                offset = jsonAux.properties.offset
            }
            if (offset == undefined) {
                offset = 1
            }
            let bufferedPolygon
            const sizePolygon = jsonAux.geometry.coordinates[0][0].length // verifica tamanho de pontos no poligono                                                                                                                     
            const polygon = jsonAux.geometry.coordinates[0][0]
            const turfPolygon = turf.multiPolygon([[polygon]])
            if (sizePolygon > 10000) { // caso poligono seja muito grande, simplifica ele                                                                                                                                                       
                const options = { tolerance: 0.00001, highQuality: false };
                const simplified = turf.simplify(turfPolygon, options);
                bufferedPolygon = turf.buffer(simplified, offset, { units: 'meters' })
                jsonAux.geometry = bufferedPolygon.geometry
            } else {
                bufferedPolygon = turf.buffer(jsonAux, offset, { units: 'meters' })
                jsonAux = bufferedPolygon
            }
            jsonAux.geometry.type = "MultiPolygon"
            jsonAux.geometry.coordinates = [jsonAux.geometry.coordinates]
            BufferFunction(jsonAux)
            return jsonAux
        }
    } catch (error) {
        console.log(error)
    }
}

export function bufferJSONDeck(json) {
    try {
        let jsonAux = JSON.parse(JSON.stringify(json));
        let loteAux = JSON.parse(JSON.stringify(getLotes()));
        if (getBufferLimiter() === false) {
            setBufferLimiter(true)
            let offset
            const nome = jsonAux.properties.nome
            if (nome === MASSA_DAGUA) {
                const rio = jsonAux.geometry.coordinates[0];
                const distance = getRiverDistance(loteAux, rio)
                if (distance <= 10) {
                    offset = 30
                } else if (distance > 10 && distance <= 50) {
                    offset = 50
                } else if (distance > 50 && distance <= 200) {
                    offset = 100
                } else if (distance > 200 && distance <= 600) {
                    offset = 200
                } else if (distance > 600) {
                    offset = 500
                }
            } else {
                offset = jsonAux.properties.offset
            }
            if (offset == undefined) {
                offset = 1
            }
            let bufferedPolygon
            const sizePolygon = jsonAux.geometry.coordinates[0][0].length // verifica tamanho de pontos no poligono                                                                                                                     
            const polygon = jsonAux.geometry.coordinates[0][0]
            const turfPolygon = turf.multiPolygon([[polygon]])
            if (sizePolygon > 10000) { // caso poligono seja muito grande, simplifica ele                                                                                                                                                       
                const options = { tolerance: 0.00001, highQuality: false };
                const simplified = turf.simplify(turfPolygon, options);
                bufferedPolygon = turf.buffer(simplified, offset, { units: 'meters' })
                jsonAux.geometry = bufferedPolygon.geometry
            } else {
                bufferedPolygon = turf.buffer(jsonAux, offset, { units: 'meters' })
                jsonAux = bufferedPolygon
            }
            jsonAux.geometry.type = "MultiPolygon"
            jsonAux.geometry.coordinates = [jsonAux.geometry.coordinates]
            BufferFunction(jsonAux)
            // return jsonAux
        }
    } catch (error) {
        console.log(error)
    }
}

function getRiverDistance(lote, rio) {
    try {
        const loteaux = JSON.parse(JSON.stringify(lote));
        const rioaux = JSON.parse(JSON.stringify(rio));

        // const turfPolygonLote = turf.polygon(loteaux, rioaux)

        const turfPolygonLote = turf.polygon(loteaux)
        const turfPolygonRiver = turf.polygon(rioaux)

        const centerLote = turf.centerOfMass(turfPolygonLote);
        const lineRiver = turf.polygonToLine(turfPolygonRiver);

        const pointRiver = turf.nearestPointOnLine(lineRiver, centerLote, { units: 'meters' });
        const bearing = turf.bearing(centerLote, pointRiver);

        const destinationPoint = turf.destination(pointRiver, 1000, bearing, { units: 'meters' });
        const destinationLine = turf.lineString([pointRiver.geometry.coordinates, destinationPoint.geometry.coordinates]);

        const pointCrossedRiver = turf.lineIntersect(lineRiver, destinationLine);
        //	const lineCrossingRiver = turf.lineString([pointRiver.geometry.coordinates, pointCrossedRiver.features[0].geometry.coordinates]);

        const distance = turf.distance(pointRiver.geometry.coordinates, pointCrossedRiver.features[0].geometry.coordinates, { units: 'meters' });
        return distance
    } catch (error) {
        console.log(error)
    }
}

