import React from "react"
import { setTilesFunction } from "../../Deck/Deck Components/Organizar Layers/DeckPrepare";
import "./SetTiles.css";

export function SetTiles() {
    let setTilesBody = document.querySelector(".setTilesBody")
    let centerIcon = document.querySelector(".centerIcon")
    let setTilesContent = document.querySelector(".setTilesContent")
    let CartoSelect = document.querySelector(".CartoSelect")
    let ArcGisSelect = document.querySelector(".ArcGisSelect")

    function openSetTiles() {
        try {
            setTilesBody.classList.add('active')
            setTilesContent.classList.add('active')
            centerIcon.classList.add('remove')
        } catch (error) { console.log(error) }
    }

    function closeSetTiles() {
        try {
            setTilesBody.classList.remove('active')
            setTilesContent.classList.remove('active')
            centerIcon.classList.remove('remove')
        } catch (error) { console.log(error) }
    }

    function CartoClick() {
        CartoSelect.classList.add('active')
        ArcGisSelect.classList.remove('active')
        setTilesFunction('https://c.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png')
    }

    function ArcGisClick() {
        ArcGisSelect.classList.add('active')
        CartoSelect.classList.remove('active')
        setTilesFunction('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}')
    }

    return (
        <>
            <div onMouseEnter={openSetTiles} onMouseLeave={closeSetTiles} id="setTilesBody" className="setTilesBody">
                <i className="fal fa-layer-group centerIcon"></i>
                <div className="setTilesContent">
                    <ul>
                        <li onClick={CartoClick} className="CartoSelect active">Cartocdn</li>
                        <li onClick={ArcGisClick} className="ArcGisSelect">ArcGis</li>
                    </ul>
                </div>
            </div>
        </>
    )
}