import React, { useEffect } from "react";
import DeckComponent, { changeSelectOrRotate } from "../Visual Settings/DeckComponent";
import { TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer, PathLayer } from '@deck.gl/layers';
import { GeoJsonLayer, IconLayer } from "deck.gl";
import ReactDOM from 'react-dom';
import { LoadingScreen } from "../../../Loading/LoadingScreen";
import $ from 'jquery';
import { EditableGeoJsonLayer, DrawLineStringMode, DrawPolygonMode, ViewMode } from "nebula.gl";
import { createBuffer, getLote, getRisks, openPopup } from "../../Deck Functions/LayersControl";
import { Tipologia } from "../../../Tipologia/Tipologia";
import { abrirSidebar, cleanModals, cleanSidebarIcons, searchSide } from "../../../Sidebar/Sidebar";
import "../../../../Clash/ButtonCloseDeck"
import { closeClash } from "../../../../Clash/ButtonCloseDeck";
import { bufferJSONDeckNebula } from "../../../../Clash/auxiliarylibrary";
import { Report } from "../../../Sidebar/Report/Report";
import { Planilha } from "../../../Sidebar/Planilha/Planilha";
import { getDistritos } from "../../Deck Functions/Requests";
import { baseUrlApi, cleanClashs, getBufferLote, getBuildingsView, getDistritoAberto, getFixedPopup, getIds, getLat, getLng, getLotes, getMeters, getMultiClash, getMultiLote, getSelectedID, getZoomMap, setBufferLimiter, setBufferLote, setBuildingsView, setClashFilter, setClashValidator, SetDistritoAberto, setFixedPopup, setisNebula, setLotes, setMeters, SetMultiLote, setNebulaGeojsonInfo, setZoomMap } from "../../../../DataHandler/DataHandler";
import { distributeAlgorithms, distributeAlgorithmsLegenda } from "../../../../Format JSON/DistributeAlgorithms";
import { UploadFile, openCloseUpload } from "../../../Sidebar/UploadFile/UploadFile";
import { SetTiles } from "../../../Sidebar/SetTiles/SetTiles";
import { DownloadClash } from "../../../Sidebar/DownloadClash/DownloadClash";
import * as turf from '@turf/turf';
import { Tutorial } from "../../../Sidebar/Tutorial/Tutorial";
import { Legend, fecharLegenda, iniciarLegenda } from "../../../Legenda/Legend";
import { RightButton } from "../../../RightButton/RightButton";
import { MapControl } from "../../../MapControl/MapControl";
import axios from "axios";
import IFC from "../../../RightButton/IFC";

// function download_file(fileURL, fileName) {
//     if (!window.ActiveXObject) {
//         var save = document.createElement('a');
//         save.href = fileURL;
//         save.target = '_blank';
//         var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
//         save.download = fileName || filename;
//         if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
//             document.location = save.href;
//             // window event not working here
//         } else {
//             var evt = new MouseEvent('click', {
//                 'view': window,
//                 'bubbles': true,
//                 'cancelable': false
//             });
//             save.dispatchEvent(evt);
//             (window.URL || window.webkitURL).revokeObjectURL(save.href);
//         }
//     }

//     else if (!!window.ActiveXObject && document.execCommand) {
//         var _window = window.open(fileURL, '_blank');
//         _window.document.close();
//         _window.document.execCommand('SaveAs', true, fileName || fileURL)
//         _window.close();
//     }
// }
// download_file("https://www.ufjf.br/peteletrica/files/2010/09/ApostilaMSProject-2008.pdf", "fileName.pdf");

var Layers, Polygons, PolygonsOffset, Points,
    Polylines, Lotes, changeTitulo, PolygonsJSON, geoemp,
    PolygonsOffsetJSON, PolylinesJSON, PointsJSON, geoempArray,
    LoteUnico, mouseoverAtivo, ids, layersSequence, distGeoemp,
    BufferDeck, bufferOn, Buildings, BuildingsOn, buildingsDeck,
    DistritosDeck, Distritos, Tiles, LoteBuffer, InterseccaoAtributo;

var initialViewState = {
    longitude: -46.6333,
    latitude: -23.6900,
    zIndex: 0,
    zoom: 10,
    maxZoom: 21,
    pitch: 0
};

var firstValue = true
var addNebula = false
var loteGenerate = true
var turnViewMode = true

async function geoempreendedorismo() {
    const geoempreendimento = {
        "name": "geoempreendimento",
        "type": "FeatureCollection",
        "features": []
    }
    await axios.get(`https://uzu2spnwitelgca-db202004101957.adb.sa-saopaulo-1.oraclecloudapps.com/ords/bim/pontos/demandas`).then((response) => {
        for (let i = 0; i < response.data.items.length; i++) {
            const props = response.data.items[i]
            let demandassAux = { "type": "Feature", "geometry": { "type": "Point", "coordinates": [props.longitude, props.latitude] }, "properties": props }
            demandassAux.properties.geoModo = "Demandas"
            demandassAux.properties.cor = [53, 201, 248]
            demandassAux.properties.layerActivated = true
            geoempreendimento.features.push(demandassAux)
        }
    });
    await axios.get(`https://uzu2spnwitelgca-db202004101957.adb.sa-saopaulo-1.oraclecloudapps.com/ords/bim/pontos/empreendimentos`).then((response) => {
        for (let i = 0; i < response.data.items.length; i++) {
            const props = response.data.items[i]
            let empreendimentosAux = { "type": "Feature", "geometry": { "type": "Point", "coordinates": [props.longitude, props.latitude] }, "properties": props }
            empreendimentosAux.properties.geoModo = "Empreendimentos"
            empreendimentosAux.properties.cor = [248, 53, 134]
            empreendimentosAux.properties.layerActivated = true
            geoempreendimento.features.push(empreendimentosAux)
        }
    });
    await axios.get(`https://uzu2spnwitelgca-db202004101957.adb.sa-saopaulo-1.oraclecloudapps.com/ords/bim/pontos/contratos`).then((response) => {
        for (let i = 0; i < response.data.items.length; i++) {
            const props = response.data.items[i]
            let contratosAux = { "type": "Feature", "geometry": { "type": "Point", "coordinates": [props.longitude, props.latitude] }, "properties": props }
            contratosAux.properties.geoModo = "Contratos"
            contratosAux.properties.cor = [255, 255, 0]
            contratosAux.properties.layerActivated = true
            geoempreendimento.features.push(contratosAux)
        }
    });
    distGeoemp = distributeAlgorithms(geoempreendimento, "geoModo")
}

geoempreendedorismo()
getDistritos()

export function zoomOut() {
    setZoomMap(getZoomMap() - 0.7)
    changeViewState(getLng() + 0.00000000001, getLat(), 100, getZoomMap())
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));

}

export function zoomIn() {
    setZoomMap(getZoomMap() + 0.7)
    changeViewState(getLng() + 0.00000000001, getLat(), 100, getZoomMap())
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));

}

export function fixOrientation2d() {
    changeViewState(getLng() + 0.00000000001, getLat(), 100, getZoomMap())
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));

}

export function fixOrientation3d() {
    changeViewState(getLng() + 0.00000000001, getLat(), 100, getZoomMap(), 90, 350)
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));

}

export function setDistritos(x) {
    Distritos = x
    console.log("Distritos funcionando!")
}

export function distritoActivated(x) {
    if (x === true) { cleanModals() }
    for (let i = 0; i < Distritos.features.length; i++) {
        Distritos.features[i].properties.layerActivated = x
    }
}

export function refreshDeck() {
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

export function BufferFunction(LayerBuffer) {
    BufferDeck = new GeoJsonLayer({
        id: 'bufferDeck',
        data: LayerBuffer,
        //Style
        stroked: false,
        filled: true,
        getLineColor: f => f.properties.RGBconverted,
        getLineWidth: 100,
        lineWidthMaxPixels: 40,
        opacity: 0.2,
        getFillColor: f => f.properties.RGBconverted,
        pickable: true,
    });
    bufferOn = true
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

export function changeViewState(long, lat, time, zoom, pitch) {
    initialViewState = {
        longitude: long,
        latitude: lat,
        transitionDuration: time,
        zIndex: 0,
        zoom: zoom,
        maxZoom: 21,
        pitch: pitch,
        bearing: 0
    };
}

export function desativarLayer(titulo) {
    try {
        if (PolygonsJSON !== undefined) {
            for (let j = 0; j < PolygonsJSON.length; j++) {
                for (let i = 0; i < PolygonsJSON[j].features.length; i++) {
                    if (titulo === PolygonsJSON[j].features[i].properties.titulo) {
                        PolygonsJSON[j].features[i].properties.layerActivated = !PolygonsJSON[j].features[i].properties.layerActivated
                    }
                }
            }
        }
        if (PolygonsOffsetJSON !== undefined) {
            for (let j = 0; j < PolygonsOffsetJSON.length; j++) {
                for (let i = 0; i < PolygonsOffsetJSON[j].features.length; i++) {
                    if (titulo === PolygonsOffsetJSON[j].features[i].properties.titulo) {
                        PolygonsOffsetJSON[j].features[i].properties.layerActivated = !PolygonsOffsetJSON[j].features[i].properties.layerActivated
                    }
                }
            }
        }
        if (PolylinesJSON !== undefined) {
            for (let j = 0; j < PolylinesJSON.length; j++) {
                for (let i = 0; i < PolylinesJSON[j].features.length; i++) {
                    if (titulo === PolylinesJSON[j].features[i].properties.titulo) {
                        PolylinesJSON[j].features[i].properties.layerActivated = !PolylinesJSON[j].features[i].properties.layerActivated
                    }
                }
            }
        }
        if (PointsJSON !== undefined) {
            for (let j = 0; j < PointsJSON.length; j++) {
                for (let i = 0; i < PointsJSON[j].features.length; i++) {
                    if (titulo === PointsJSON[j].features[i].properties.titulo) {
                        PointsJSON[j].features[i].properties.layerActivated = !PointsJSON[j].features[i].properties.layerActivated
                    }
                }
            }
        }
        if (distGeoemp !== undefined) {
            for (let j = 0; j < distGeoemp.length; j++) {
                if (titulo === distGeoemp[j].name) {
                    for (let i = 0; i < distGeoemp[j].features.length; i++) {
                        distGeoemp[j].features[i].properties.layerActivated = !distGeoemp[j].features[i].properties.layerActivated
                    }
                }
            }
        }
        changeLayers(Layers, LoteUnico, PolygonsJSON, PolygonsOffsetJSON, PointsJSON, PolylinesJSON, InterseccaoAtributo)
    } catch (e) {
        console.log("desativarLayer", e)
    }
}

export function desativarLayerDeck() {
    try {
        if (PolygonsJSON !== undefined) {
            for (let j = 0; j < PolygonsJSON.length; j++) {
                for (let i = 0; i < PolygonsJSON[j].features.length; i++) {
                    PolygonsJSON[j].features[i].properties.layerActivated = false
                }
            }
        }
        if (PolygonsOffsetJSON !== undefined) {
            for (let j = 0; j < PolygonsOffsetJSON.length; j++) {
                for (let i = 0; i < PolygonsOffsetJSON[j].features.length; i++) {
                    PolygonsOffsetJSON[j].features[i].properties.layerActivated = false
                }
            }
        }
        if (PolylinesJSON !== undefined) {
            for (let j = 0; j < PolylinesJSON.length; j++) {
                for (let i = 0; i < PolylinesJSON[j].features.length; i++) {
                    PolylinesJSON[j].features[i].properties.layerActivated = false
                }
            }
        }
        if (PointsJSON !== undefined) {
            for (let j = 0; j < PointsJSON.length; j++) {
                for (let i = 0; i < PointsJSON[j].features.length; i++) {
                    PointsJSON[j].features[i].properties.layerActivated = false
                }
            }
        }
        if (distGeoemp !== undefined) {
            for (let j = 0; j < distGeoemp.length; j++) {
                for (let i = 0; i < distGeoemp[j].features.length; i++) {
                    distGeoemp[j].features[i].properties.layerActivated = false
                }
            }
        }
        changeLayers(Layers, LoteUnico, PolygonsJSON, PolygonsOffsetJSON, PointsJSON, PolylinesJSON, InterseccaoAtributo)
    } catch (e) {
        console.log("desativarLayerDeck", e)
    }
}

export function ativarLayerDeck() {
    try {
        if (PolygonsJSON !== undefined) {
            for (let j = 0; j < PolygonsJSON.length; j++) {
                for (let i = 0; i < PolygonsJSON[j].features.length; i++) {
                    PolygonsJSON[j].features[i].properties.layerActivated = true
                }
            }
        }
        if (PolygonsOffsetJSON !== undefined) {
            for (let j = 0; j < PolygonsOffsetJSON.length; j++) {
                for (let i = 0; i < PolygonsOffsetJSON[j].features.length; i++) {
                    PolygonsOffsetJSON[j].features[i].properties.layerActivated = true
                }
            }
        }
        if (PolylinesJSON !== undefined) {
            for (let j = 0; j < PolylinesJSON.length; j++) {
                for (let i = 0; i < PolylinesJSON[j].features.length; i++) {
                    PolylinesJSON[j].features[i].properties.layerActivated = true
                }
            }
        }
        if (PointsJSON !== undefined) {
            for (let j = 0; j < PointsJSON.length; j++) {
                for (let i = 0; i < PointsJSON[j].features.length; i++) {
                    PointsJSON[j].features[i].properties.layerActivated = true
                }
            }
        }
        if (distGeoemp !== undefined) {
            for (let j = 0; j < distGeoemp.length; j++) {
                for (let i = 0; i < distGeoemp[j].features.length; i++) {
                    distGeoemp[j].features[i].properties.layerActivated = true
                }
            }
        }
        changeLayers(Layers, LoteUnico, PolygonsJSON, PolygonsOffsetJSON, PointsJSON, PolylinesJSON, InterseccaoAtributo)
    } catch (e) {
        console.log("ativarLayer", e)
    }
}

export function mouseoverLayer(titulo) {
    try {
        if (changeTitulo !== titulo) {
            changeTitulo = titulo
            if (PolygonsJSON !== undefined) {
                for (let j = 0; j < PolygonsJSON.length; j++) {
                    if (titulo === PolygonsJSON[j].features[0].properties.titulo) {
                        const vetorAuxiliar = PolygonsJSON[j]
                        if (vetorAuxiliar !== undefined) {
                            PolygonsJSON.push(PolygonsJSON.splice(PolygonsJSON.indexOf(vetorAuxiliar), 1)[0]);
                            mouseoverAtivo = true
                        }
                    }
                }
            }
            if (PolygonsOffsetJSON !== undefined) {
                for (let j = 0; j < PolygonsOffsetJSON.length; j++) {
                    if (titulo === PolygonsOffsetJSON[j].features[0].properties.titulo) {
                        const vetorAuxiliar1 = PolygonsOffsetJSON[j]
                        if (vetorAuxiliar1 !== undefined) {
                            PolygonsOffsetJSON.push(PolygonsOffsetJSON.splice(PolygonsOffsetJSON.indexOf(vetorAuxiliar1), 1)[0]);
                            mouseoverAtivo = false
                        }
                    }
                }
            }
            if (PolylinesJSON !== undefined) {
                for (let j = 0; j < PolylinesJSON.length; j++) {
                    if (titulo === PolylinesJSON[j].features[0].properties.titulo) {
                        const vetorAuxiliar2 = PolylinesJSON[j]
                        if (vetorAuxiliar2 !== undefined) {
                            PolylinesJSON.push(PolylinesJSON.splice(PolylinesJSON.indexOf(vetorAuxiliar2), 1)[0]);
                            mouseoverAtivo = false
                        }
                    }
                }
            }
            changeLayers(Layers, LoteUnico, PolygonsJSON, PolygonsOffsetJSON, PointsJSON, PolylinesJSON, InterseccaoAtributo)
        }
    } catch (e) {
        console.log("mouseoverLayer", e)
    }
}

export function setTilesFunction(tiles) {
    if (tiles === 'https://c.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png') {
        setBuildingsView(true)
    } else { setBuildingsView(false) }
    if (buildingsDeck !== undefined) { createBuildings(buildingsDeck) }
    Tiles = new TileLayer({
        id: "Tiles",
        data: tiles,
        maxRequests: 20,
        pickable: true,
        onViewportLoad: null,
        autoHighlight: false,
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        onHover: () => {
            sumirBuffer()
            if (getFixedPopup() === false) {
                changeSelectOrRotate(null)
            }
            if (getDistritoAberto()) {
                changeSelectOrRotate(null)
            }
        },
        onClick: (info) => {
            getLote(info, loteGenerate);
            setisNebula(false)
            mouseoverAtivo = false
        },
        renderSubLayers: props => {
            const {
                bbox: { west, south, east, north }
            } = props.tile;

            return [
                new BitmapLayer(props, {
                    data: null,
                    image: props.data,
                    bounds: [west, south, east, north]
                }),
                new PathLayer({
                    id: `${props.id}-border`,
                    visible: props.visible,
                    data: [
                        [
                            [west, north],
                            [west, south],
                            [east, south],
                            [east, north],
                            [west, north]
                        ]
                    ],
                    getPath: d => d,
                    getColor: [255, 0, 0],
                    widthMinPixels: 4,
                })
            ];
        }
    });
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

setTilesFunction('https://c.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png')

export function changeLotes() {
    firstValue = fecharLegenda()
    Lotes = new GeoJsonLayer({
        id: 'Lotes',
        data: getMultiLote(),
        // Styles
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        opacity: 0.9,
        getLineColor: [0, 0, 255],
        getFillColor: [173, 216, 230],
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 100],
        onHover: (f) => {
            if (f.object !== undefined) {
                changeSelectOrRotate(f)
            }
            // if (getFixedPopup() === false) {
            //     changeSelectOrRotate(null)
            // }
        },
        onClick: (info) => { getRisks(info) }
    });

    Polygons = []
    PolygonsOffset = []
    Polylines = []
    geoempArray = []
    Points = []
    LoteBuffer = []
    setBufferLote(undefined)

    ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

export function cleanAllLayers() {
    Polygons = []
    PolygonsOffset = []
    Polylines = []
    geoempArray = []
    Points = []
    Lotes = []
    LoteBuffer = []
    setBufferLote(undefined)

    ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

export function changeLayers(layers, loteUnico, PolygonsLayer, PolygonsOffsetLayer, PointsLayer, PolylinesLayer, interseccaoAtributo) {
    Polygons = []
    PolygonsOffset = []
    Polylines = []
    geoempArray = []
    Points = []
    Lotes = []
    LoteBuffer = []

    PolygonsJSON = PolygonsLayer
    PolygonsOffsetJSON = PolygonsOffsetLayer
    PolylinesJSON = PolylinesLayer
    LoteUnico = loteUnico
    Layers = layers
    InterseccaoAtributo = interseccaoAtributo

    PointsJSON = PointsLayer
    addNebula = false
    try {
        DistritosDeck = new GeoJsonLayer({
            id: "Distritos",
            data: Distritos,
            // Styles
            visible: Distritos.features[0].properties.layerActivated,
            stroked: true,
            filled: true,
            getLineWidth: 5,
            lineWidthMinPixels: 2,
            opacity: 1,
            getLineColor: [100, 100, 100, 200],
            getFillColor: [220, 220, 220],
            pickable: true,
            autoHighlight: true,
            highlightColor: [150, 150, 150],
            onClick: (info) => {
                changeViewState(info.coordinate[0], info.coordinate[1], 500, 13)
                distritoActivated(false)
                cleanSidebarIcons(); SetDistritoAberto(false)
                changeLayers(); changeSelectOrRotate(null)
            }
        })
    } catch (error) { console.log(error) }


    let loteData;
    if (LoteUnico !== undefined) {
        if (LoteUnico.object !== undefined) {
            loteData = LoteUnico.object
        } else {
            loteData = LoteUnico
        }
    }
    if (BuildingsOn) {
        Lotes = new GeoJsonLayer({
            id: 'Lotes',
            data: loteData,
            // Styles
            stroked: true,
            filled: true,
            lineWidthMinPixels: 2,
            opacity: 0.2,
            extruded: true,
            getElevation: getMeters(),
            getLineColor: [0, 0, 255],
            getFillColor: [70, 216, 250],
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 100],
            onHover: (f) => {
                if (f.object !== undefined) {
                    changeSelectOrRotate(f)
                }
                console.log("pssei o mouse aqui")
                // if (getFixedPopup() === false) {
                //     changeSelectOrRotate(null)
                // }
            },
            onClick: (info) => { getRisks(info, true) },
        });

        if (PointsLayer !== undefined) {
            for (let i = 0; i < PointsLayer.length; i++) {
                if (PointsLayer[i].features.length !== 0) {
                    const PointsDeck = new IconLayer({
                        id: PointsLayer[i].features[0].properties.id,
                        data: PointsLayer[i].features,
                        // Styles
                        visible: PointsJSON[i].features[0].properties.layerActivated,
                        pickable: true,
                        getIcon: d => ({ url: `${baseUrlApi()}` + d.icone, width: 128, height: 128, anchorY: 220 }),
                        onIconError: null,
                        // getPixelOffset: [0, 15],
                        // getPixelOffset: [0, 40],
                        sizeMaxPixels: 200,
                        sizeMinPixels: 0,
                        sizeUnits: 'pixels',
                        sizeScale: 7,
                        getPosition: d => d.geometry.coordinates[0],
                        getSize: 5,
                        autoHighlight: true,
                        highlightColor: [0, 0, 0],
                        onHover: (info) => {
                            createBuffer(info.object);
                            if (info.object !== undefined) {
                                mouseoverLayer(info.object.properties.titulo);
                                if (getFixedPopup() === false) {
                                    openPopup(info)
                                }
                            }
                        },
                        onClick: () => setFixedPopup(true),
                    });
                    Points.push(PointsDeck)
                }
            }
        }
    } else {
        Lotes = new GeoJsonLayer({
            id: 'Lotes',
            data: loteData,
            // Styles
            stroked: true,
            filled: true,
            lineWidthMinPixels: 2,
            opacity: 0.9,
            getLineColor: [0, 0, 255],
            getFillColor: [173, 216, 230],
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 100],
            onHover: (f) => {
                sumirBuffer()
                if (f.object !== undefined) {
                    if (f.object.properties.quadra) {
                        changeSelectOrRotate(f)
                    } else {
                        changeSelectOrRotate(null)
                    }
                }
                // if (getFixedPopup() === false) {
                //     changeSelectOrRotate(null)
                // }
            },
            onClick: (info) => { getRisks(info) }
        });

        if (PointsLayer !== undefined) {
            for (let i = 0; i < PointsLayer.length; i++) {
                if (PointsLayer[i].features.length !== 0) {
                    // for (let j = 0; j < PointsJSON[i].features.length; j++) {
                    //     console.log("ponto " + j + ": ", PointsJSON[i].features[j].geometry.coordinates)
                    // }
                    const PointsDeck = new IconLayer({
                        id: PointsLayer[i].features[0].properties.id,
                        data: PointsLayer[i].features,
                        // Styles
                        visible: PointsJSON[i].features[0].properties.layerActivated,
                        pickable: true,
                        getIcon: d => ({ url: `${baseUrlApi()}` + d.icone, width: 128, height: 128, anchorY: 220 }),
                        onIconError: null,
                        getPixelOffset: [0, 30],
                        sizeMaxPixels: 200,
                        sizeMinPixels: 0,
                        sizeUnits: 'pixels',
                        sizeScale: 7,
                        getPosition: d => d.geometry.coordinates[0],
                        getSize: 5,
                        autoHighlight: true,
                        highlightColor: [0, 0, 0],
                        onHover: (info) => {
                            createBuffer(info.object);
                            if (info.object !== undefined) {
                                // inicio distance
                                // var from = turf.point(loteData.geometry.coordinates[0][0][0]);
                                // var to = turf.point(info.object.coordinates);
                                // var options = { units: 'meters' };
                                // const turfDistance = turf.distance(from, to, options)
                                // console.log("turfDistance", parseInt(turfDistance))
                                // fim distance
                                mouseoverLayer(info.object.properties.titulo);
                                if (getFixedPopup() === false) {
                                    openPopup(info)
                                }
                            }
                        },
                        onClick: () => setFixedPopup(true),
                    });
                    Points.push(PointsDeck)
                }
            }
        }
    }

    if (PolygonsLayer !== undefined) {
        for (let i = 0; i < PolygonsLayer.length; i++) {
            if (PolygonsLayer[i].features.length !== 0) {
                const PolygonsDeck = new GeoJsonLayer({
                    id: "Polygons" + [i],
                    data: PolygonsLayer[i],
                    // Styles
                    visible: PolygonsLayer[i].features[0].properties.layerActivated,
                    stroked: true,
                    filled: true,
                    extruded: false,
                    lineWidthMinPixels: 2,
                    opacity: 0.4,
                    getLineColor: [255, 255, 255],
                    getFillColor: f => f.properties.RGBconverted,
                    pickable: true,
                    autoHighlight: true,
                    highlightColor: f => f.object.properties.RGBconverted,
                    onHover: (info) => {
                        sumirBuffer();
                        if (info.object !== undefined) {
                            mouseoverLayer(info.object.properties.titulo);
                            if (getFixedPopup() === false) {
                                openPopup(info)
                            }
                        }
                    },
                    onClick: () => setFixedPopup(true),
                })
                Polygons.push(PolygonsDeck)
            }
        }
    }
    if (PolygonsOffsetLayer !== undefined) {
        for (let i = 0; i < PolygonsOffsetLayer.length; i++) {
            if (PolygonsOffsetLayer[i].features.length !== 0) {
                const PolygonsOffsetDeck = new GeoJsonLayer({
                    id: "PolygonsOffset" + [i],
                    data: PolygonsOffsetLayer[i],
                    // Styles
                    visible: PolygonsOffsetLayer[i].features[0].properties.layerActivated,
                    stroked: true,
                    filled: true,
                    extruded: false,
                    lineWidthMinPixels: 2,
                    opacity: 0.4,
                    getLineColor: [255, 255, 255],
                    getFillColor: f => f.properties.RGBconverted,
                    pickable: true,
                    autoHighlight: true,
                    highlightColor: f => f.object.properties.RGBconverted,
                    onHover: (info) => {
                        createBuffer(info.object);
                        if (info.object !== undefined) {
                            mouseoverLayer(info.object.properties.titulo);
                            if (getFixedPopup() === false) {
                                openPopup(info)
                            }
                        }
                    },
                    onClick: () => setFixedPopup(true),
                })
                PolygonsOffset.push(PolygonsOffsetDeck)
            }
        }
    }

    if (PolylinesLayer !== undefined) {
        for (let i = 0; i < PolylinesLayer.length; i++) {
            if (PolylinesLayer[i].features.length !== 0) {
                const polylineLayerDeck = new GeoJsonLayer({
                    id: "Polylines" + [i],
                    data: PolylinesLayer[i],
                    // Styles
                    visible: PolylinesLayer[i].features[0].properties.layerActivated,
                    stroked: true,
                    filled: true,
                    extruded: false,
                    getLineColor: f => f.properties.RGBconverted,
                    getLineWidth: 5,
                    lineWidthMinPixels: 2,
                    opacity: 0.5,
                    getFillColor: f => f.properties.RGBconverted,
                    pickable: true,
                    autoHighlight: true,
                    highlightColor: f => f.object.properties.RGBconverted,
                    onHover: (info) => {
                        createBuffer(info.object);
                        if (info.object !== undefined) {
                            mouseoverLayer(info.object.properties.titulo);
                            if (getFixedPopup() === false) {
                                openPopup(info)
                            }
                        }
                    },
                    onClick: () => setFixedPopup(true),
                })
                Polylines.push(polylineLayerDeck)
            }
        }
    }

    if (getBufferLote() !== undefined) {
        const buffered = turf.buffer(getBufferLote(), 500, { units: 'meters' });

        LoteBuffer = new GeoJsonLayer({
            id: 'LoteBuffer',
            data: buffered,
            // Styles
            stroked: true,
            filled: false,
            // getLineWidth: 5,
            lineWidthMinPixels: 2,
            opacity: 0.6,
            getLineColor: [0, 0, 0],
            getFillColor: [0, 0, 0, 0],
            pickable: false,
            autoHighlight: false
        });
    }
    geoemp = undefined
    if (distGeoemp !== undefined && BuildingsOn !== true) {
        try {
            const buffer = turf.buffer(getBufferLote(), 500, { units: 'meters' });
            const geoempreendimento = {
                "name": "geoempreendimento",
                "type": "FeatureCollection",
                "features": []
            }
            for (let i = 0; i < distGeoemp.length; i++) {
                for (let j = 0; j < distGeoemp[i].features.length; j++) {
                    const feature = distGeoemp[i].features[j];
                    const isInsideBuffer = turf.booleanWithin(feature, buffer);

                    if (isInsideBuffer) { geoempreendimento.features.push(feature) }
                }
            }
            geoemp = distributeAlgorithms(geoempreendimento, "geoModo")
            for (let i = 0; i < geoemp.length; i++) {
                if (geoemp[i].features.length !== 0) {
                    const polylineLayerDeck = new GeoJsonLayer({
                        id: "geoemp" + [i],
                        data: geoemp[i],
                        // Styles
                        visible: geoemp[i].features[0].properties.layerActivated,
                        stroked: true,
                        filled: true,
                        extruded: false,
                        getLineWidth: 30,
                        // lineWidthMinPixels: 5,
                        opacity: 0.5,
                        getLineColor: f => f.properties.cor,
                        getFillColor: f => f.properties.cor,
                        pickable: true,
                        autoHighlight: true,
                        highlightColor: f => f.object.properties.cor,
                        onHover: (info) => {
                            if (info.object !== undefined) {
                                console.log('info.object', info.object.properties)
                                if (getFixedPopup() === false) {
                                    openPopup(info)
                                }
                            }
                        },
                        onClick: () => setFixedPopup(true),
                    })
                    geoempArray.push(polylineLayerDeck)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    if (firstValue) {
        if (BuildingsOn !== true) {
            firstValue = iniciarLegenda(Layers, firstValue, geoemp)
        } else {

            // firstValue = iniciarLegenda(Layers, firstValue, [])
        }
    }

    turnViewMode = true
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

export function changeMeters(meters) {
    createBuildings(buildingsDeck, meters)
}

export function createBuildings(edificacao, meters) {
    buildingsDeck = edificacao
    let loteData;
    if (LoteUnico === undefined) {
        LoteUnico = getLotes()
    }
    if (LoteUnico.object !== undefined) {
        loteData = LoteUnico.object
    } else {
        loteData = LoteUnico
    }

    let ed_altura = 0;
    if (meters === undefined) {
        for (let i = 0; i < edificacao.features.length; i++) {
            const hasIntersection = turf.booleanIntersects(loteData, edificacao.features[i])
            if (hasIntersection && ed_altura < edificacao.features[i].properties.ed_altura) {
                ed_altura = edificacao.features[i].properties.ed_altura
            }
        }
    } else {
        ed_altura = parseInt(meters)
    }
    setMeters(ed_altura)

    Lotes = new GeoJsonLayer({
        id: 'Lotes',
        data: loteData,
        // Styles
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        opacity: 0.2,
        extruded: true,
        getElevation: ed_altura,
        getLineColor: [0, 0, 255],
        getFillColor: [70, 216, 250],
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 100],
        onHover: (f) => {
            if (f.object !== undefined) {
                changeSelectOrRotate(f)
            }
            // if (getFixedPopup() === false) {
            //     changeSelectOrRotate(null)
            // }
        },
        onClick: (info) => { getRisks(info, true) },
    });

    Buildings = new GeoJsonLayer({
        id: 'buildings',
        data: edificacao,
        // Styles
        opacity: 1,
        filled: true,
        extruded: true,
        visible: getBuildingsView(),
        wireframe: true,
        fp64: true,
        getElevation: f => f.properties.ed_altura,
        getLineColor: [0, 0, 0],
        pointRadiusMinPixels: 2,
        pointRadiusScale: 2000,
        getFillColor: [216, 217, 217],
    });
    BuildingsOn = true
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

function sumirBuffer() {
    // bufferOn = false
    setBufferLimiter(false)
    BufferDeck = undefined
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

export function StopDraw() {
    if (document.getElementById('drawPolygonButton').classList[1] === "ativo") {
        $("#drawPolygonButton").click();
    }
    turnViewMode = true
    ReactDOM.render(<DeckHUB />, document.getElementById('root'));
}

$(document).keydown(function (e) {
    if (e.key === "Escape") {
        if (document.getElementById('drawPolygonButton').classList[1] === "ativo") {
            $("#drawPolygonButton").click();
        }
        turnViewMode = true
        ReactDOM.render(<DeckHUB />, document.getElementById('root'));
    }
});

function DeckHUB() {
    async function changeLotesNebula(Data) {
        await axios.get(`${baseUrlApi()}getLoteInfo?lote=${Data}`).then((response) => {
            setNebulaGeojsonInfo(response.data)
        });
        // for (let i = 0; i < getNebulaGeojsonInfo().length; i++) {
        //     let setor = 'setor'
        //     let quadra = 'quadra'
        //     let lote = 'lote'
        //     let porcentagem = 'porcentagem'
        //     let area_interseccao = 'area_interseccao'
        //     let area_total_lote = 'area_total_lote'
        //     let area_total_poligono = 'area_total_poligono'
        //     Data.properties[setor] = getNebulaGeojsonInfo()[i].setor
        //     Data.properties[quadra] = getNebulaGeojsonInfo()[i].quadra
        //     Data.properties[lote] = getNebulaGeojsonInfo()[i].lote
        //     Data.properties[porcentagem] = getNebulaGeojsonInfo()[i].porcentagem
        //     Data.properties[area_interseccao] = getNebulaGeojsonInfo()[i].area_interseccao
        //     Data.properties[area_total_lote] = getNebulaGeojsonInfo()[i].area_total_lote
        //     Data.properties[area_total_poligono] = getNebulaGeojsonInfo()[i].area_total_poligono
        //     Data.properties.nebula = true
        // }
        if (Data !== undefined) {
            Lotes = new GeoJsonLayer({
                id: 'Lotes',
                data: Data,
                // Styles
                stroked: true,
                filled: true,
                lineWidthMinPixels: 2,
                opacity: 0.9,
                getLineColor: [0, 0, 255],
                getFillColor: [173, 216, 230],
                pickable: true,
                autoHighlight: true,
                highlightColor: [0, 0, 0, 100],
                // onHover: (f) => {
                //     if (f.object !== undefined) {
                //         changeSelectOrRotate(f)
                //     }
                //     // if (getFixedPopup() === false) {
                //     //     changeSelectOrRotate(null)
                //     // }
                // },
                onClick: (info) => { getRisks(info, true) },
            });
            if (Data.length !== 0) { Polygons = []; PolygonsOffset = []; Polylines = []; geoempArray = []; Points = [] }
        }
    }
    const [mode, setMode] = React.useState(() => ViewMode);
    const [features, setFeatures] = React.useState({
        type: "FeatureCollection",
        features: []
    });

    useEffect(() => {
        if (features.features.length !== 0) {
            setLotes(features)
            const nebulaLayer = bufferJSONDeckNebula(features.features[0], features.features[0].geometry.coordinates)
            setBufferLote(nebulaLayer)
            changeLotesNebula(nebulaLayer); NebulaDisable()
        }
    }, [features])

    const [selectedFeatureIndexes] = React.useState([0]);

    const NebulaPolygon = new EditableGeoJsonLayer({
        id: 'NebulaPolygon',
        data: features,
        mode,
        selectedFeatureIndexes,
        // Styles
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        opacity: 0.9,
        getTentativeLineColor: [0, 0, 255],
        getTentativeFillColor: [173, 216, 230, 200],
        getEditHandlePointColor: [0, 0, 255],
        getEditHandlePointRadius: [0, 0, 255],
        getEditHandlePointOutlineColor: [0, 0, 255],
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 100],
        onEdit: ({ updatedData }) => {
            setFeatures(updatedData);
        }
    });

    if (addNebula) {
        layersSequence = [Tiles, Polygons, Polylines, geoempArray, PolygonsOffset, Lotes, LoteBuffer, Points, NebulaPolygon, DistritosDeck]
    } else if (mouseoverAtivo) {
        layersSequence = [Tiles, Polylines, geoempArray, PolygonsOffset, Polygons, BufferDeck, Buildings, Lotes, LoteBuffer, Points, DistritosDeck];
        //  mouseoverAtivo = false
    } else if (!BuildingsOn && bufferOn) {
        layersSequence = [Tiles, Polygons, BufferDeck, Polylines, geoempArray, PolygonsOffset, Lotes, LoteBuffer, Points, DistritosDeck]
    } else if (BuildingsOn && bufferOn) {
        layersSequence = [Tiles, Polygons, BufferDeck, Polylines, geoempArray, PolygonsOffset, Buildings, Lotes, LoteBuffer, Points, DistritosDeck]
    } else if (BuildingsOn && !bufferOn) {
        layersSequence = [Tiles, Polygons, Polylines, geoempArray, PolygonsOffset, Buildings, Lotes, LoteBuffer, Points, DistritosDeck]
    } else {
        layersSequence = [Tiles, Polygons, Polylines, geoempArray, PolygonsOffset, Lotes, LoteBuffer, Points, DistritosDeck]
    }

    const DrawLine = () => {
        setMode(() => DrawLineStringMode)
        setClashValidator(false)
        loteGenerate = false; addNebula = true; Polygons = []; PolygonsOffset = []; Polylines = []; geoempArray = []; Points = []; Lotes = []; turnViewMode = false; LoteBuffer = []
        setBufferLote(undefined)
        firstValue = fecharLegenda()
        setFeatures({
            type: "FeatureCollection",
            features: []
        })
    }
    const DrawPolygon = () => {
        setMode(() => DrawPolygonMode)
        setClashValidator(false)
        loteGenerate = false; addNebula = true; Polygons = []; PolygonsOffset = []; Polylines = []; geoempArray = []; Points = []; Lotes = []; turnViewMode = false; LoteBuffer = []
        setBufferLote(undefined)
        firstValue = fecharLegenda()
        setFeatures({
            type: "FeatureCollection",
            features: []
        })
    }
    const NebulaDisable = () => {
        setMode(() => ViewMode)
        loteGenerate = true; addNebula = false
        setFeatures({
            type: "FeatureCollection",
            features: []
        })
    }

    if (turnViewMode) { NebulaDisable(); turnViewMode = false }

    return (
        <>
            <div id="NebulaCentral" className="NebulaCentral">
                <div className="controlNebula">
                    <h3>Escolha o modo <br /> de Desenho</h3>
                    <ul>
                        <li
                            onClick={DrawLine}
                            style={{ background: mode === DrawLineStringMode ? "#0059b8" : null }}
                        > Linha </li>
                        <li
                            onClick={DrawPolygon}
                            style={{ background: mode === DrawPolygonMode ? "#0059b8" : null }}
                        > Polígono </li>
                        <li
                            onClick={NebulaDisable}
                            style={{ background: mode === ViewMode ? "#0059b8" : null }}
                        > Visualização </li>
                    </ul>
                </div>
            </div>
            <LoadingScreen />
            <DeckComponent
                loteGenerate={loteGenerate}
                initialViewState={initialViewState}
                mapLayers={layersSequence} />
            <Legend />
            <Tipologia />
            <Planilha />
            <Report />
            <Tutorial />
            <UploadFile />
            <SetTiles />
            <RightButton />
            <DownloadClash />
            <MapControl />
            <IFC />
            {/* <PopupInfo /> */}
            <button id="uploadButtonid" onClick={() => openCloseUpload()} className="btn btn-primary buttonDeck"> Sair do modo Upload </button>
            <button id="buttonDeckid" className="btn btn-primary buttonDeck"> Sair do modo Clash </button>
        </>
    )
}

export function changeClashDisplay(finalClash) {
    searchSide.$children[0].displayClash(finalClash)
}

export function changeLegend(layer) {
    firstValue = fecharLegenda()
    setTimeout(() => {
        // searchSide.$children[0].displayClash(finalClash)
        firstValue = iniciarLegenda(layer, firstValue, [])
    }, 300);
}

export function executaAlgoritmo(finalClash) {
    console.log("teste", finalClash)
    ids = getIds()
    //setBufferLote(undefined)
    var legendaObject = [{
        "name": "layer",
        "type": "FeatureCollection",
        "features": []
    }]
    changeSelectOrRotate(null)
    let LegendaEstatica = []
    PolylinesJSON = selectIDs(PolylinesJSON, ids)
    PolygonsJSON = selectIDs(PolygonsJSON, ids)
    PolygonsOffsetJSON = selectIDs(PolygonsOffsetJSON, ids)
    PointsJSON = selectIDs(PointsJSON, ids)
    if (getMultiClash() === true) {
        setClashFilter(getSelectedID(), PolygonsJSON, PolylinesJSON, PolygonsOffsetJSON, PointsJSON, InterseccaoAtributo, getLotes())
    }
    function selectIDs(layer, ids) {
        if (layer !== undefined) {
            let geojson = [{
                "name": "layer",
                "type": "FeatureCollection",
                "features": []
            }]
            for (let j = 0; j < layer.length; j++) {
                for (let i = 0; i < layer[j].features.length; i++) {
                    layer[j].features[i].properties.layerActivated = true
                    if (ids.includes(layer[j].features[i].properties.id)) {
                        geojson[0].features.push(layer[j].features[i])
                        legendaObject[0].features.push(layer[j].features[i])
                    }
                }
            }
            geojson = distributeAlgorithmsLegenda(geojson, "titulo")
            return geojson
        }
        return layer
    }
    legendaObject = distributeAlgorithmsLegenda(legendaObject, "titulo")
    LegendaEstatica.push(legendaObject)

    document.getElementById("textSide").style.display = "none";
    document.getElementById("showSide").style.display = "block";
    document.getElementById("positionDownload").classList.add("active")
    document.getElementById("buttonDeckid").style.display = "block"
    document.getElementById("uploadButtonid").style.display = "none"
    document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);

    changeLayers(Layers, LoteUnico, PolygonsJSON, PolygonsOffsetJSON, PointsJSON, PolylinesJSON, InterseccaoAtributo)
    changeSelectOrRotate(true)
    firstValue = fecharLegenda()

    setTimeout(() => {
        searchSide.$children[0].displayClash(finalClash)
        firstValue = iniciarLegenda(LegendaEstatica, firstValue, [])
        ReactDOM.render(<DeckHUB />, document.getElementById('root'));
    }, 300);
    abrirSidebar()

    $("#buttonDeckid").click(function () {
        if (getMultiClash() === true) {
            closeClashAux()
            cleanClashs()
            for (let i = 0; i < getMultiLote().features.length; i++) {
                getMultiLote().features[i].properties.isSelected = false
                getMultiLote().features[i].properties.isClicked = false
            }
            SetMultiLote(getMultiLote())
            document.getElementById("positionDownload").classList.remove("active")
            document.getElementById("buttonDeckid").style.display = "none"
            document.getElementById("uploadButtonid").style.display = "block"
            changeLotes()
            setClashValidator(false)
        } else {
            closeClashAux(); cleanSidebarIcons(); cleanClashs()
        }
    })
    $("#drawPolygonButton").click(function () {
        if (getMultiClash() === true) {
            openCloseUpload()
        } else {
            closeClashAux(); cleanSidebarIcons(); cleanClashs()
        }
    })
}
export function closeClashAux() {
    document.getElementById("NorthCompass").style.opacity = 1
    document.getElementById("orientation").style.pointerEvents = "none"
    document.getElementById("orientation").style.opacity = 0
    changeViewState(Tiles.state.tileset._viewport.longitude, Tiles.state.tileset._viewport.latitude, 500, Tiles.state.tileset._viewport.zoom)
    Buildings = []; BuildingsOn = false; buildingsDeck = undefined
    firstValue = fecharLegenda()
    closeClash()
}

export default DeckHUB