import React, { useEffect, useState } from "react";
import { baseUrlApi, setOrientation } from "../../DataHandler/DataHandler.jsx";
import { fixOrientation2d, fixOrientation3d, zoomIn, zoomOut } from "../Deck/Deck Components/Organizar Layers/DeckPrepare.jsx";
import "./MapControl.css"

var changeMap = false

export function firstFilter() {
    changeMap = true
}

export function MapControl() {
    const [icone, SetIcone] = useState("fal fa-globe")

    useEffect(() => {
        if (changeMap) {
            setOrientation(true)
            SetIcone("fal fa-map")
        }
    }, [changeMap])


    const callZoomOut = () => {
        zoomOut()
    }

    const callZoomIn = () => {
        zoomIn()
    }

    const callFixOrientation = () => {
        if (icone === "fal fa-globe") {
            setOrientation(true)
            SetIcone("fal fa-map")
            fixOrientation3d()
        } else {
            setOrientation(false)
            SetIcone("fal fa-globe")
            fixOrientation2d()
        }
    }

    return (
        <>
            <div id="zoomPanel" className="zoomPanel">
                <div onClick={callZoomOut} className="zoomControlMinus" title="Zoom Out">
                    <div className="minusSymbol">-</div>
                </div>
                <div onClick={callFixOrientation} id="orientation" className="orientation" title="Ajustar visualização do mapa">
                    <i className={icone}></i>
                </div>
                <div onClick={callZoomIn} className="zoomControlPlus" title="Zoom In">
                    <div className="plusSymbol">+</div>
                </div>
            </div>
            <img id="NorthCompass" src={`${baseUrlApi()}images?name=northCompass.png`} alt="" />
        </>
    )
}