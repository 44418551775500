var jsonMARKER;
var obj = {};

export function getIndex(json, algoritmo) {
    for (let i = 0, len = json.length; i < len; i++) {
        const name = json[i].name
        if (name === algoritmo) {
            return i
        }
    }
    return null
}

export function redistribuirIcones(layer) {
    try {
        jsonMARKER = []
        for (let i = 0; i < layer.features.length; i++) {
            obj = {
                "type": "Feature",
                "properties": layer.features[i].properties,
                "icone": layer.features[i].properties.icone,
                "coordinates": [
                    layer.features[i].geometry.coordinates[0][0],
                    layer.features[i].geometry.coordinates[0][1]
                ],
                "geometry": layer.features[i].geometry
            }

            jsonMARKER.push(obj)
        }
        return jsonMARKER;
    } catch (e) { console.log(e) }
}
export function getColor(color) {
    try {
        if (color !== undefined) {
            for (let i = 0; i < color.features.length; i++) {
                const hexColor = color.features[i].properties.cor;
                const removeSymbol = hexColor.split('#')
                hexToRgb(removeSymbol[1])
                color.features[i].properties.RGBconverted = RGBcolor
                color.features[i].properties.isClicked = false
            }
        }
    } catch (e) { console.log(e) }
    return color;
}
var RGBcolor;
export function hexToRgb(hex) {
    const arrBuff = new ArrayBuffer(4);
    const vw = new DataView(arrBuff);
    vw.setUint32(0, parseInt(hex, 16), false);
    const arrByte = new Uint8Array(arrBuff);

    RGBcolor = [arrByte[1], arrByte[2], arrByte[3]]
}

export function inputID(json) {
    let counterID = 1
    for (let i = 0; i < json.length; i++) {
        for (let j = 0; j < json[i].features.length; j++) {
            json[i].features[j].properties.id = counterID
            json[i].features[j].properties.layerActivated = true
            counterID++
        }
    }
    return json
}

export function inputIDClash(json) {
    let counterID = 1
    for (let i = 0; i < json.length; i++) {
        for (let j = 0; j < json[i].features.length; j++) {
            json[i].features[j].properties.idClash = counterID
            json[i].features[j].properties.layerActivated = true
            counterID++
        }
    }
    return json
}

export function inputLotesID(json) {
    let counterID = 1
    for (let i = 0; i < json.features.length; i++) {
        json.features[i].properties.id = counterID
        counterID++
    }
    counterID = 1
    return json
}