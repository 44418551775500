import React from "react";
import { openIframeBim } from "../Alertas e Iframe/AlertsIframe";
import { changeMeters } from "../Deck/Deck Components/Organizar Layers/DeckPrepare";
import "./RightButton.css"
import $ from "jquery"
import { getEnter, getMeters, setEnter, setMeters } from "../../DataHandler/DataHandler";

var limiter = 1

export function rightButton(x, y) {
    document.getElementById("fundoMouseRight").style.display = "flex";
    document.getElementById("bordaCirculo").style.left = x - "70" + "px";
    document.getElementById("bordaCirculo").style.top = y - "70" + "px";
    document.getElementById("setLoteHeight").style.left = x - "75" + "px";
    document.getElementById("setLoteHeight").style.top = y - "113" + "px";
    document.getElementById("setLoteHeight").style.opacity = 0;
    document.getElementById("setLoteHeight").style.pointerEvents = "none";
    document.getElementById("changeInfoMouseRight").innerHTML = "";
}

export function RightButton() {

    const funcao1 = () => {
        openIframeBim()
        document.getElementById("bordaCirculo").style.left = "-7000px";
    }
    const funcao2 = () => {
        document.getElementById("inputSetLote").select()
        setEnter(true)
        document.getElementById("bordaCirculo").style.left = "-7000px";
        document.getElementById("setLoteHeight").style.opacity = 1;
        document.getElementById("setLoteHeight").style.pointerEvents = "all";
    }

    function changeNameMouseRight(compar) {
        if (compar === 1) {
            document.getElementById("changeInfoMouseRight").innerHTML =
                "Abrir IFC";
        } else if (compar === 2) {
            document.getElementById("changeInfoMouseRight").innerHTML =
                "Ajustar altura do projeto";
        } else if (compar === 3) {
            document.getElementById("changeInfoMouseRight").innerHTML =
                "Função Nº 3";
        } else if (compar === 4) {
            document.getElementById("changeInfoMouseRight").innerHTML =
                "Função Nº 4";
        }
    }

    const mouseleave = () => {
        document.getElementById("bordaCirculo").style.left = "-7000px";
    }

    document.addEventListener("click", function (e) {
        const id = e.target.id
        if (limiter === 1) {
            if (id !== "setLoteHeight" && id !== "bordaCirculo" && id !== "clickMouseRight2" && id !== "" && id !== "inputSetLote" && id !== "root") {
                limiter++
                setEnter(false)
                document.getElementById("setLoteHeight").style.opacity = 0;
                document.getElementById("setLoteHeight").style.pointerEvents = "none";
            }
        }
        setTimeout(() => { limiter = 1 }, 200);
    })

    $(document).keydown(function (e) {
        if (e.key === "Enter" && limiter === 1) {
            if (getEnter() === true) {
                limiter++
                document.getElementById("setLoteHeight").style.opacity = 0;
                document.getElementById("setLoteHeight").style.pointerEvents = "none";
                changeMeters(getMeters())
                setEnter(false)
            }
        }
        setTimeout(() => { limiter = 1 }, 200);
    });

    return (
        <>
            <div id="fundoMouseRight">
                <div onMouseLeave={mouseleave} id="bordaCirculo">
                    <div id="clickMouseRight1" onMouseOver={() => changeNameMouseRight(1)} onClick={funcao1}>
                        <i className="far fa-layer-group iconeMouseRight"></i>
                    </div>
                    <div id="clickMouseRight2" onMouseOver={() => changeNameMouseRight(2)} onClick={funcao2}>
                        <i className="far fa-sliders-v iconeMouseRight" style={{ marginLeft: "23px" }}></i>
                    </div>
                    {/* <div id="clickMouseRight3" onMouseOver={() => changeNameMouseRight(3)} onClick={funcao3}>
                    <img src="https://www.iconpacks.net/icons/1/free-pin-icon-48-thumb.png" className="iconeMouseRight"
                        style={{ marginTop: "20px" }} />
                </div>
                <div id="clickMouseRight4" onMouseOver={() => changeNameMouseRight(4)} onClick={funcao4}>
                    <img src="https://www.iconpacks.net/icons/1/free-pin-icon-48-thumb.png" className="iconeMouseRight"
                        style={{ marginTop: "20px", marginLeft: "18px" }} />
                </div> */}
                    <div id="clickMouseRight5"></div>
                    <div className="infoMouseRight">
                        <p id="changeInfoMouseRight" style={{ fontSize: "17px", fontWeight: "bold" }}></p>
                    </div>
                </div>
                <div id="setLoteHeight">
                    <div className="infoSetLote">Insira a altura do lote em metros:</div>
                    <input className="inputSetLote" id="inputSetLote" onChange={(e) => { setMeters(e.target.value) }} type="number"></input>
                    <div id="setLoteButton" className="setLoteButton" onClick={() => { changeMeters(getMeters()); setEnter(false) }}>Ok</div>
                </div>
            </div>
        </>
    )
}