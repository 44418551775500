import React from "react";
import "./Legenda.css"
import { ativarLayerDeck, desativarLayer, desativarLayerDeck, mouseoverLayer } from "../Deck/Deck Components/Organizar Layers/DeckPrepare";
import { baseUrlApi, setScreenshot } from "../../DataHandler/DataHandler";

var vetor = []

export function iniciarLegenda(layers, firstValue, geoemp) {
    try {
        if (layers[0].length !== 0) {
            vetor = []
            let legenda = []
            for (let i = 0; i < layers.length; i++) {
                if (layers[0][i] !== undefined) { layers = layers[0] }
                for (let j = 0; j < layers[i].features.length; j++) {
                    const vetor = {
                        "algoritmo": layers[i].features[j].properties.algoritmo,
                        "titulo": layers[i].features[j].properties.titulo,
                        "cor": layers[i].features[j].properties.cor,
                        "icone": `${baseUrlApi()}` + layers[i].features[j].properties.icone,
                        "id": legenda.length
                    }

                    if (vetor.icone === `${baseUrlApi()}undefined`) { vetor.icone = "" }

                    let duplicatedValue = false
                    for (let i = 0; i < legenda.length; i++) {
                        if (legenda[i].titulo === vetor.titulo) {
                            duplicatedValue = true
                        }
                    }

                    if (!duplicatedValue) {
                        legenda.push(vetor)
                    }
                }
            }

            if (geoemp) {
                for (let i = 0; i < geoemp.length; i++) {
                    const feature = geoemp[i].features[0].properties
                    const vetor = {
                        "titulo": feature.geoModo,
                        "cor": `rgba(${feature.cor.join(', ')})`,
                        "id": legenda.length
                    }
                    legenda.push(vetor)
                }
            }

            vetor = legenda.sort()
            if (vetor.length === 0) {
                setScreenshot(false)
            } else {
                setScreenshot(true)
            }
            legenda = document.getElementById('leg');
            legenda.classList.add('active')

            const tampa = document.getElementsByClassName("tampa")
            if (firstValue === true) {
                for (var i = 0; i < tampa.length; i++) {
                    const tampa = document.getElementsByClassName("tampa")[i]
                    const trocarTexto = document.getElementsByClassName("changeTextColor")[i]
                    const iconeVue = document.getElementsByClassName("iconeVue")[i]
                    trocarTexto.style.color = "black"
                    tampa.style.display = "inline-block";
                    iconeVue.style.opacity = "1";
                }
            }
        } else {
            setScreenshot(false)
        }
        return false
    } catch (error) {
        console.log("iniciarlegenda", error)
    }
}

export function fecharLegenda() {
    const legenda = document.getElementById('leg');
    legenda.classList.remove('active')
    for (let i = 0; i < document.getElementsByClassName("changeTextColor").length; i++) {
        document.getElementsByClassName("changeTextColor")[i].style.color = "black"
        document.getElementsByClassName("trocarCor")[i].style.opacity = "1"
        document.getElementsByClassName("tampa")[i].style.opacity = "1";
        document.getElementsByClassName("iconeVue")[i].style.opacity = "1";
    }
    return true
}


export function Legend() {
    const textoLegenda = (idContador) => {
        const trocarTexto = document.getElementsByClassName("changeTextColor")[idContador]
        const trocarCor1 = document.getElementsByClassName("trocarCor")[idContador]
        const tampa = document.getElementsByClassName("tampa")[idContador]
        const iconeVue = document.getElementsByClassName("iconeVue")[idContador]

        if (trocarTexto.style.color === "black") {
            if (iconeVue.src.includes("${baseUrlApi()}")) {
                trocarCor1.style.opacity = "0"
                trocarTexto.style.color = "#3a3a3adc";
                tampa.style.opacity = "0";
                iconeVue.style.opacity = "0.5";
            } else {
                trocarTexto.style.color = "#3a3a3adc";
                tampa.style.opacity = "0";
            }
        } else {
            trocarTexto.style.color = "black"
            tampa.style.opacity = "1";
            iconeVue.style.opacity = "1";
        }
    }

    const desativarLayers = () => {
        desativarLayerDeck()
        const tampa = document.getElementsByClassName("tampa")
        setTimeout(() => {
            for (let i = 0; i < tampa.length; i++) {
                const tampa = document.getElementsByClassName("tampa")[i]
                const trocarTexto = document.getElementsByClassName("changeTextColor")[i]
                const iconeVue = document.getElementsByClassName("iconeVue")[i]
                const trocarCor1 = document.getElementsByClassName("trocarCor")[i]
                if (iconeVue.src.includes("${baseUrlApi()}")) {
                    trocarCor1.style.opacity = "0"
                    trocarTexto.style.color = "#3a3a3adc";
                    tampa.style.opacity = "0";
                    iconeVue.style.opacity = "0.5";
                } else {
                    trocarTexto.style.color = "#3a3a3adc"
                    tampa.style.opacity = "0";
                    iconeVue.style.opacity = "0";
                }
            }
        }, 5);
    }

    const ativarLayers = () => {
        ativarLayerDeck()
        const tampa = document.getElementsByClassName("tampa")
        for (let i = 0; i < tampa.length; i++) {
            const tampa = document.getElementsByClassName("tampa")[i]
            const trocarTexto = document.getElementsByClassName("changeTextColor")[i]
            const iconeVue = document.getElementsByClassName("iconeVue")[i]
            trocarTexto.style.color = "black"
            tampa.style.opacity = "1";
            iconeVue.style.opacity = "1";
        }
    }

    return (
        <>
            <div className="fundoLegenda">
                <div id="leg" className="legenda">
                    <div className="container">
                        <a href="#" className="legendaSelect">
                            <label className="labelLegend">
                                <div className="toggleLegend">
                                    <div
                                        onClick={ativarLayers}
                                        // onMouseMove={(e) => OpenTitle(e, "Ativar Layers")}
                                        // onMouseLeave={closeTitle}
                                        className="toggleAtiva">
                                        Ativar
                                    </div>
                                    <div
                                        onClick={desativarLayers}
                                        // onMouseMove={(e) => OpenTitle(e, "Desativar Layers")}
                                        // onMouseLeave={closeTitle}
                                        className="toggleDesativa">
                                        Desativar
                                    </div>
                                </div>
                                <span>
                                    {vetor.map((props) => (
                                        <div onClick={() => textoLegenda(props.id)}
                                            onMouseUp={() => desativarLayer(props.titulo)}
                                            onMouseEnter={() => mouseoverLayer(props.titulo)}
                                            className="changeTextColor" key={props.id}
                                            style={{ cursor: "pointer", color: "black" }}>
                                            <img className="iconeVue" src={props.icone} />
                                            <div className="tampa" style={{ background: props.cor }}></div>
                                            <input disabled className="trocarCor" style={{ border: 'solid 3px ' + props.cor }} ></input>
                                            {props.titulo}
                                        </div>
                                    ))}
                                </span>
                            </label>
                        </a>
                    </div>
                </div>
            </div>
            <div id="legScreenshot"></div>
        </>
    )
}