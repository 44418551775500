import * as bootstrap from 'bootstrap'
import { cleanAllLayers } from "../Components/Deck/Deck Components/Organizar Layers/DeckPrepare";
import { closeClashFunctions } from '../Components/Deck/Deck Functions/LayersControl';
import { fecharSidebar } from "../Components/Sidebar/Sidebar";

export function closeClash() {
    const collapseElementList = [].slice.call(document.querySelectorAll('.collapse.show'))
    collapseElementList.map(function (collapseEl) {
        return new bootstrap.Collapse(collapseEl)
    })
    document.getElementById("showSide").style.display = "none";
    document.getElementById("buttonDeckid").style.display = "none"
    document.getElementById("positionDownload").classList.remove("active")
    cleanAllLayers()
    fecharSidebar()
    closeClashFunctions()
}