import React from 'react';
import ReactDOM from 'react-dom';
import DeckHUB from './Components/Deck/Deck Components/Organizar Layers/DeckPrepare';

import './index.css';

ReactDOM.render(
  <>
    <DeckHUB />
  </>,
  document.getElementById('root')
);

