import { getColor, getIndex, inputID, redistribuirIcones } from "../../../../Format JSON/formatJson"
import { changeLayers, executaAlgoritmo } from "./DeckPrepare"
import { baseUrlApi, getClashFilter, getGlobalRisk, getLotes, getMultiClash, getSelectedID, getTipologia, setAllClash, setCamadasDeRisco, setClash, setClashBruto, setFinalClash, setIds, setListaLotes } from "../../../../DataHandler/DataHandler"
import { distributeAlgorithms, distributeAlgorithmsFeatures } from "../../../../Format JSON/DistributeAlgorithms"
import { Loading } from "../../../Loading/LoadingScreen"
import { getTypology, iniciarModal } from "../../../Tipologia/Tipologia"
import Worker from "web-worker"
import $ from "jquery";
import { executa_algoritmos } from "../../../../Clash/algorithmlibrary"
import { LegendScreenshot } from "../../../Sidebar/DownloadClash/DownloadClash"

const worker = new Worker(
    // new URL('${baseUrlApi()}worker', import.meta.url),
    new URL('../../../../Worker/WorkerLogic.js', import.meta.url),
    { type: 'module' }
);

var POLYLINE, MARKER, POLYGON, POLYGONOFFSET, INTERSECCAOATRIBUTO
const INTERSECCAO_ATRIBUTO = "interseccao_atributo"

export default async (layers, loteUnico, limiter, id) => {
    // const indexInterseccaoAtributo = getIndex(layers, INTERSECCAO_ATRIBUTO)
    inputID(layers)
    setAllClash(layers)
    setCamadasDeRisco(layers)
    if (id !== undefined) {
        const cloned = layers.slice(0);
        setClashBruto(id, cloned)
    }
    try {
        // criando propriedade layerActivated para permitir que camadas sejam desativadas no futuro
        for (let i = 0; i < layers.length; i++) {
            for (let j = 0; j < layers[i].features.length; j++) {
                layers[i].features[j].properties.layerActivated = true
            }
        }
        // const globalRisk = layers.slice()
        // setGlobalRisk(globalRisk)
        // remove interseccao_atributo do codigo
        // INTERSECCAOATRIBUTO = layers[indexInterseccaoAtributo]
        // if (indexInterseccaoAtributo > -1) {
        //     layers.splice(indexInterseccaoAtributo, 1);
        // }

        // desenhando poligono que mostra area analisada
        // const polygon = turf.polygon([lote], { name: 'poly1' })
        // const bufferedArea = turf.buffer(polygon, 502, { units: 'meters' })
    } catch (error) {
        console.error(error)
    }
    for (let i = 0; i < layers.length; i++) {
        for (let j = 0; j < layers[i].features.length; j++) {
            if (layers[i].features[j].properties.tipo.includes('invisivel')) {
                delete layers[i].features[j]
            }
        }
    }
    const indexMARKER = getIndex(layers, "interseccao_circulo")
    const indexPOLYGON = getIndex(layers, "interseccao_poligono")
    const indexPOLYLINE = getIndex(layers, "interseccao_polilinha")
    const indexPOLYGONOFFSET = getIndex(layers, "interseccao_poligono_offset")

    POLYLINE = layers[indexPOLYLINE]
    MARKER = layers[indexMARKER]
    POLYGON = layers[indexPOLYGON]
    POLYGONOFFSET = layers[indexPOLYGONOFFSET]

    POLYGON = getColor(POLYGON)
    MARKER = getColor(MARKER)
    POLYGONOFFSET = getColor(POLYGONOFFSET)
    POLYLINE = getColor(POLYLINE)
    MARKER = redistribuirIcones(MARKER)

    if (POLYGON != undefined) {
        POLYGON = distributeAlgorithms(POLYGON, "titulo")
    }
    if (POLYGONOFFSET != undefined) {
        POLYGONOFFSET = distributeAlgorithms(POLYGONOFFSET, "titulo")
    }
    if (POLYLINE != undefined) {
        POLYLINE = distributeAlgorithms(POLYLINE, "titulo")
    }
    if (MARKER != undefined) {
        MARKER = distributeAlgorithmsFeatures(MARKER, "titulo")
    }

    if (limiter && limiter !== undefined) {
        // const clash = executa_algoritmos(getTipologia(), getLotes(), getGlobalRisk())
        // setClash(clash)

        let workerOrganize = []
        workerOrganize.push(getTipologia());
        workerOrganize.push(getLotes());
        workerOrganize.push(getGlobalRisk());
        worker.postMessage(workerOrganize);
        worker.addEventListener('message', message => {
            setClash(message.data[0])
            setIds(message.data[1])
            setListaLotes(message.data[2])
            // setisNebula(message.data[3])
            $.ajax({
                type: "POST",
                url: `${baseUrlApi()}getclash`,
                data: { "clash": message.data[0] },
                dataType: 'JSON',
                success: function (finalClash) {
                    executaAlgoritmo(finalClash)
                    if (getMultiClash() === true) {
                        setFinalClash(getSelectedID(), finalClash)
                    }
                }
            })
            // const clash = getClash()
            // const requestFinalClash = await postRequest("${baseUrlApi()}api/getclash", { "clash": clash })
            // executaAlgoritmo(requestFinalClash)
        });
    }

    if (getMultiClash() === true) {
        if (getClashFilter().length > 0) {
            iniciarModal('tipologiaCheckbox')
        }
    }
    changeLayers(layers, loteUnico, POLYGON, POLYGONOFFSET, MARKER, POLYLINE, INTERSECCAOATRIBUTO)
    setTimeout(() => {
        Loading("fechar")
        LegendScreenshot()
        document.getElementById('zoomPanel').style.opacity = 1
        document.getElementById('setTilesBody').style.opacity = 1
        document.getElementById("MouseBlock").style.display = "none"
    }, 1500);
}


export function OrganizaLayerClashMode(layers, loteUnico, limiter, id) {
    const indexInterseccaoAtributo = getIndex(layers, INTERSECCAO_ATRIBUTO)
    inputID(layers)
    setAllClash(layers)
    setCamadasDeRisco(layers)
    if (id !== undefined) {
        const cloned = layers.slice(0);
        setClashBruto(id, cloned)
    }
    try {
        // criando propriedade layerActivated para permitir que camadas sejam desativadas no futuro
        for (let i = 0; i < layers.length; i++) {
            for (let j = 0; j < layers[i].features.length; j++) {
                layers[i].features[j].properties.layerActivated = true
            }
        }
        // const globalRisk = layers.slice()
        // setGlobalRisk(globalRisk)

        // remove interseccao_atributo do codigo
        INTERSECCAOATRIBUTO = layers[indexInterseccaoAtributo]
        if (indexInterseccaoAtributo > -1) {
            layers.splice(indexInterseccaoAtributo, 1);
        }

        // desenhando poligono que mostra area analisada
        // const polygon = turf.polygon([lote], { name: 'poly1' })
        // const bufferedArea = turf.buffer(polygon, 502, { units: 'meters' })
    } catch (error) {
        console.error(error)
    }
    for (let i = 0; i < layers.length; i++) {
        for (let j = 0; j < layers[i].features.length; j++) {
            if (layers[i].features[j].properties.tipo.includes('invisivel')) {
                delete layers[i].features[j]
            }
        }
    }

    const indexMARKER = getIndex(layers, "interseccao_circulo")
    const indexPOLYGON = getIndex(layers, "interseccao_poligono")
    const indexPOLYLINE = getIndex(layers, "interseccao_polilinha")
    const indexPOLYGONOFFSET = getIndex(layers, "interseccao_poligono_offset")

    POLYLINE = layers[indexPOLYLINE]
    MARKER = layers[indexMARKER]
    POLYGON = layers[indexPOLYGON]
    POLYGONOFFSET = layers[indexPOLYGONOFFSET]

    POLYGON = getColor(POLYGON)
    MARKER = getColor(MARKER)
    POLYGONOFFSET = getColor(POLYGONOFFSET)
    POLYLINE = getColor(POLYLINE)
    MARKER = redistribuirIcones(MARKER)

    if (POLYGON != undefined) {
        POLYGON = distributeAlgorithms(POLYGON, "titulo")
    }
    if (POLYGONOFFSET != undefined) {
        POLYGONOFFSET = distributeAlgorithms(POLYGONOFFSET, "titulo")
    }
    if (POLYLINE != undefined) {
        POLYLINE = distributeAlgorithms(POLYLINE, "titulo")
    }
    if (MARKER != undefined) {
        MARKER = distributeAlgorithmsFeatures(MARKER, "titulo")
    }

    if (limiter && limiter !== undefined) {
        const clash = executa_algoritmos(getTipologia(), getLotes(), getGlobalRisk())
        setClash(clash)

        // let workerOrganize = []
        // workerOrganize.push(getTipologia());
        // workerOrganize.push(getLotes());
        // workerOrganize.push(getGlobalRisk());
        // worker.postMessage(workerOrganize);
        // worker.addEventListener('message', message => {
        //     setClash(message.data[0])
        //     setIds(message.data[1])
        // });
    }

    Loading("fechar")

    changeLayers(layers, loteUnico, POLYGON, POLYGONOFFSET, MARKER, POLYLINE, INTERSECCAOATRIBUTO)
    getTypology()
}

