export function distributeAlgorithms(json, property) {
    let vetorAlgoritmo = []
    let vetorGEOJSON = []
    for (let i = 0, len = json.features.length; i < len; i++) {
        const searchedProperties = json.features[i].properties[property]
        if (!vetorAlgoritmo.includes(searchedProperties)) {
            const geojson = {
                "name": searchedProperties,
                "type": "FeatureCollection",
                "features": []
            }
            vetorAlgoritmo.push(searchedProperties)
            vetorGEOJSON.push(geojson)
        }
        const indiceAlgoritmo = vetorAlgoritmo.indexOf(searchedProperties)
        vetorGEOJSON[indiceAlgoritmo].features.push(json.features[i])
    }

    return vetorGEOJSON
}

export function distributeAlgorithmsFeatures(json, property) {
    let vetorAlgoritmo = []
    let vetorGEOJSON = []
    for (let i = 0, len = json.length; i < len; i++) {
        const searchedProperties = json[i].properties[property]
        if (!vetorAlgoritmo.includes(searchedProperties)) {
            const geojson = {
                "name": searchedProperties,
                "type": "FeatureCollection",
                "features": []
            }
            vetorAlgoritmo.push(searchedProperties)
            vetorGEOJSON.push(geojson)
        }
        const indiceAlgoritmo = vetorAlgoritmo.indexOf(searchedProperties)
        vetorGEOJSON[indiceAlgoritmo].features.push(json[i])
    }
    return vetorGEOJSON
}

export function distributeAlgorithmsLegenda(json, property) {
    let vetorAlgoritmo = []
    let vetorGEOJSON = []
    for (let i = 0, len = json.length; i < len; i++) {
        for (let j = 0, len = json[i].features.length; j < len; j++) {
            const searchedProperties = json[i].features[j].properties[property]
            if (!vetorAlgoritmo.includes(searchedProperties)) {
                const geojson = {
                    "name": searchedProperties,
                    "type": "FeatureCollection",
                    "features": []
                }
                vetorAlgoritmo.push(searchedProperties)
                vetorGEOJSON.push(geojson)
            }
            const indiceAlgoritmo = vetorAlgoritmo.indexOf(searchedProperties)
            vetorGEOJSON[indiceAlgoritmo].features.push(json[i].features[j])
        }
    }
    return vetorGEOJSON
}